import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { empty, forkJoin } from "rxjs";
import { catchError, expand, finalize, map, startWith, takeUntil } from "rxjs/operators";
import { CsvService } from "src/app/services/csv.service";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { GraphService } from "src/app/services/graph.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LoggingService } from "src/app/services/logging.service";
import { MessageOfTheDayService } from "src/app/services/message-of-the-day.service";
import { ClientRepository } from "src/app/services/repositories/client.repository";
import { ScopeListingService } from "src/app/services/scope-listing.service";
import { CommonFeatureFlags } from "../../../../../../common/constants/CommonFeatureFlags";
import { IClientRow } from "../../../../../../common/models/IClientRow";
import { IRelationship } from "../../../../../../common/models/IRelationship";
import { environment } from "../../../../environments/environment";
import { AnalyticsService } from "../../../services/analytics.service";
import { TourComponent } from "../../common/tour/tour.component";
import { ListingBaseComponent } from "../listing-base/listing-base.component";
import { AnalyticsCategory } from "./../../../../../../common/constants/AnalyticsCategory";
import { apsCountry } from "./../../../../../../common/constants/Aps";
import { WindowService } from "./../../../services/window.service";

@Component({
    selector: "app-legacy-clients-listing",
    templateUrl: "./legacy-clients-listing.component.html",
    styleUrls: ["./legacy-clients-listing.component.scss"],
})
export class LegacyClientsListingComponent extends ListingBaseComponent implements OnInit {
    componentName = "ClientsListingComponent";
    firstLoad = true;

    totalScoped = 0;
    totalRevenueLY = 0;
    totalInvoicedLY = 0;
    totalRevenueYTD = 0;
    totalInvoicedYTD = 0;
    totalResults = 0;
    columns: any[] = [
        { name: "Sub Region", value: "Sub Region", visible: true },
        { name: "Matter partner", value: "Matter partner", visible: true },
        { name: "Matter manager", value: "Matter manager", visible: true },
        { name: "Prior year", value: "Prior year", visible: true },
        { name: "Selected year", value: "Selected year", visible: true },
        { name: "Scoped $", value: "Scoped $", visible: false },
    ];
    @ViewChild(TourComponent, { static: true })
    tourComponent: TourComponent;

    readonly yearlessScopesFeature: boolean = environment.featureFlags[CommonFeatureFlags.YEARLESS_SCOPES];

    constructor(
        protected clientRepository: ClientRepository,
        protected loggingService: LoggingService,
        protected motdService: MessageOfTheDayService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected graphService: GraphService,
        private scopeListingService: ScopeListingService,
        protected windowService: WindowService,
        protected csvService: CsvService,
        public dialog: MatDialog,
        protected errorHandlerService: ErrorHandlerService,
        protected analyticsService: AnalyticsService,
        protected localStorageService: LocalStorageService
    ) {
        super(
            clientRepository,
            loggingService,
            motdService,
            router,
            activatedRoute,
            graphService,
            windowService,
            csvService,
            errorHandlerService,
            analyticsService,
            localStorageService
        );
    }

    ngOnInit(): void {
        this.logAnalyticsEvent(AnalyticsCategory.pageLoad, "client-listing");

        this.makingRequests = true;
        const currentDate = moment();
        const currentYear = Number(currentDate.add(6, "months").format("YYYY")); // TODO sorry NZ
        this.getYearOptions(currentYear);

        const meVal$ = this.graphService.getMe().pipe(
            catchError((err) => {
                // failed to get ad
                this.loggingService.warn(err);
                throw err;
            })
        );

        // get from local storage
        const savedSearchTextControl = this.getStoredCommonValue("searchTextControl");
        const savedSearchTypeControl = this.getStoredCommonValue("searchTypeControl");
        const savedSubRegion = this.getStoredCommonValue("subRegion");
        this.subRegion = savedSubRegion;

        forkJoin(this.clientRepository.getAllMatterManagers(), this.clientRepository.getAllMatterPartners(), meVal$)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([matterManagers, matterPartners, meVal]: [IRelationship[], IRelationship[], any]) => {
                    this.matterManagers = matterManagers.filter(
                        (matterManager) => typeof matterManager.email === "string"
                    );
                    this.matterPartners = matterPartners.filter(
                        (matterPartner) => typeof matterPartner.email === "string"
                    );
                    this.options = this.matterPartners;
                    this.currentUser = {
                        name: meVal["displayName"],
                        email: meVal["mail"],
                    };
                    this.currentUserEmail = meVal["mail"];
                    const searchTextControlOnLoad = savedSearchTextControl ? savedSearchTextControl : this.currentUser;
                    this.searchTextControl.setValue(searchTextControlOnLoad);
                    this.filteredOptions = this.searchTextControl.valueChanges.pipe(
                        startWith(<string | { name: string; email: string }>searchTextControlOnLoad),
                        map((value) => (typeof value === "string" ? value : value.name)),
                        map((value) => {
                            return value ? this._filter(value) : this.options.slice();
                        })
                    );

                    const searchTypeControlOnLoad = savedSearchTypeControl
                        ? savedSearchTypeControl
                        : this.matterPartner;
                    this.searchTypeControl.setValue(searchTypeControlOnLoad);
                    this.subscribeToFormControl();
                    this.currentSearch = this.searchTypeControl.value;
                    this.onSearch();
                    if (this.firstLoad) {
                        this.firstLoad = false;
                    }
                    this.tourComponent.initializeTour("client-tour");
                },
                (err) => this.handleError(err, "getAllMatterPartners", err.message)
            );
    }

    // hook up analytics to all methods in this region
    //#region handlers
    /**
     * Set up a row click event to go to the scope
     */
    async onRowClicked(event: any): Promise<void> {
        this.logAnalyticsEvent(AnalyticsCategory.buttonClick, "onRowClicked");
        const country = apsCountry(event.sourceSystem);
        // const createScopeValid = this.scopeValidator.scopeCanBeCreated(
        //     { country, year: this.selectedYear },
        //     this.currentUserEmail
        // );

        const id = event.scopeId;
        const familyGroupId: string = event.familyGroupId;
        const sourceSystem: string = event.sourceSystem;

        if (id != null || (sourceSystem && familyGroupId)) {
            this.navigateToClientDetails(id, sourceSystem, familyGroupId);
        } else {
            // if (!createScopeValid.valid) {
            //     const message = ['Could not create scope:', ...createScopeValid.messages].join(' ');
            //     this.motdService.createMessage(message, 'OK');
            //     return;
            // }
            // CM - this code should no longer be used and will be deleted with this component in TASKX-3106

            const year: string = this.selectedYear.toString();
            const eventFamilyGroupId: string = event.familyGroupId;
            const eventSourceSystem: string = event.sourceSystem;
            this.navigateToPrefillUrl(eventSourceSystem, eventFamilyGroupId, year);
        }
    }

    onSearch(input?: any): void {
        this.logAnalyticsEvent(AnalyticsCategory.search, "onSearch");
        const relationship = this.searchTypeControl.value;
        const value = input ? input : this.getSearchValue(this.searchTypeControl.value);
        const year = this.selectedYear;

        this.updatePriorYear();

        const queryObservable = this.clientRepository.getClients(relationship, value, year);

        this.makingRequests = true;
        let clientRows: IClientRow[] = [];

        queryObservable
            .pipe(
                expand((response) => {
                    const { offset, hasMore, count } = response.page;

                    if (hasMore) {
                        return this.clientRepository.getClients(relationship, value, year, offset + count, count);
                    } else {
                        return empty();
                    }
                }),
                map((response) => (clientRows = [...clientRows, ...response.result])),
                finalize(() => (this.makingRequests = false))
            )
            .subscribe(
                (clientList) => {
                    this.scopeSummaries = this.scopeListingService.formatLists(clientList);

                    this.resetTotals();
                    this.sortRows("revenueYTD");

                    this.setTotals();
                },
                (error) => this.handleError(error, "onSearch", error.message)
            );
    }
    //#endregion handlers

    private navigateToPrefillUrl(sourceSystem: string, familyGroupId: string, year: string): void {
        this.windowService
            .window()
            .open(
                `/client/sourceSystemName/${sourceSystem}/referenceId/${familyGroupId}/year/${year}`,
                `${familyGroupId}-${sourceSystem}`
            );
    }

    private navigateToClientDetails(id: any, sourceSystemName: string, familyGroupId: string): void {
        if (this.yearlessScopesFeature) {
            window.open(`/yearless/sourceSystemName/${sourceSystemName}/referenceId/${familyGroupId}/client`, "_blank");
        } else {
            window.open(`/scope/${id}/client`, "_blank");
        }
    }

    protected resetTotals(): void {
        this.totalScoped = 0;
        this.totalRevenueLY = 0;
        this.totalInvoicedLY = 0;
        this.totalRevenueYTD = 0;
        this.totalInvoicedYTD = 0;
        this.totalResults = 0;
    }

    protected setTotals() {
        this.filteredScopeSummaries.forEach((summary: any): void => {
            this.totalScoped += summary.scoped;
            this.totalRevenueLY += summary.revenueLY;
            this.totalInvoicedLY += summary.invoicedLY;
            this.totalRevenueYTD += summary.revenueYTD;
            this.totalInvoicedYTD += summary.invoicedYTD;
            this.totalResults++;
        });
    }
}
