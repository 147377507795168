import { IWorkItem } from "../../models/IWorkItem";
import { IEngagement } from "../../models/IEngagement";

export class WorkItemFilterFactory {
    filterAssignedWorkItems(engagements: IEngagement[]): (workItem: IWorkItem) => boolean {
        return (workItem) => {
            const assignedWorkItemIds = engagements.reduce(
                (acc, engagement) => [...acc, ...engagement.workItems.map((workItem) => workItem.workItemId)],
                [] as string[]
            );
            return assignedWorkItemIds.includes(workItem.workItemId);
        };
    }
}
