import { MsalModule } from "@azure/msal-angular";
import { InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "./../../environments/environment";

const redirectUri = environment.redirectUrl;
function loggerCallback(logLevel: LogLevel, message: string) {
    // this is to see logs from the MSAL library
    console.log(message); // tslint:disable-line:no-console
}
const BACKEND_SCOPE_NAME = `api://${environment.azureAD.clientID}/JST.Access`;
const protectedResourceMap = new Map([
    [environment.backendUrl, [BACKEND_SCOPE_NAME]],
    ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
    ["https://graph.microsoft.com/v1.0/users/", ["user.read"]],
]);
export const AuthenticationModule = MsalModule.forRoot(
    new PublicClientApplication({
        auth: {
            clientId: environment.azureAD.clientID,
            authority: environment.azureAD.authority,
            redirectUri,
        },
        cache: {
            cacheLocation: "localStorage",
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    }),
    {
        interactionType: InteractionType.Redirect,
    },
    {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    }
);
