/**
 * HACK: We shouldn't need to know this list.
 * This only exists because we are receiving clients for which we are unaware of the office.
 * DO NOT USE THIS IN NEW CODE UNLESS YOU HAVE A GOOD REASON AND A PLAN TO REMOVE IT AGAIN
 * https://{{env}}.location.findex.{{dev/solutions}}/Locations
 * @deprecated This is a hack, don't use it please.
 */

export const Offices: { [key: string]: string[] } = {
    AU: [
        "Albury",
        "Apollo Bay",
        "Ararat",
        "Armidale",
        "Ayr",
        "Bairnsdale",
        "Ballarat",
        "Ballina",
        "Bathurst",
        "Baulkham Hills",
        "Berri",
        "Brisbane",
        "Broken Hill",
        "Bundall",
        "Burdekin",
        "Burnie",
        "Cairns",
        "Camperdown",
        "Canberra",
        "Casino",
        "Charters Towers",
        "Chinchilla",
        "Coffs Harbour",
        "Colac",
        "Dalby",
        "Daylesford",
        "Devonport",
        "Dubbo",
        "Forbes",
        "Geelong",
        "George Town",
        "Glen Innes",
        "Gold Coast",
        "Goondiwindi",
        "Grafton",
        "Hamilton",
        "Hobart",
        "Hopetoun",
        "Hughenden",
        "Innisfail",
        "Inverell",
        "Kingston",
        "Kirwan",
        "Kyogle",
        "Launceston",
        "Lismore",
        "Lithgow",
        "Lorne",
        "Loxton",
        "Melbourne",
        "Mildura",
        "Murwillumbah",
        "Ocean Grove",
        "Orange",
        "Ouyen",
        "Pakenham",
        "Perth",
        "Robinvale",
        "Sale",
        "Smithton",
        "Southport",
        "St Helens",
        "Stawell",
        "Sydney",
        "Terang",
        "Test Office",
        "Toowoomba",
        "Torquay",
        "Townsville",
        "Traralgon",
        "Tweed Heads",
        "Walcha",
        "Warialda",
        "Warragul",
        "Warren",
        "Warrnambool",
        "Wellington",
        "Werribee",
        "West Wyalong",
        "Yamba",
        "Yarra Junction",
    ],
    NZ: [
        "Alexandra",
        "Auckland",
        "Blenheim",
        "Christchurch",
        "Cromwell",
        "Dunedin",
        "Gore",
        "Hamilton",
        "Hastings",
        "Invercargill",
        "Lower Hutt",
        "Milton",
        "Napier",
        "Nelson",
        "Oamaru",
        "Queenstown",
        "Richmond",
        "Tauranga",
        "Te Anau",
        "Te Awamutu",
        "Waipukurau",
        "Wanaka",
        "Wellington",
        "Winton",
        "Wyndham",
    ],
};
