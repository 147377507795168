import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { JstError } from "../../../../common/models/JstError";

export interface IGraphUserDetails {
    jobTitle: string;
    displayName: string;
    officeLocation: string;
    mail: string;
}

@Injectable({
    providedIn: "root",
})
export class GraphService {
    constructor(private httpClient: HttpClient) {}

    getMe(): Observable<IGraphUserDetails> {
        return this.httpClient.get<IGraphUserDetails>("https://graph.microsoft.com/v1.0/me");
    }

    getJobTitleAndName(userEmail: string): Observable<IGraphUserDetails> {
        if (!this.isEmail(userEmail)) {
            throw new JstError("InvalidEmailAddress", {
                functionName: "getJobTitle",
                className: "GraphService",
                displayMessage: `${userEmail} is not a valid email address`,
            });
        }
        return this.httpClient.get<IGraphUserDetails>(`https://graph.microsoft.com/v1.0/users/${userEmail}`).pipe(
            catchError((err) => {
                throw new JstError("InvalidEmailAddress", {
                    functionName: "getJobTitle",
                    className: "GraphService",
                    displayMessage: `Error: ${userEmail} is not found`,
                });
            })
        );
    }

    // TODO email validator service
    isEmail(emailAddress: string): boolean {
        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(emailAddress).toLowerCase());
    }
}
