import isEqual from "lodash/isEqual";

export const arrayIsNullOrEmpty = (theArray: any) => {
    return !Array.isArray(theArray) || theArray.length === 0;
};

export const arrayContains = (
    list: Array<any>,
    comparable: any,
    matcher?: (element1: any, element2: any) => boolean
): boolean => {
    return list.some((elem) => (matcher ? matcher(comparable, elem) : isEqual(comparable, elem)));
};
