import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, delay, retry } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { JstError, JstHttpError } from "../../../../common/models/JstError";
import { formatError } from "../../../../common/src/helpers/JstErrorHelper";

// FIXME: Can't find a place where lib/msal-core/src/error/ClientAuthError.ts userLoginRequiredError.code is exported.
const MSAL_USER_LOGIN_ERROR = "user_login_error";
const MSAL_ERROR_IGNORE = [MSAL_USER_LOGIN_ERROR];
const MSAL_LOGIN_REQUIRED = "login_required";
const MSAL_INTERACTION_REQUIRED = "interaction_required";
const MSAL_TOKEN_RENEWAL_FAILED = "Token Renewal Failed";
const MSAL_ERROR_DESC_IGNORE = [MSAL_LOGIN_REQUIRED, MSAL_INTERACTION_REQUIRED, MSAL_TOKEN_RENEWAL_FAILED];

export interface IRetry {
    times: number;
    delay: number;
}

@Injectable({
    providedIn: "root",
})
export class BackendService {
    //#region dev
    readonly config = {
        url: environment.backendUrl,
    };
    //#endregion dev

    //#region ctor
    constructor(private httpClient: HttpClient) {}
    //#endregion ctor

    get<T>(key: string, queryStrings?: Array<{ key: string; value: string }>, retryConfig?: IRetry) {
        const url = this.buildURL(key);
        const retryTimes = retryConfig ? retryConfig.times - 1 : 0;
        const retryDelay = retryConfig ? retryConfig.delay : 5000;
        return this.httpClient
            .get<T>(url, {
                observe: "body",
                ...this.buildOptions(queryStrings),
            })
            .pipe(
                catchError((error) => {
                    return this.handleErrors(error, "get", url);
                })
            );
    }

    getString(
        key: string,
        queryStrings?: Array<{ key: string; value: string }>,
        retryConfig?: IRetry
    ): Observable<string> {
        const url = this.buildURL(key);
        const retryTimes = retryConfig ? retryConfig.times - 1 : 0;
        const retryDelay = retryConfig ? retryConfig.delay : 5000;
        return this.httpClient
            .get<string>(url, {
                observe: "body",
                ...this.buildOptions(queryStrings, "string"),
            })
            .pipe(
                catchError((error) => {
                    return this.handleErrors(error, "getString", url);
                })
            );
    }

    getBlob(key: string, queryStrings?: Array<{ key: string; value: string }>, retryConfig?: IRetry): Observable<Blob> {
        const url = this.buildURL(key);
        const retryTimes = retryConfig ? retryConfig.times - 1 : 0;
        const retryDelay = retryConfig ? retryConfig.delay : 5000;
        return this.httpClient
            .get(url, {
                observe: "body",
                ...this.buildOptions(queryStrings, "string"),
                responseType: "blob",
            })
            .pipe(
                delay(retryDelay),
                retry(retryTimes),
                catchError((error) => {
                    return this.handleErrors(error, "getblob", url);
                })
            );
    }

    getFromSignedUrl(key: string, retryConfig?: IRetry): Observable<Blob> {
        const retryTimes = retryConfig ? retryConfig.times - 1 : 0;
        const retryDelay = retryConfig ? retryConfig.delay : 5000;
        return this.httpClient.get(key).pipe(
            delay(retryDelay),
            retry(retryTimes),
            catchError((error) => {
                return this.handleErrors(error, "getFromSignedUrl", key);
            })
        );
    }

    create<T>(key: string, data: T, queryStrings?: Array<{ key: string; value: string }>): Observable<T> {
        const url = this.buildURL(key);
        return this.httpClient
            .post<T>(url, data, {
                observe: "body",
                ...this.buildOptions(queryStrings),
            })
            .pipe(
                catchError((error) => {
                    return this.handleErrors(error, "create", url);
                })
            );
    }

    // for cases where we are not getting back the same object type
    post<T>(
        key: string,
        data: any,
        queryStrings?: Array<{ key: string; value: string }>,
        type = "json"
    ): Observable<T> {
        const url = this.buildURL(key);
        return this.httpClient
            .post<T>(url, data, {
                observe: "body",
                ...this.buildOptions(queryStrings, type),
            })
            .pipe(
                catchError((error) => {
                    return this.handleErrors(error, "post", url);
                })
            );
    }

    update<TData, TResponse>(
        key: string,
        data: TData,
        queryStrings?: Array<{ key: string; value: string }>
    ): Observable<TResponse> {
        const url = this.buildURL(key);
        return this.httpClient
            .put<TResponse>(url, data, {
                observe: "body",
                ...this.buildOptions(queryStrings),
            })
            .pipe(
                catchError((error) => {
                    return this.handleErrors(error, "update", url);
                })
            );
    }

    delete(key: string): Observable<void> {
        const url = this.buildURL(key);
        return this.httpClient.delete<void>(url, this.buildOptions()).pipe(
            catchError((error) => {
                return this.handleErrors(error, "delete", key);
            })
        );
    }

    uploadFile(file: File, uploadUrl: string): Observable<any> {
        const headers = new HttpHeaders({ "Content-Type": file.type });
        const req = new HttpRequest("PUT", uploadUrl, file, {
            headers: headers,
            reportProgress: true,
        });
        return this.httpClient.request(req).pipe(
            catchError((error) => {
                return this.handleErrors(error, "uploadFile", uploadUrl);
            })
        );
    }

    private buildURL(key: string): string {
        return `${this.config.url}/${key}`;
    }

    private buildOptions(queryStrings?: Array<{ key: string; value: string }>, type?: string) {
        let httpOptions = {};
        if (type === "string") {
            httpOptions = {
                ...httpOptions,
                responseType: "text",
            };
        }
        if (queryStrings) {
            let params = new HttpParams();
            queryStrings.forEach(({ key, value }) => {
                params = params.append(key, value);
            });
            httpOptions = {
                ...httpOptions,
                params: params,
            };
        }
        return httpOptions;
    }

    private isErrorToBeIgnored(error: any) {
        if (
            (typeof error === "string" && error.startsWith(MSAL_LOGIN_REQUIRED)) ||
            (typeof error === "string" && error.startsWith(MSAL_INTERACTION_REQUIRED)) ||
            (typeof error === "string" && error.startsWith(MSAL_TOKEN_RENEWAL_FAILED))
        ) {
            return true;
        }
        return false;
    }

    private handleErrors(error: any, functionName: string, url: string): Observable<any> {
        let message;
        let errorCode = undefined;

        if (error instanceof HttpErrorResponse) {
            const internalError = error["error"];
            message =
                typeof internalError.message === "string"
                    ? `Error: ${internalError.message}`
                    : `Error: ${error.message}`;
            errorCode = error.status;
            return throwError(new JstHttpError(message, errorCode, url));
        } else if (typeof error.error === "string") {
            // MSALError defines error.error as a string (errorcode) and errorDesc as message.
            const displayMessage = error.errorDesc || `Error: ${error.error}`;
            errorCode = undefined;
            const stack = error.stack;

            // A nuisance is being thrown by the MSAL interceptor after tokens expire.
            if (MSAL_ERROR_IGNORE.includes(error.error)) {
                const userLoginError = new JstError(
                    error.error,
                    { displayMessage, functionName, className: "BackendService" },
                    true
                );
                userLoginError.stack = stack;
                return throwError(userLoginError);
            } else if (MSAL_ERROR_DESC_IGNORE.includes(error.error)) {
                return undefined;
            }
            // MSAL errors are crazy, make them less crazy.
            error = new JstError(error.error, { displayMessage, functionName, className: "BackendService" }, false);
            error.stack = stack;
        } else {
            if (this.isErrorToBeIgnored(error)) {
                return undefined;
            }
            message = `Error: ${error.message}`;
        }

        return throwError(formatError(error, { displayMessage: message, functionName, className: "BackendService" }));
    }
}
