export class JstError extends Error {
    constructor(message: string, public params: IErrorParameters, public suppressLogging: boolean = false) {
        super(message);
        this.params = params;
    }
}

export class JstHttpError extends Error {
    constructor(message: string, public errorCode: string | number, public url: string) {
        super(message);
        this.errorCode = errorCode;
        this.url = url;
    }
}

export interface IErrorParameters {
    displayMessage: string;
    functionName: string;
    className: string;
    [key: string]: string;
}
