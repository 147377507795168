import { ISourceSystemReference } from "../../models/ISourceSystemReference";

/**
 * Optimization to compare two source systems. lodash.isEqual is too slow to use in this performance
 * sensitive code.
 */
export const sourceSystemReferencesAreEqual = (
    left: ISourceSystemReference,
    right: ISourceSystemReference
): boolean => {
    if (!left || !right) {
        return false;
    }
    return left.referenceId === right.referenceId && left.sourceSystemName === right.sourceSystemName;
};
