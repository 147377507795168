import { AnalyticsService } from "./services/analytics.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { HotjarService } from "./services/hotjar.service";
import { AnalyticsCategory } from "../../../common/constants/AnalyticsCategory";
import { IUserInfo } from "src/models/IUserInfo";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject();

    tokenFailureSubscription: Subscription;
    taskXUrl = environment.taskx;
    crmUrl = environment.crm;
    documentsUrl = environment.documents;
    reportingUrl = environment.reporting;

    title = "Findex";
    stage: string = "";
    isIframe: boolean;
    user$: Observable<IUserInfo>;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private hotjarService: HotjarService
    ) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.analyticsService.updateLocation(event.urlAfterRedirects);
                this.analyticsService.updatePageView();
            }
        });

        this.hotjarService.init(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;
        if (this.analyticsService) {
            this.analyticsService.initialize();
        }
        this.stage = environment.environment;
        this.user$ = this.authenticationService.getUser();
    }

    ngOnDestroy() {
        if (this.tokenFailureSubscription) {
            this.tokenFailureSubscription.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    login() {
        this.authenticationService.login();
    }
    newScope() {
        this.router.navigate(["scope", "client"], { replaceUrl: true });
    }
    logout() {
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, "app_nav_logout");
        this.authenticationService.logout();
    }

    raiseAnalyticsEvent(eventType: string) {
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, eventType);
    }
}
