import uniq from "lodash/uniq";

// used to concatenate error messages
export const buildValidity = (
    validity: boolean | Array<string>,
    valid: boolean | Array<string>
): boolean | Array<string> => {
    let errors: string[] = [];
    let isValid = true;
    if (Array.isArray(validity)) {
        errors = [...validity];
        isValid = false;
    } else {
        isValid = validity;
    }
    if (Array.isArray(valid)) {
        errors = [...errors, ...valid];
    } else {
        // one false breaks all
        isValid = isValid ? (isValid = valid as boolean) : isValid;
    }

    if (errors.length > 0) {
        return uniq(errors); // return error strings
    }

    return isValid; // just return the booleans
};

export const isValid = (validity: boolean | Array<string>): boolean => {
    if (Array.isArray(validity)) {
        return false;
    }
    return validity;
};
