export enum CommonFeatureFlags {
    YEARLESS_SCOPES = "yearlessScopes",
}

export enum Environments {
    QA = "qa",
    UAT = "uat",
    PROD = "prod",
    DEV = "dev",
}

export const commonFeatureFlags: { [env in Environments]: { [flag in CommonFeatureFlags]: boolean } } = {
    [Environments.DEV]: { [CommonFeatureFlags.YEARLESS_SCOPES]: true },
    [Environments.QA]: { [CommonFeatureFlags.YEARLESS_SCOPES]: true },
    [Environments.UAT]: { [CommonFeatureFlags.YEARLESS_SCOPES]: true },
    [Environments.PROD]: { [CommonFeatureFlags.YEARLESS_SCOPES]: true },
};
