import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { uniqBy } from "lodash";
import { IEntity } from "../../../../../../../common/models/IEntity";
import { ClientRepository } from "src/app/services/repositories/client.repository";

export interface IRolloverWarningDialogComponentData {
    scopes: Array<{
        scopeName: string;
        scopeId: string;
    }>;
    entities: IEntity[];
    previousYear: number;
    selectedScopeId?: string;
}

@Component({
    selector: "app-rollover-warning-dialog",
    templateUrl: "./rollover-warning-dialog.component.html",
    styleUrls: ["./rollover-warning-dialog.component.scss"],
})
export class RolloverWarningDialogComponent {
    refreshing = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IRolloverWarningDialogComponentData,
        public dialogRef: MatDialogRef<RolloverWarningDialogComponent>,
        private clientRepository: ClientRepository
    ) {}

    selectScope(scopeId: string) {
        this.data.selectedScopeId = scopeId;
    }

    ignore() {
        return {
            ...this.data,
            selectedScopeId: "",
        };
    }

    navigateToProductSelection(productId: any) {
        window.open(`/scope/${productId}/service/selection`, "_blank");
    }

    refresh() {
        const entityIds = this.data.entities.map((entity) => entity.entityId);
        this.refreshing = true;
        this.clientRepository.getWorkItemsForEntities(entityIds, this.data.previousYear).subscribe((workItems) => {
            this.refreshing = false;
            const scopes = uniqBy(
                workItems.map((workItem) => {
                    return {
                        scopeName: workItem.scopeName,
                        scopeId: workItem.scopeId,
                    };
                }),
                "scopeId"
            );

            if (scopes && scopes.length > 1) {
                this.data.scopes = scopes;
                this.data.selectedScopeId = "";
            } else {
                const scopeId = scopes && scopes[0] ? scopes[0].scopeId : "";
                this.dialogRef.close({
                    ...this.data,
                    selectedScopeId: scopeId,
                });
            }
        });
    }
}
