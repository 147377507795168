import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { uniqWith } from "lodash";
import { Moment } from "moment";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { CustomerService } from "src/app/services/customer.service";
import { EntityService } from "src/app/services/entity.service";
import { LoggingService } from "src/app/services/logging.service";
import { MessageOfTheDayService } from "src/app/services/message-of-the-day.service";
import { ScopeService } from "src/app/services/scope.service";
import { InjectableEntityValidator } from "src/app/validators/entity-validator";
import { InjectableScopeValidator } from "src/app/validators/scope-validator";
import { ScopeStages } from "src/constants/Scope";
import { AnalyticsCategory } from "../../../../../../common/constants/AnalyticsCategory";
import { IEntity } from "../../../../../../common/models/IEntity";
import { IPrefill, IPrefillResponse, IRuntimePrefill } from "../../../../../../common/models/IPrefill";
import { IRelationship } from "../../../../../../common/models/IRelationship";
import { WriteStatus } from "../../../../../../common/models/IRuntime";
import { IScopeSaveRequest, IScope } from "../../../../../../common/models/IScope";
import { ISourceSystemReference } from "../../../../../../common/models/ISourceSystemReference";
import { IWorkItem, IYearlyWorkItem } from "../../../../../../common/models/IWorkItem";
import { DateTimeHelper } from "../../../../../../common/src/helpers/DateTimeHelper";
import { sourceSystemReferencesAreEqual } from "../../../../../../common/src/helpers/SourceSystemReferenceHelper";
import { isValid } from "../../../../../../common/src/validators/ValidatorBase";
import { AnalyticsService } from "../../../services/analytics.service";
import { ProgressService } from "../../../services/progress.service";

@Component({
    selector: "app-client",
    templateUrl: "./client.component.html",
    styleUrls: ["./client.component.scss"],
})
export class ClientComponent implements OnInit, OnDestroy {
    scope: IScopeSaveRequest;
    pageTitle = "Clients";
    ngUnsubscribe = new Subject();
    entities: IEntity[] = [];
    progress$ = new Subject();
    loadSteps = ["prefill"];
    makingRequests = false;
    prefill: IPrefill;
    years: Array<number>;
    year: number;
    startDate: string;
    endDate: string;
    primaryEntity: IEntity;
    referenceId: string;
    sourceSystemName: string;
    relationships: IRelationship[];
    workItems: (IWorkItem | IYearlyWorkItem)[];
    userEmail: string;

    detailsLastUpdated: string = undefined;

    constructor(
        private customerService: CustomerService,
        private scopeService: ScopeService,
        private loggingService: LoggingService,
        private motdService: MessageOfTheDayService,
        private progressService: ProgressService,
        private scopeValidator: InjectableScopeValidator,
        private entityService: EntityService,
        private entityValidator: InjectableEntityValidator,
        private router: Router,
        private location: Location,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {
        this.progressService.setStage(ScopeStages.prefill);
        this.progressService.clearDirty();
        this.loadInitialPage();
    }

    loadInitialPage(): void {
        this.makingRequests = true;
        this.getScopeFromService()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap((scope) => this.initializeView(scope)),
                switchMap((scope) => this.loadPrefillData())
            )
            .subscribe(
                () => {
                    this.makingRequests = false;
                },
                (error) => {
                    this.makingRequests = false;
                    this.loggingService.error(error);
                }
            );
    }

    loadPrefillData(): Observable<{
        entities: IEntity[];
        primaryEntity?: IEntity;
        prefill: IPrefill;
    }> {
        this.rerouteToFamilyGroupHead(this.scopeService.getSourceSystemReference());
        this.setSourceSystemReference(this.scopeService.getSourceSystemReference());
        return this.getCustomers(this.referenceId, this.sourceSystemName, "FAMILY_GROUP_ID").pipe(
            takeUntil(this.ngUnsubscribe),
            tap((customerData) => this.displayCustomerData(customerData))
        );
    }

    onPrefillButtonClick(): void {
        this.makingRequests = true;
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, "scoping_clientdetails_refreshprefill");
        this.loadPrefillData()
            .pipe(switchMap((customerData) => this.saveCustomerData(this.scope, customerData)))
            .subscribe(
                () => {
                    this.makingRequests = false;
                },
                (error) => {
                    this.makingRequests = false;
                    this.handleError(error);
                }
            );
    }

    getDetailsLastUpdated(scope: IScope): string | undefined {
        if (scope === undefined || scope === null) {
            return undefined;
        }

        const prefills = scope.prefills;
        if (!prefills || !Array.isArray(prefills)) {
            return undefined;
        }

        const newDetails: Moment | undefined = prefills.reduce((currentDetails: Moment | undefined, prefill) => {
            if (prefill.request === undefined) {
                return undefined;
            }
            const requestTime = prefill.request.time;
            const timeUpdatedInRequest = DateTimeHelper.toDateObject(requestTime);
            if (DateTimeHelper.isValidMoment(timeUpdatedInRequest)) {
                if (currentDetails === undefined) {
                    return timeUpdatedInRequest;
                }
                if (currentDetails.isBefore(timeUpdatedInRequest)) {
                    return timeUpdatedInRequest;
                } else {
                    return currentDetails;
                }
            }

            return undefined;
        }, undefined);

        if (newDetails === undefined) {
            return undefined;
        }

        return DateTimeHelper.fromNow(newDetails);
    }

    private getPrimaryEntityFromPrefill(prefill: IPrefillResponse, entityId: ISourceSystemReference): IEntity {
        return (
            prefill.primaryEntity ||
            (Array.isArray(prefill.entities) &&
                prefill.entities.find((entity) => sourceSystemReferencesAreEqual(entity.entityId, entityId)))
        );
    }

    private getScopeFromService(): Observable<IScope> {
        return this.scopeService.getScope();
    }

    getCustomers(
        prefillId: string,
        locationCode: string,
        apsType: string
    ): Observable<{ entities: IEntity[]; primaryEntity?: IEntity; prefill: IPrefill }> {
        const entityId: ISourceSystemReference = {
            referenceId: prefillId,
            sourceSystemName: locationCode,
        };
        const prefillResponse$ = this.customerService.getCustomers(entityId, apsType);
        const currentRequest = {
            prefillId,
            locationCode,
            apsType,
            time: new Date().toISOString(),
        };
        return prefillResponse$.pipe(
            switchMap((prefillResponse) => {
                const primaryEntity = this.getPrimaryEntityFromPrefill(prefillResponse, {
                    referenceId: prefillId,
                    sourceSystemName: locationCode,
                });
                return combineLatest([of(prefillResponse.entities), of(primaryEntity), of(prefillResponse)]);
            }),
            switchMap(([entities, primaryEntity, prefillResponse]: [IEntity[], IEntity, IPrefillResponse]) => {
                if (primaryEntity == null) {
                    primaryEntity = this.primaryEntity;
                }
                this.warnIfNoRelationships(prefillResponse.relationships);
                this.warnIfRelationshipsIncomplete(prefillResponse.relationships);
                return of({
                    entities,
                    primaryEntity,
                    prefill: { request: currentRequest, response: prefillResponse },
                });
            })
        );
    }

    private warnIfNoRelationships(relationships: IRelationship[]): void {
        if (relationships.length === 0) {
            this.motdService.createMessage(
                "Warning: This family group is not linked to any current staff members",
                "OK"
            );
        }
    }

    private warnIfRelationshipsIncomplete(relationships: IRelationship[]): void {
        relationships.forEach((relationship) => {
            if (!relationship.email) {
                this.motdService.createMessage(
                    `The relationship ${relationship.givenName} ${relationship.familyName}
                    does not have an email. Please check APS and refresh prefill again.`,
                    "OK"
                );
            }
        });
    }

    getEntityDebtorName(entity: IEntity): string {
        const debtor = this.entities.find((param) => {
            return sourceSystemReferencesAreEqual(param.entityId, entity.debtorId);
        });

        return debtor?.name || "";
    }

    private updateEntities(entities: IEntity[]): void {
        this.entities = this.entities
            .map((entity) => {
                const matchingEntity = entities.find((updatedEntity) =>
                    sourceSystemReferencesAreEqual(entity.entityId, updatedEntity.entityId)
                );
                if (!matchingEntity) {
                    const entityCanBeModified = this.scopeValidator.entityCanBeModified(entity, this.workItems);
                    return entityCanBeModified ? undefined : entity;
                }
                const entityIsValid = this.entityValidator.isValidEntity(matchingEntity);
                if (!isValid(entityIsValid)) {
                    const errorMessages = Array.isArray(entityIsValid) ? entityIsValid.join(" ") : "";
                    this.motdService.createMessage(
                        `The entity ${entity.name} is invalid. ${errorMessages}
                         Please fix the data in APS and refresh the prefill`,
                        "OK"
                    );
                    return {
                        ...matchingEntity,
                        active: false,
                    };
                }
                return {
                    ...matchingEntity,
                    active: this.entityService.isActiveEntity(matchingEntity),
                };
            })
            .filter((entity) => entity !== undefined);

        // if there are new entities
        const newEntities = entities.filter(
            (updatedEntity) =>
                !this.entities.find((existingEntity) =>
                    sourceSystemReferencesAreEqual(existingEntity.entityId, updatedEntity.entityId)
                )
        );
        if (newEntities.length > 0) {
            const activeNewEntities = newEntities.map((newEntity) => {
                return {
                    ...newEntity,
                    active: this.entityService.isActiveEntity(newEntity),
                };
            });
            this.entities = [...this.entities, ...activeNewEntities];
        }
        const existingEntities = entities.filter((updatedEntity) =>
            this.entities.find((existingEntity) =>
                sourceSystemReferencesAreEqual(existingEntity.entityId, updatedEntity.entityId)
            )
        );
        this.entities = uniqWith([...existingEntities, ...this.entities], (entity1, entity2) =>
            sourceSystemReferencesAreEqual(entity1.entityId, entity2.entityId)
        );
    }

    handleError(error: any): void {
        this.motdService.createErrorMessage(error, "OK");
        this.loggingService.error(error);
    }

    canBeSaved(): boolean {
        return this.entities.length > 0;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private initializeView(componentView: IScope): void {
        this.detailsLastUpdated = this.getDetailsLastUpdated(componentView);
        this.scope = {
            prefills: componentView.prefills.map((prefill) => ({ ...prefill, writeStatus: WriteStatus.create })),
            entities: [],
            engagements: [],
            workItems: [],
            country: undefined,
            primaryEntity: undefined,
        };
        if (componentView == null) {
            const entityId = this.scopeService.getSourceSystemReference();
            this.rerouteToFamilyGroupHead(entityId);
            this.setSourceSystemReference(entityId);
            return;
        }
        const { entities, workItems, primaryEntity } = componentView;
        this.rerouteToFamilyGroupHead(primaryEntity.entityId || this.scopeService.getSourceSystemReference());
        this.setSourceSystemReference(primaryEntity.entityId || this.scopeService.getSourceSystemReference());
        this.entities = entities;
        this.workItems = workItems || [];
    }

    setSourceSystemReference(sourceSystemReference: ISourceSystemReference): void {
        this.referenceId = sourceSystemReference.referenceId;
        this.sourceSystemName = sourceSystemReference.sourceSystemName;
    }

    rerouteToFamilyGroupHead(sourceSystemReference: ISourceSystemReference): void {
        if (
            this.referenceId === sourceSystemReference.referenceId &&
            this.sourceSystemName === sourceSystemReference.sourceSystemName
        ) {
            return;
        }
        this.location.replaceState(
            [
                "yearless",
                "sourceSystemName",
                sourceSystemReference.sourceSystemName,
                "referenceId",
                sourceSystemReference.referenceId,
                "client",
            ].join("/")
        );
    }

    async onSaveToScopeClick(): Promise<void> {
        this.makingRequests = true;
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, "scoping_clientdetails_savetoscope");
        try {
            const savedScope = await this.scopeService.saveScope(this.scope).toPromise();

            this.makingRequests = false;
            if (savedScope) {
                this.progressService.clearDirty();
                this.navigateToProductSelection();
            }
        } catch (error) {
            this.makingRequests = false;
            this.handleError(error);
        }
    }

    getPrefillButtonName(): "Refresh Prefill" | "Prefill" {
        return this.entities.length > 0 ? "Refresh Prefill" : "Prefill";
    }

    canSaveToScope(): boolean {
        return this.canBeSaved() && !this.makingRequests;
    }

    entityCanBeModified(entity: IEntity, workItems: IWorkItem[]): boolean {
        return this.scopeValidator.entityCanBeModified(entity, workItems);
    }

    private displayCustomerData(customerData: {
        entities: IEntity[];
        primaryEntity?: IEntity;
        prefill: IPrefill;
    }): void | Observable<any> {
        if (customerData == null) {
            return of(null);
        }
        const { entities, primaryEntity, prefill } = customerData;
        this.updateEntities(entities);
        this.prefill = prefill;
        this.primaryEntity =
            primaryEntity || this.entities.find((entity) => entity.entityId.referenceId === prefill.request.prefillId);
    }

    private saveCustomerData(
        scope: IScopeSaveRequest,
        customerData: { entities: IEntity[]; primaryEntity?: IEntity; prefill: IPrefill }
    ): Observable<IScope> {
        const prefillToSave = { ...customerData.prefill, writeStatus: WriteStatus.create };
        return this.savePrefill(prefillToSave);
    }

    private navigateToProductSelection(): void {
        this.router.navigate(
            [
                "yearless",
                "sourceSystemName",
                this.sourceSystemName,
                "referenceId",
                this.referenceId,
                "service",
                "selection",
            ],
            {
                replaceUrl: true,
            }
        );
    }

    private savePrefill(prefill: IRuntimePrefill): Observable<IScope> {
        const saveScope: IScope = {
            workItems: [],
            engagements: [],
            prefills: [prefill],
            country: "",
            entities: [],
            primaryEntity: undefined,
            relationships: [],
        };
        return this.scopeService.saveScope(saveScope);
    }

    deleteEntity($event: any): void {
        // With prefills we only create entity, never update or delete.
        throw new Error("Not implemented");
    }
}
