import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject, timer, Observable, from } from "rxjs";
import { NotificationService } from "src/app/services/notification.service";
import { map, takeUntil, filter, share, take } from "rxjs/operators";
import { INotification, NotificationAlertLevel } from "../../../../../../common/models/INotification";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import {
    NotificationDialogComponent,
    INotificationDialogData,
} from "./notification.dialog/notification.dialog.component";
import { environment } from "src/environments/environment";
import { LoggingService } from "src/app/services/logging.service";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
    selector: "app-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit, OnDestroy {
    menuNotifications = new Subject<INotification[]>();
    ngUnsubscribe = new Subject();
    notificationPoller: Observable<number>;

    constructor(
        private notificationService: NotificationService,
        private snackBar: MatSnackBar,
        private router: Router,
        private dialog: MatDialog,
        private loggingService: LoggingService,
        private authenticationService: AuthenticationService
    ) {
        this.notificationPoller = timer(0, environment.notificationTimeout).pipe(takeUntil(this.ngUnsubscribe));
    }

    ngOnInit() {
        this.authenticationService
            .getUser()
            .pipe(
                filter((user) => !!user),
                take(1)
            )
            .subscribe(() => {
                this.notificationPoller.subscribe(() => this.checkNotifications().subscribe());
            });
    }

    /**
     * Checks all notifications and funnels them all to their appropriate
     * notification mechanism.
     */
    checkNotifications(): Observable<{}> {
        const allNotifications = this.notificationService.getNotifications().pipe(share());
        const notificationFilter = (alertLevel: NotificationAlertLevel) =>
            map((notifications: INotification[]): INotification[] =>
                notifications.filter((notification) => notification.alertLevel === alertLevel)
            );
        const mapTypeToProcessor = (
            alertLevel: NotificationAlertLevel,
            notificationProcessor: (notifications: INotification[]) => void
        ) =>
            allNotifications
                .pipe(
                    notificationFilter(alertLevel),
                    filter((notifications) => Array.isArray(notifications) && notifications.length > 0)
                )
                .subscribe(
                    (notifications) => notificationProcessor(notifications),
                    (error) => this.handleError(error)
                );
        const dialogNotifications = mapTypeToProcessor(
            NotificationAlertLevel.DIALOG,
            this.processDialogNotifications.bind(this)
        );

        const modalDialogNotifications = mapTypeToProcessor(
            NotificationAlertLevel.MODAL_DIALOG,
            this.processModalDialogNotifications.bind(this)
        );
        const menuNotifications = mapTypeToProcessor(
            NotificationAlertLevel.MENU,
            this.processMenuNotifications.bind(this)
        );
        const roadblockNotifications = mapTypeToProcessor(
            NotificationAlertLevel.ROADBLOCK,
            this.processRoadblockNotifications.bind(this)
        );
        const toasterNotifications = mapTypeToProcessor(
            NotificationAlertLevel.TOASTER,
            this.processToasterNotifications.bind(this)
        );

        return from(
            Promise.all([
                dialogNotifications,
                modalDialogNotifications,
                menuNotifications,
                roadblockNotifications,
                toasterNotifications,
            ])
        );
    }

    async processDialogNotifications(notifications: INotification[]) {
        await notifications.reduce(async (acc, notification) => {
            await acc;
            const dialogData: INotificationDialogData = { message: notification.message };
            const dialog = this.dialog.open(NotificationDialogComponent, { data: dialogData });
            return dialog.afterClosed().toPromise();
        }, Promise.resolve({}));
    }

    async processModalDialogNotifications(notifications: INotification[]) {
        await notifications.reduce(async (acc, notification) => {
            await acc;
            const dialogData: INotificationDialogData = { message: notification.message };
            const dialog = this.dialog.open(NotificationDialogComponent, { data: dialogData });
            return dialog.afterClosed().toPromise();
        }, Promise.resolve({}));
    }

    processMenuNotifications(notifications: INotification[]) {
        this.menuNotifications.next(notifications);
    }

    processRoadblockNotifications(notifications: INotification[]) {
        this.router.navigate(["roadblock"]);
    }

    async processToasterNotifications(notifications: INotification[]) {
        await notifications.reduce(async (acc, notification) => {
            await acc;
            const snackbar = this.snackBar.open(notification.message, "OK");
            return snackbar.afterDismissed().toPromise();
        }, Promise.resolve({}));
    }
    /*
    processNotification(notification: INotification) {
        switch (notification.alertLevel) {
            case NotificationAlertLevel.DIALOG: {
                const dialogData: INotificationDialogData = {
                    message: notification.message
                };
                this.dialog.open(NotificationDialogComponent, { data: dialogData });
                break;
            }
            case NotificationAlertLevel.MODAL_DIALOG: {
                const dialogData: INotificationDialogData = {
                    message: notification.message
                };
                this.dialog.open(NotificationDialogComponent, { data: dialogData });
                break;
            }
            case NotificationAlertLevel.MENU:
                this.menuNotifications.next([notification]);
                break;
            case NotificationAlertLevel.ROADBLOCK:
                this.router.navigate(["roadblock"]);
                break;
            case NotificationAlertLevel.TOASTER:
                this.snackBar.open(notification.message, "OK");
                break;
        }
    }
    */
    getMenuNotifications(): Observable<INotification[]> {
        return this.menuNotifications;
    }
    private handleError(error: Error): void {
        this.loggingService.error(error);
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
