import { Injectable } from "@angular/core";
import Driver from "driver.js";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LocalStorageService } from "./local-storage.service";
import { appVersion } from "../../../../common/constants/Release";
import semver from "semver";

@Injectable({
    providedIn: "root",
})
export class TourService {
    constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) {}

    getTourSteps(stepIdentifier: string): Observable<Driver.Step[] | null> {
        const localStorageTourKey = `tour:${stepIdentifier}`;
        const lastTourVersion = this.localStorageService.get(localStorageTourKey);
        this.localStorageService.save(localStorageTourKey, appVersion);
        return this.httpClient.get(`/assets/tour/${stepIdentifier}.json`).pipe(
            map((steps: (Driver.Step & { version: string })[]) => {
                if (!Array.isArray(steps) || steps.length == 0) {
                    return null;
                }
                return steps.filter(
                    (step) => lastTourVersion == null || this.versionIsLater(step.version, lastTourVersion)
                );
            })
        );
    }

    versionIsLater(version1: string, version2: string): boolean {
        return semver.gt(version1, version2);
    }
}
