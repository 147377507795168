import { Component } from "@angular/core";
import { commonEnvironment } from "../../../environments/environment.common";

@Component({
    selector: "app-site-maintenance",
    templateUrl: "./site-maintenance.component.html",
    styleUrls: ["./site-maintenance.component.scss"],
})
export class SiteMaintenanceComponent {
    openCurrentStatusUrl() {
        const newWindow = window.open(commonEnvironment.currentStatusUrl);
        if (newWindow) {
            newWindow.opener = null;
        }
    }

    openFeedbackUrl() {
        const newWindow = window.open(commonEnvironment.feedbackUrl);
        if (newWindow) {
            newWindow.opener = null;
        }
    }
}
