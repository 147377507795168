import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmDialogComponent } from "./confirm-dialog.component";
import { MaterialModule } from "../../../modules/material.module";

@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [CommonModule, MaterialModule],
    exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
