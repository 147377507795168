import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { empty, forkJoin } from "rxjs";
import { catchError, expand, finalize, map, takeUntil } from "rxjs/operators";
import { CsvService } from "src/app/services/csv.service";
import { CustomerService } from "src/app/services/customer.service";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { GraphService } from "src/app/services/graph.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LoggingService } from "src/app/services/logging.service";
import { MessageOfTheDayService } from "src/app/services/message-of-the-day.service";
import { ClientRepository } from "src/app/services/repositories/client.repository";
import { ScopeListingService } from "src/app/services/scope-listing.service";
import { ScopeService } from "src/app/services/scope.service";
import { InjectableEntityValidator } from "src/app/validators/entity-validator";
import { InjectableScopeValidator } from "src/app/validators/scope-validator";
import { AnalyticsCategory } from "../../../../../../common/constants/AnalyticsCategory";
import { CommonFeatureFlags } from "../../../../../../common/constants/CommonFeatureFlags";
import { ClientSearchFilter } from "../../../../../../common/enums/ClientSearchFilter";
import { IClientRow } from "../../../../../../common/models/IClientRow";
import { environment } from "../../../../environments/environment";
import { AnalyticsService } from "../../../services/analytics.service";
import { EntityService } from "../../../services/entity.service";
import { WindowService } from "../../../services/window.service";
import { TourComponent } from "../../common/tour/tour.component";
import { ListingBaseComponent } from "../listing-base/listing-base.component";

@Component({
    selector: "app-client-search",
    templateUrl: "./client-search.component.html",
    styleUrls: ["./client-search.component.scss"],
})
export class ClientSearchComponent extends ListingBaseComponent implements OnInit {
    componentName = "ClientSearchComponent";
    firstLoad = true;
    searchKeyword = "";
    selectedFilters: string[] = [];

    totalScoped = 0;
    totalRevenueLY = 0;
    totalInvoicedLY = 0;
    totalRevenueYTD = 0;
    totalInvoicedYTD = 0;
    totalResults = 0;
    columns: any[] = [
        { name: "Sub Region", value: "Sub Region", visible: true },
        { name: "Matter partner", value: "Matter partner", visible: true },
        { name: "Matter manager", value: "Matter manager", visible: true },
        { name: "Prior year", value: "Prior year", visible: true },
        { name: "Selected year", value: "Selected year", visible: true },
        { name: "Scoped $", value: "Scoped $", visible: true },
    ];
    filterOptions: any[] = [
        { name: "Family group", value: "Family Group", checked: false },
        { name: "Sub region", value: "Sub Region", checked: false },
        { name: "Matter partner", value: "Matter Partner", checked: false },
        { name: "Matter manager", value: "Matter Manager", checked: false },
    ];
    @ViewChild(TourComponent, { static: true })
    tourComponent: TourComponent;

    readonly yearlessScopesFeature: boolean = environment.featureFlags[CommonFeatureFlags.YEARLESS_SCOPES];

    constructor(
        protected clientRepository: ClientRepository,
        protected loggingService: LoggingService,
        protected motdService: MessageOfTheDayService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected customerService: CustomerService,
        protected graphService: GraphService,
        protected scopeService: ScopeService,
        protected scopeListingService: ScopeListingService,
        protected entityValidator: InjectableEntityValidator,
        protected entityService: EntityService,
        protected windowService: WindowService,
        protected scopeValidator: InjectableScopeValidator,
        protected csvService: CsvService,
        public dialog: MatDialog,
        protected errorHandlerService: ErrorHandlerService,
        protected analyticsService: AnalyticsService,
        protected localStorageService: LocalStorageService
    ) {
        super(
            clientRepository,
            loggingService,
            motdService,
            router,
            activatedRoute,
            graphService,
            windowService,
            csvService,
            errorHandlerService,
            analyticsService,
            localStorageService
        );
    }

    ngOnInit(): void {
        this.logAnalyticsEvent(AnalyticsCategory.pageLoad, "client-search");

        const currentDate = moment();
        const currentYear = Number(currentDate.add(6, "months").format("YYYY")); // TODO sorry NZ
        this.getYearOptions(currentYear);

        const meVal$ = this.graphService.getMe().pipe(
            catchError((err) => {
                // failed to get ad
                this.loggingService.warn(err);
                throw err;
            })
        );

        forkJoin(meVal$)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([meVal]: any) => {
                    this.currentUserEmail = meVal["mail"];
                    this.currentSearch = "";
                    if (this.firstLoad) {
                        this.firstLoad = false;
                    }
                    this.tourComponent.initializeTour("client-tour");
                },
                (err) => this.handleError(err, "getAllMatterPartners", err.message)
            );
    }

    removeFilter(value: string): void {
        this.updateFilterOptions(value, false);
        this.onSearch();
    }

    clearFilters(): void {
        this.filterOptions = this.filterOptions.map((filter) => {
            return {
                name: filter.name,
                value: filter.value,
                checked: false,
            };
        });
        this.selectedFilters = [];
        this.onSearch();
    }

    updateFilterOptions(value: string, checked: boolean): void {
        if (checked) {
            // add checked value to selectedFilter and the rest is unchecked
            this.selectedFilters = [value];
            this.filterOptions = this.filterOptions.map((filter) => {
                return {
                    name: filter.name,
                    value: filter.value,
                    checked: filter.value === value,
                };
            });
        } else {
            this.selectedFilters = this.selectedFilters.filter((filter) => filter !== value);
        }
    }

    // hook up analytics to all methods in this region
    //#region handlers
    /**
     * Set up a row click event to go to the scope
     */
    async onRowClicked(event: any): Promise<void> {
        this.logAnalyticsEvent(AnalyticsCategory.buttonClick, "scoping_client_viewdetails");
        // CM - this code should no longer be used and will be deleted with this component in TASKX-3106
        // const country = apsCountry(event.sourceSystem);
        // const createScopeValid = this.scopeValidator.scopeCanBeCreated(
        //     { country, year: this.selectedYear },
        //     this.currentUserEmail
        // );

        const id = event.scopeId;
        const familyGroupId: string = event.familyGroupId;
        const sourceSystem: string = event.sourceSystem;

        if (id != null || (sourceSystem && familyGroupId)) {
            this.navigateToClientDetails(id, sourceSystem, familyGroupId);
        } else {
            // CM - this code should no longer be used and will be deleted with this component in TASKX-3106
            // if (!createScopeValid.valid) {
            //     const message = ['Could not create scope:', ...createScopeValid.messages].join(' ');
            //     this.motdService.createMessage(message, 'OK');
            //     return;
            // }
            const year: string = this.selectedYear.toString();
            this.navigateToPrefillUrl(sourceSystem, familyGroupId, year);
        }
    }

    onSearch(input?: any): void {
        this.logAnalyticsEvent(AnalyticsCategory.search, "scoping_client_search");

        const currentSearchKeyword = input ? input.searchKeyword : this.searchKeyword;
        this.searchKeyword = currentSearchKeyword;
        let filters = this.selectedFilters;
        const year = this.selectedYear;

        if (this.searchKeyword) {
            // if no filter is selected, apply all filters for search
            if (this.selectedFilters && this.selectedFilters.length === 0) {
                filters = Object.values(ClientSearchFilter);
            }

            this.updatePriorYear();

            const queryObservable = this.clientRepository.searchClients(this.searchKeyword, year, filters);
            this.makingRequests = true;
            let clientRows: IClientRow[] = [];

            queryObservable
                .pipe(
                    expand((response) => {
                        const { offset, hasMore, count } = response.page;

                        if (hasMore) {
                            return this.clientRepository.searchClients(
                                this.searchKeyword,
                                year,
                                filters,
                                offset + count,
                                count
                            );
                        } else {
                            return empty();
                        }
                    }),
                    map((response) => (clientRows = [...clientRows, ...response.result])),
                    finalize(() => (this.makingRequests = false))
                )
                .subscribe(
                    (clientList) => {
                        this.scopeSummaries = this.scopeListingService.formatLists(clientList);

                        this.resetTotals();
                        this.sortRows("revenueYTD");

                        this.setTotals();
                    },
                    (error) => this.handleError(error, "onSearch", error.message)
                );
        }
    }
    //#endregion handlers

    private navigateToPrefillUrl(sourceSystem: string, familyGroupId: string, year: string): void {
        this.windowService
            .window()
            .open(
                `/client/sourceSystemName/${sourceSystem}/referenceId/${familyGroupId}/year/${year}`,
                `${familyGroupId}-${sourceSystem}`
            );
    }

    private navigateToClientDetails(id: any, sourceSystemName: string, familyGroupId: string): void {
        if (this.yearlessScopesFeature) {
            window.open(`/yearless/sourceSystemName/${sourceSystemName}/referenceId/${familyGroupId}/client`, "_blank");
        } else {
            window.open(`/scope/${id}/client`, "_blank");
        }
    }

    navigateToClientListing(): void {
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, "scoping_client_viewoldclientlisting");
        this.router.navigate(["/legacy-client-listing"]);
    }

    protected resetTotals(): void {
        this.totalScoped = 0;
        this.totalRevenueLY = 0;
        this.totalInvoicedLY = 0;
        this.totalRevenueYTD = 0;
        this.totalInvoicedYTD = 0;
        this.totalResults = 0;
    }

    protected setTotals(): void {
        this.filteredScopeSummaries.forEach((summary: any): void => {
            this.totalScoped += summary.scoped;
            this.totalRevenueLY += summary.revenueLY;
            this.totalInvoicedLY += summary.invoicedLY;
            this.totalRevenueYTD += summary.revenueYTD;
            this.totalInvoicedYTD += summary.invoicedYTD;
            this.totalResults++;
        });
    }
}
