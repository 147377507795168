import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WysiwygEditorComponent } from "./wysiwyg-editor.component";
import { QuillModule } from "ngx-quill";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [WysiwygEditorComponent],
    imports: [CommonModule, QuillModule, ReactiveFormsModule],
    exports: [WysiwygEditorComponent],
})
export class WysiwygEditorModule {}
