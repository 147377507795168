import { DropDownComponent } from "./../components/common/forms/drop-down/drop-down.component";
import { DisableControlDirective } from "./../directive/disabled.directive";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgxPaginationModule } from "ngx-pagination";
import { MaterialModule } from "./material.module";
import { ProgressComponent } from "../components/yearlessScope/progress/progress.component";
import { LoadingComponent } from "../components/loading/loading.component";
import { BannerComponent } from "../components/common/banner/banner.component";
import { CustomCurrencyPipe } from "../pipe/custom-currency.pipe";
import { ClassnamePipe } from "../pipe/classname.pipe";
import { TableHeaderTitlePipe } from "../pipe/table-header-title.pipe";
import { CustomLabelPipe } from "../pipe/custom-label.pipe";
import { KeyPressDebounceDirective } from "../directive/key-press-debounce.directive";
import { MultiMonthSelectorComponent } from "./../components/multi-month-selector/multi-month-selector.component";
import { SingleMultiSelectorComponent } from "./../components/single-multi-selector/single-multi-selector.component";
import { ColumnSettingsDialogComponent } from "../components/column-settings-dialog/column-settings-dialog.component";
import { A11yModule, CdkTrapFocus } from "@angular/cdk/a11y";
import { ActionButtonComponent } from "../components/common/action-button/action-button.component";
import { SingleMultiSelectorHeaderComponent } from "../components/single-multi-selector/single-multi-selector-header.component";
import { TextBoldPipe } from "../pipe/text-bold.pipe";
import { DateSelectorComponent } from "../components/date-selector/date-selector.component";
import { RadioButtonGroupComponent } from "../components/radio-button-group/radio-button-group.component";
import { StatusLegendComponent } from "./../components/status-legend/status-legend.component";
import { TourComponent } from "../components/common/tour/tour.component";
import { ServiceLineFullDescriptionPipe } from "../pipe/service-line-full-description.pipe";
import { FxUiModule } from "@findex/fx-ui";
import { SearchBarComponent } from "../components/search-bar/search-bar.component";
import { WysiwygEditorModule } from "../components/common/wysiwyg-editor/wysiwyg-editor.module";
import { WorkItemHasConnectorPipe } from "../components/yearlessScope/workitem/work-item.pipe";
import { CountButtonComponent } from "../components/count-button/count-button.component";
import { MonthSelectorComponent } from "../components/month-selector/month-selector.component";
import { ScheduleSelectorComponent } from "../components/schedule-selector/schedule-selector.component";
import { SelectComponent } from "../components/select/select.component";
import { OptionComponent } from "../components/select/option.component";
import { ServiceFilterComponent } from "../components/yearlessScope/service-filter/service-filter.component";
import { CdkScrollableModule } from "@angular/cdk/scrolling";
import { OverlayModule } from "@angular/cdk/overlay";

const usedModules = [
    CommonModule,
    MaterialModule,
    FxUiModule,
    NgxPaginationModule,
    A11yModule,
    FormsModule,
    WysiwygEditorModule,
    CdkScrollableModule,
    OverlayModule,
];
@NgModule({
    imports: usedModules,
    declarations: [
        ActionButtonComponent,
        BannerComponent,
        ClassnamePipe,
        ColumnSettingsDialogComponent,
        CustomCurrencyPipe,
        CustomLabelPipe,
        DateSelectorComponent,
        DisableControlDirective,
        KeyPressDebounceDirective,
        LoadingComponent,
        MultiMonthSelectorComponent,
        ProgressComponent,
        RadioButtonGroupComponent,
        SingleMultiSelectorComponent,
        SingleMultiSelectorHeaderComponent,
        StatusLegendComponent,
        TableHeaderTitlePipe,
        TextBoldPipe,
        TourComponent,
        DropDownComponent,
        ServiceLineFullDescriptionPipe,
        WorkItemHasConnectorPipe,
        SearchBarComponent,
        CountButtonComponent,
        MonthSelectorComponent,
        ServiceFilterComponent,
        ScheduleSelectorComponent,
        SelectComponent,
        OptionComponent,
    ],
    exports: [
        ActionButtonComponent,
        BannerComponent,
        ClassnamePipe,
        ColumnSettingsDialogComponent,
        CustomCurrencyPipe,
        CustomLabelPipe,
        DateSelectorComponent,
        DisableControlDirective,
        KeyPressDebounceDirective,
        LoadingComponent,
        MultiMonthSelectorComponent,
        ProgressComponent,
        RadioButtonGroupComponent,
        SingleMultiSelectorComponent,
        SingleMultiSelectorHeaderComponent,
        StatusLegendComponent,
        TableHeaderTitlePipe,
        TextBoldPipe,
        TourComponent,
        usedModules,
        DropDownComponent,
        ServiceLineFullDescriptionPipe,
        SearchBarComponent,
        WorkItemHasConnectorPipe,
        CountButtonComponent,
        MonthSelectorComponent,
        ServiceFilterComponent,
        ScheduleSelectorComponent,
        SelectComponent,
        OptionComponent,
    ],
    providers: [CurrencyPipe, CdkTrapFocus],
})
export class CommonJSTModule {}
