import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";

export interface PrefillData {
    prefillId: string;
    locationCode: string;
    apsType: string;
}
@Component({
    templateUrl: "./prefill.dialog.component.html",
    styleUrls: ["./prefill.dialog.component.scss"],
})
export class PrefillDialogComponent implements OnInit, OnDestroy {
    prefillId: FormControl;
    locationCode: FormControl;
    apsType: FormControl;
    apsTypes = [
        { name: "Debtor ID", value: "DEBTOR_ID" },
        { name: "Family Group ID", value: "FAMILY_GROUP_ID" },
    ];
    subscriptions: Subscription[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: PrefillData) {}

    ngOnInit() {
        this.prefillId = new FormControl(this.data.prefillId);
        this.locationCode = new FormControl(this.data.locationCode);
        this.data.apsType = "FAMILY_GROUP_ID";
        this.apsType = new FormControl(this.data.apsType);
        this.subscriptions = [
            this.prefillId.valueChanges.subscribe((id) => (this.data.prefillId = id)),
            this.locationCode.valueChanges.subscribe((location) => (this.data.locationCode = location)),
            this.apsType.valueChanges.subscribe((apsType) => (this.data.apsType = apsType)),
        ];
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
