export const ScopeStages: any = {
    prefill: {
        title: "Client Details",
        active: false,
        valid: false,
        analyticsLabel: "scoping_nav_clientdetails",
    },
    productSelection: {
        title: "Select Services",
        active: false,
        valid: false,
        analyticsLabel: "scoping_nav_selectservices",
    },
    productConfiguration: {
        title: "Configure Services",
        active: false,
        valid: false,
        analyticsLabel: "scoping_nav_configureservices",
    },
    engagements: {
        title: "Configure Engagements",
        active: false,
        valid: false,
        analyticsLabel: "scoping_nav_configureengagements",
    },
    engagement: {
        title: "Engagement",
        active: false,
        valid: false,
        analyticsLabel: "scoping_nav_engagement",
    },
};
