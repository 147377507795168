import { HttpInterceptor, HttpResponse, HttpHandler, HttpEvent, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { tap, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { appVersion, appVersionKey } from "../../../../common/constants/Release";

@Injectable()

//TODO This isn't really a service, it is a interceptor, it is 100 percent tied to the concept of a Web App.
export class ScopeHttpRequestInterceptor implements HttpInterceptor {
    private versionId: string = "";

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const setHeaders = this.setHeaders(request);
        const modified = request.clone(setHeaders);

        return next.handle(modified).pipe(
            tap((evt) => {
                if (evt instanceof HttpResponse && this.isWithinScope(request.url)) {
                    if (evt.headers && evt.headers.get("version-id")) {
                        this.versionId = evt.headers.get("version-id");
                    }
                }
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    private setHeaders(request: HttpRequest<any>): { [key: string]: any } {
        const defaultHeaders = {
            [appVersionKey]: appVersion,
        };
        if (!this.isWithinJst(request.url)) {
            return {};
        }
        if (this.isWithinScope(request.url)) {
            return this.versionId
                ? {
                      setHeaders: {
                          ...defaultHeaders,
                          "version-id": this.versionId,
                      },
                  }
                : {
                      setHeaders: {
                          ...defaultHeaders,
                      },
                  };
        }
        return {
            setHeaders: {
                ...defaultHeaders,
            },
        };
    }

    private isWithinScope(url: string): boolean {
        const scopeUrl = `${environment.backendUrl}/scope`;
        const scopeUrlToExclude = `${environment.backendUrl}/scope/byFamilyGroup`;

        return url.includes(scopeUrl) && !url.includes(scopeUrlToExclude);
    }

    private isWithinJst(url: string): boolean {
        const scopeUrl = `${environment.backendUrl}`;
        const signedUrl = `s3.ap-southeast-2.amazonaws.com`;

        return url.includes(scopeUrl) && !url.includes(signedUrl);
    }
}
