import { Moment } from "moment";
import moment from "moment";
import { dateTimeFormats } from "../../constants/DateTime";

export class DateTimeHelper {
    static now(): Moment {
        return moment();
    }

    static toDateObject(date: string, format?: string): Moment {
        return moment(date, format);
    }

    static toDateDataFormat(dateTimeObject: Moment | string): string {
        const momentDate = moment(dateTimeObject);
        const dateFormat = dateTimeFormats.dateFormat.data;

        return momentDate.isValid() ? momentDate.startOf("day").format(dateFormat) : undefined;
    }

    static toMonthDataFormat(dateTimeObject: Moment | string): string {
        const momentDate = moment(dateTimeObject);
        const monthFormat = dateTimeFormats.monthFormat.data;

        return momentDate.isValid() ? momentDate.startOf("day").format(monthFormat) : undefined;
    }

    static monthYearToDataFormat(month: string, year: number): string {
        const date = moment(`${year}-${month}`, dateTimeFormats.monthFormat.data);
        return this.toDateDataFormat(date);
    }

    static toDateFormat(isoDateTimeString: Moment | string): string {
        if (!isoDateTimeString) {
            return "Please select a date"; // add this to ag-grid formatter
        }
        const dateTimeObject =
            typeof isoDateTimeString === "string"
                ? moment(isoDateTimeString, dateTimeFormats.dateFormat.data)
                : isoDateTimeString;
        return dateTimeObject.format(dateTimeFormats.dateFormat.display);
    }

    static toMonthFormat(isoDateTimeString: Moment | string): string {
        if (!isoDateTimeString) {
            return "Please select a date"; // add this to ag-grid formatter
        }
        const dateTimeObject =
            typeof isoDateTimeString === "string"
                ? moment(isoDateTimeString, dateTimeFormats.monthFormat.data)
                : isoDateTimeString;
        if (!dateTimeObject.isValid()) {
            return "";
        }
        return dateTimeObject.format(dateTimeFormats.monthFormat.display);
    }

    /**
     *
     * @param month Month in MMMM YYYY format
     */
    static monthFormatToDataFormat(monthString: string) {
        const momentObject = moment(monthString, dateTimeFormats.monthFormat.display);
        if (momentObject.isValid()) {
            return momentObject.format(dateTimeFormats.monthFormat.data);
        }
        return "";
    }

    static toMonthShortFormat(isoDateTimeString: Moment | string): string {
        if (!isoDateTimeString) {
            return "Please select a date";
        }
        const dateTimeObject =
            typeof isoDateTimeString === "string" ? moment(isoDateTimeString, "MMM") : isoDateTimeString;
        return dateTimeObject.format("MMM");
    }

    static isBefore(dateFrom: string | Moment, dateTo: string | Moment): boolean {
        const from = typeof dateFrom === "string" ? moment(dateFrom) : dateFrom;
        const to = typeof dateTo === "string" ? moment(dateTo) : dateTo;
        return from.isBefore(to);
    }

    static isBetween(dateCurrent: string | Moment, dateFrom: string | Moment, dateTo: string | Moment): boolean {
        const current = typeof dateCurrent === "string" ? moment(dateCurrent) : dateCurrent;
        const from = typeof dateFrom === "string" ? moment(dateFrom) : dateFrom;
        const to = typeof dateTo === "string" ? moment(dateTo) : dateTo;
        return current.isBetween(from, to, null, "[]");
    }

    static fromDateDataFormat(input: string) {
        return moment(input, dateTimeFormats.dateFormat.data);
    }

    static fromMonthDisplayFormat(input: string) {
        return moment(input, "MMMM YYYY");
    }

    static fromNow(date: Moment): string {
        return date.fromNow();
    }

    static isValidMoment(obj: object) {
        return moment.isMoment(obj) && obj.isValid();
    }

    static frequencyToMomentDuration(frequency: JstFrequencies): moment.Duration {
        switch (frequency) {
            case JstFrequencies.weekly:
                return moment.duration(1, "weeks");
            case JstFrequencies.fortnightly:
                return moment.duration(2, "weeks");
            case JstFrequencies.monthly:
                return moment.duration(1, "months");
            case JstFrequencies.fourWeekly:
                return moment.duration(4, "weeks");
            case JstFrequencies.biMonthly:
                return moment.duration(2, "months");
            case JstFrequencies.quarterly:
                return moment.duration(3, "months");
            case JstFrequencies.annual:
                return moment.duration(1, "years");
            case JstFrequencies.onceOff:
                return moment.duration(1, "days");
        }
    }

    /**
     * @param startDate Date in YYYY-MM-DD format
     * @param frequency A defined frequency (see code)
     * @param schedules Dates in YYYY-MM format
     */
    static calculateSchedules(startDate: string, frequency: string, schedules: string[]): string[] {
        const start = moment(startDate);

        if (frequency === JstFrequencies.onceOff) {
            return [start.format("YYYY-MM-DD")];
        }

        const duration = this.frequencyToMomentDuration(frequency as JstFrequencies);

        if (!duration) return [];

        const scheduleDurations = schedules.map((schedule) => {
            const start = this.toDateObject(schedule + "-01", "YYYY-MM-DD");
            const end = start.clone().add(1, "months").subtract(1, "days");
            return { start, end };
        });
        const latestEnd = scheduleDurations.reduce((currentLatest: moment.Moment | null, next) => {
            if (currentLatest == null) {
                return next.end;
            } else {
                return moment.max(currentLatest, next.end);
            }
        }, null);

        let matchingDates = [];
        const current = start.clone();
        while (current.isSameOrBefore(latestEnd)) {
            if (
                scheduleDurations.some(
                    (duration) =>
                        current.isSame(duration.start) ||
                        current.isSame(duration.end) ||
                        current.isBetween(duration.start, duration.end)
                )
            ) {
                matchingDates.push(current.clone());
            }
            current.add(duration);
        }
        return matchingDates.map((date) => date.format("YYYY-MM-DD"));
    }

    static localDateFormat(date: string): string {
        return moment(date).format("LLL");
    }

    static getSurrounding12MonthsDateRange(now = moment()): { startDate: string; endDate: string } {
        return {
            startDate: now.clone().subtract(12, "months").startOf("month").format(dateTimeFormats.dateFormat.data),
            endDate: now.clone().add(12, "months").endOf("month").format(dateTimeFormats.dateFormat.data),
        };
    }

    static getDateRange(
        startDate: string,
        endDate: string,
        incrementQuantity: number,
        incrementUnit: moment.unitOfTime.DurationConstructor,
        format: string
    ): string[] {
        const start = moment(startDate, format);
        const end = moment(endDate, format);
        const increment = moment.duration(incrementQuantity, incrementUnit);

        const range = [];
        if (!start.isSameOrBefore(end)) {
            throw new Error("Cannot create date range, start is after end");
        }
        let current = start.clone();
        do {
            range.push(current);
            current = current.clone().add(increment);
        } while (current.isSameOrBefore(end));

        const result = range.map((date) => date.format(format));
        return result;
    }

    // expect YYYY-MM-DD
    static nextAvailableWeekDate(date: string): string {
        const dateObject = moment(date);
        const day = dateObject.day();
        if (day === 6) {
            dateObject.add(2, "days");
        } else if (day === 0) {
            dateObject.add(1, "day");
        }
        return DateTimeHelper.toDateDataFormat(dateObject);
    }
}
export enum JstFrequencies {
    weekly = "Weekly",
    fortnightly = "Fortnightly",
    monthly = "Monthly",
    fourWeekly = "4 Weekly",
    biMonthly = "Bi-monthly",
    quarterly = "Quarterly",
    annual = "Annual",
    onceOff = "Once off",
}
