import { EngagementState } from "../enums/EngagementState";

export const EngagementStateOrder: Array<string> = [
    EngagementState.notCreated,
    EngagementState.open,
    EngagementState.draft,
    EngagementState.approved,
    EngagementState.manual,
    EngagementState.scheduled,
    EngagementState.sent,
    EngagementState.signed,
    EngagementState.notRequired,
    EngagementState.sendFailed,
];
