import { Component, OnInit } from "@angular/core";
import moment from "moment";
import { EmailSendingService } from "./email-sending.service";
import { arrayIsNullOrEmpty } from "../../../../../common/src/helpers/ArrayHelper";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../common/confirm-dialog/confirm-dialog.component";

type EmailType = "reminder" | "client";
type ScheduleType = "auto" | "manual";

@Component({
    selector: "app-email-sending",
    templateUrl: "./email-sending.component.html",
    styleUrls: ["./email-sending.component.scss"],
})
export class EmailSendingComponent implements OnInit {
    scheduleDate: string;
    scheduleDateOptions: { value: string; name: string }[];
    selectedType: ScheduleType;
    types: ScheduleType[] = ["auto", "manual"];
    arrayIsNullOrEmpty = arrayIsNullOrEmpty;

    engagements: string[];
    makingRequests = false;

    constructor(private sendingService: EmailSendingService, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.scheduleDateOptions = this.getDateOptions();
        this.scheduleDate = this.scheduleDateOptions[1].value;
        this.selectedType = this.types[0];
    }

    private reset(): void {
        this.engagements = [];
    }

    getCount(): void {
        this.sendingService.getEngagementToSend(this.scheduleDate, this.selectedType).subscribe((engagements) => {
            this.engagements = engagements;
        });
    }

    sendEmail(type: EmailType): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: "50%",
            data: {
                messages: [
                    `Are you sure you want to send ${type} emails for ${this.scheduleDate}?`,
                    "",
                    "Do not send this more than one each month!",
                ],
                title: "Bulk Email Send Confirmation",
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            // agree to proceed
            if (result) {
                this.generateEmailRequest(type);
            }
        });
    }

    private generateEmailRequest(type: EmailType): void {
        if (type === "client") {
            this.sendingService.sendClientEmail(this.scheduleDate).subscribe((_engagements) => {
                this.reset();
            });
        }
        if (type === "reminder") {
            this.sendingService.sendReminderEmail(this.scheduleDate, this.selectedType).subscribe((_engagements) => {
                this.reset();
            });
        }
    }

    private getDateOptions(): { value: string; name: string }[] {
        const currentDate = moment();
        return [moment(currentDate).subtract(1, "months").endOf("month"), moment(currentDate).endOf("month")].map(
            (date) => {
                return {
                    value: date.format("YYYY-MM-DD"),
                    name: date.format("MMMM YYYY"),
                };
            }
        );
    }
}
