import { Component, OnInit } from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
import { INotification, NotificationAlertLevel } from "../../../../../common/models/INotification";
import { filter } from "rxjs-compat/operator/filter";

@Component({
    selector: "app-roadblock",
    templateUrl: "./roadblock.component.html",
    styleUrls: ["./roadblock.component.scss"],
})
export class RoadblockComponent implements OnInit {
    messages: string[];
    constructor(private notificationService: NotificationService) {}

    ngOnInit() {
        this.notificationService
            .getNotifications()
            .subscribe((notifications) => this.processNotifications(notifications));
    }

    processNotifications(notifications: INotification[]): void {
        const notificationMessages = notifications
            .filter((notification) => notification.alertLevel === NotificationAlertLevel.ROADBLOCK)
            .reduce((acc, notification) => [...acc, notification.message], [] as string[]);

        this.messages = notificationMessages;
    }
}
