import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    OnDestroy,
    ChangeDetectorRef,
} from "@angular/core";
import { ICellData } from "src/models/ICellData";
import { FocusMonitor } from "@angular/cdk/a11y";

/**
    Usage:

    <app-date-selector
        *ngIf="field.type === 'date'"
        [cellData]="cellDatas[workItem.workItemId][field.key]"
        [value]="cellDatas[workItem.workItemId][field.key].value"
        (valueSelected)="fieldDataOnChange(workItem, field, $event.value)"
    >
 */

@Component({
    selector: "app-radio-button-group",
    templateUrl: "./radio-button-group.component.html",
    styleUrls: ["./radio-button-group.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonGroupComponent implements OnInit, OnDestroy {
    @Input() cellData: ICellData;
    @Input() name: string;
    @Input() label: string;
    @Input() key: string;
    @Input() tooltipShowDelay: number;
    @Input() tooltipHideDelay: number;
    @Output() change = new EventEmitter<string>();

    hasExtendedEllipsis: boolean = false;
    expanded: boolean = false;
    @ViewChild("toggleGroup") toggleGroup: ElementRef;
    constructor(private focusMonitor: FocusMonitor, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.hasExtendedEllipsis =
            Array.isArray(this.cellData.options) && this.cellData.options.some((option) => option.extended);
    }

    /*
     * This is to trigger closing of the component after blur.
     * It works for mouse events (when clicked outside the component)
     * It does not capture the escape button. (TODO)
     */
    ngAfterViewInit() {
        this.focusMonitor.monitor(this.toggleGroup.nativeElement, true).subscribe((event) => {
            if (event === null) {
                this.expanded = false;
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    onChange(event: string): void {
        this.change.next(event);
    }

    ngOnDestroy(): void {
        this.focusMonitor.stopMonitoring(this.toggleGroup.nativeElement);
    }
}
