import { JstError } from "./../../../../common/models/JstError";
import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
    providedIn: "root",
})
export class MessageOfTheDayService {
    constructor(private snackBar: MatSnackBar) {}
    createMessage(message?: string, action?: string, callback?: any) {
        const config: MatSnackBarConfig = {
            verticalPosition: "top",
        };
        return this.snackBar.open(message, action, config);
    }
    createErrorMessage(error?: Error, action?: string, callback?: any) {
        let errorMessage = error.message;
        if (error instanceof JstError && error.params.displayMessage) {
            errorMessage = error.params.displayMessage;
        }

        const config: MatSnackBarConfig = {
            verticalPosition: "top",
        };
        return this.snackBar.open(errorMessage, action, config);
    }

    createMessageWithAction(message?: string, action?: string, callback?: any) {
        const config: MatSnackBarConfig = {
            verticalPosition: "top",
        };
        return this.snackBar.open(message, action, config).onAction();
    }

    private executeCallback(callback: any) {}
}
