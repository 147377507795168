import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, ObservableInput, throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class MaintenanceInterceptorService implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error): ObservableInput<HttpEvent<any>> => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 503) {
                        // system is down for maintenance, redirect user to maintenance page
                        this.router.navigate(["/site-maintenance"]);
                    }
                }

                return observableThrowError(error);
            })
        );
    }
}
