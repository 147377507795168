import { ILoggingService } from "../services/ILoggingService";
import { IEntity } from "../../models/IEntity";
import { buildValidity } from "./ValidatorBase";

export class EntityValidator {
    constructor(private loggingService: ILoggingService) {}

    isValidActiveEntity(entity: IEntity, latestEntity?: IEntity): boolean | Array<string> {
        const validators = [
            this.isActiveEntity(entity),
            this.hasValidCreditStatus(entity),
            this.isUpToDate(entity, latestEntity),
        ];
        return validators.reduce((acc, next) => buildValidity(acc, next), true);
    }

    isValidEntity(entity: IEntity): boolean | Array<string> {
        const validators = [this.hasValidCreditStatus(entity), this.hasEntityType(entity)];
        return validators.reduce((acc, next) => buildValidity(acc, next), true);
    }

    private hasEntityType(entity: IEntity): boolean | Array<string> {
        if (entity.type == null) {
            return ["Entity type not provided."];
        }
        return true;
    }

    // TODO
    private hasValidCreditStatus(entity: IEntity): boolean | Array<string> {
        return true;
    }

    private isActiveEntity(entity: IEntity): boolean | Array<string> {
        return entity.active !== false;
    }

    // TODO
    isUpToDate(entity: IEntity, latestEntity?: IEntity): boolean | Array<string> {
        return true;
    }
}
