import moment from "moment";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { IEngagement } from "../../models/IEngagement";
import { arrayIsNullOrEmpty } from "./ArrayHelper";
import { IWorkItem } from "../../models/IWorkItem";

export class EngagementHelper {
    getEngagementStartDateFormatted(engagement: IEngagement): string {
        const engagementStartDate = engagement.startDate;
        const earliestWorkItemSchedule = this.getEarliestWorkItemSchedule(engagement.workItems);
        const candidateDates = [
            moment(engagementStartDate),
            ...(earliestWorkItemSchedule ? [earliestWorkItemSchedule] : []),
        ];
        const earliestDate = moment.min(candidateDates).startOf("month").format("D MMMM YYYY");
        return earliestDate;
    }

    getEngagementEndDateFormatted(engagement: IEngagement): string {
        const engagementEndDate = engagement.endDate;
        const latestWorkItemSchedule = this.getLatestWorkItemSchedule(engagement.workItems);
        const candidateDates = [moment(engagementEndDate), ...(latestWorkItemSchedule ? [latestWorkItemSchedule] : [])];
        const earliestDate = moment.max(candidateDates).endOf("month").format("D MMMM YYYY");
        return earliestDate;
    }

    private getEarliestWorkItemSchedule(workItems: IWorkItem[]): moment.Moment | null {
        const schedules = flatten(
            workItems.map((workItem) => (!arrayIsNullOrEmpty(workItem.data.schedules) ? workItem.data.schedules : []))
        );

        if (schedules.length == 0) {
            return null;
        }

        const uniqueSchedules = uniq(schedules);
        const earliestMoment = moment.min(
            uniqueSchedules
                .map((schedule) => {
                    try {
                        return moment(schedule);
                    } catch (err) {
                        // invalid schedule
                        return undefined;
                    }
                })
                .filter((dateObject) => !!dateObject)
        );
        return earliestMoment;
    }

    private getLatestWorkItemSchedule(workItems: IWorkItem[]): moment.Moment | null {
        const schedules = flatten(
            workItems.map((workItem) => (!arrayIsNullOrEmpty(workItem.data.schedules) ? workItem.data.schedules : []))
        );
        if (schedules.length == 0) {
            return null;
        }
        const uniqueSchedules = uniq(schedules);
        const latestMoment = moment.max(
            uniqueSchedules
                .map((schedule) => {
                    try {
                        return moment(schedule);
                    } catch (err) {
                        // invalid schedule
                        return undefined;
                    }
                })
                .filter((dateObject) => !!dateObject)
        );
        return latestMoment;
    }
}
