import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "customLabel",
})
export class CustomLabelPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (args && args === "complexity") {
            return value.substring(0, 1);
        }
        return value;
    }
}
