import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ClientSearchComponent } from "./client-search.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonJSTModule } from "src/app/modules/common.module";

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, CommonJSTModule],
    declarations: [ClientSearchComponent],
    exports: [],
    providers: [],
})
export class ClientSearchModule {}
