import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ICellData } from "src/models/ICellData";
import { v4 as Uuid } from "uuid";

/**
    Usage:

    <app-multi-month-selector
        [options]="[
            {
                type: 'checkbox',
                value: '2019',
                name: 'checkbox',
                id: 'm2019',
                label: '2019',
                selected: true
            }
        ]"
        [layout]="'inline'"
    ></app-multi-month-selector>
 */

export interface IMultiMonthSelectOption {
    type: string;
    value: string;
    name: string;
    id: string;
    label: string;
    tooltip: string;
    selected?: boolean;
    disabled?: boolean;
}

@Component({
    selector: "app-multi-month-selector",
    templateUrl: "./multi-month-selector.component.html",
    styleUrls: ["./multi-month-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiMonthSelectorComponent implements OnInit, OnChanges {
    @Input() cellData: ICellData;
    @Input() value: string[];
    @Input() mandatory: boolean;
    @Input() type: string;
    @Input() showMonths: boolean;
    @Output() valueSelected = new EventEmitter();
    mappedOptions: Array<IMultiMonthSelectOption> = [];
    isFocussed = true;
    isDisabled = false;
    selectedString = "";
    tooltipShowDelay = new FormControl(1000);
    tooltipHideDelay = new FormControl(0);
    engaged = false;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.initialiseOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initialiseOptions();
    }

    initialiseOptions(): void {
        const val = this.cellData.value ? this.cellData.value : [];
        this.engaged = this.cellData.metadata.engaged;

        this.mappedOptions = [];

        if (this.cellData && this.cellData.options && this.cellData.options.length) {
            this.isDisabled = !(this.cellData.metadata && this.cellData.metadata.editable) || false;
            this.cellData.options.forEach((option: any) => {
                const selected = (val && val.indexOf(option.value) !== -1) || false;
                this.mappedOptions.push({
                    type: "checkbox",
                    value: option.value,
                    name: `name${option.name}`,
                    id: `id${Uuid()}${option.value}`,
                    label: option.name.substring(0, 1),
                    tooltip: option.name,
                    selected,
                    disabled: this.isDisabled,
                });
            });

            this.setSelectedSummary();
        }
    }

    open(event: any): void {
        this.showMonths = true;
    }

    updateSelectedOptions(option: any, event: Event): void {
        if (event.target instanceof HTMLInputElement) {
            if (this.cellData && this.cellData.metadata && this.cellData.metadata.optionLimit === 1) {
                this.manageSingleValue(option.value, event.target.checked);
            } else {
                this.manageMultiValue(option.value, event.target.checked);
            }
        }

        this.valueSelected.next(this.cellData);
        this.setSelectedSummary();
    }

    setSelectedSummary(): void {
        if (this.mappedOptions && this.mappedOptions.length) {
            if (this.mappedOptions.filter((option) => option.selected === true).length > 1) {
                this.selectedString = `(${this.mappedOptions.filter((option) => option.selected === true).length})`;
            } else if (this.mappedOptions.filter((option) => option.selected === true).length === 1) {
                this.selectedString = `(${this.mappedOptions.filter((option) => option.selected === true)[0].label})`;
            } else {
                this.selectedString = "(0)";
            }
        }
    }

    hasValueSelected(): IMultiMonthSelectOption {
        return this.mappedOptions.find((option: any) => option.selected);
    }

    manageMultiValue(value: string, checked: boolean): void {
        if (!Array.isArray(this.cellData.value)) {
            this.cellData.value = [];
        }
        this.cellData.value = this.cellData.value.filter((cellDataValue: any) => cellDataValue !== value);
        if (checked) {
            this.cellData.value = [...this.cellData.value, value];
        }
    }

    manageSingleValue(value: string, checked: boolean): void {
        this.cellData.value = [];
        if (checked) {
            this.cellData.value = [value];
        }
    }
}
