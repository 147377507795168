export enum RelationshipType {
    MatterPartner = "Matter Partner",
    MatterManager = "Matter Manager",
}
export interface IRelationship {
    type: RelationshipType;
    givenName: string;
    familyName: string;
    email: string;
    title?: string;
    departments?: string[];
    displayName?: string;
    office?: string;
}

export function isRelationship(relationship: IRelationship) {
    return (
        typeof relationship === "object" &&
        typeof relationship["givenName"] === "string" &&
        typeof relationship["familyName"] === "string" &&
        typeof relationship["email"] === "string" &&
        Object.values(RelationshipType).includes(relationship["type"])
    );
}
