import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonJSTModule } from "../../../modules/common.module";
import { ClientRoutingModule } from "./client-routing.module";
import { MaterialModule } from "src/app/modules/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClientComponent } from "./client.component";
import { ClientDisplayComponent } from "./client.display.component/client.display.component";
import { RecipientDisplayComponent } from "./client-recipient.display.component/client-recipient.display.component";
import { ClientWorkItemWarningComponent } from "./client-workitem-warning/client-workitem-warning.component";
import { PrefillDialogComponent } from "./prefill.dialog/prefill.dialog.component";
import { RolloverWarningDialogComponent } from "./rollover-warning-dialog/rollover-warning-dialog.component";

@NgModule({
    imports: [CommonModule, ClientRoutingModule, MaterialModule, FormsModule, ReactiveFormsModule, CommonJSTModule],
    declarations: [
        ClientComponent,
        ClientDisplayComponent,
        RecipientDisplayComponent,
        ClientWorkItemWarningComponent,
        PrefillDialogComponent,
        RolloverWarningDialogComponent,
    ],
    exports: [RecipientDisplayComponent],
})
export class ClientModule {}
