import { Injectable } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { BackendService } from "src/app/services/backend.service";

const PAYLOAD_NZ = {
    serviceLines: ["BAS", "SMSF", "PC", "CA", "TA"],
    writeToQueue: true,
    countries: ["nz"],
};

const PAYLOAD_AU = {
    serviceLines: ["SMSF", "PC", "CA", "TA"],
    writeToQueue: true,
    countries: ["au"],
};

@Injectable({
    providedIn: "root",
})
export class EmailSendingService {
    constructor(private backendService: BackendService) {}

    getEngagementToSend(month: string, reason: "manual" | "auto"): Observable<string[]> {
        const url = `/emails/query`;
        const payloadNZ = {
            ...PAYLOAD_NZ,
            scheduledDate: month,
            manual: reason === "manual",
        };
        const payloadAU = {
            ...PAYLOAD_AU,
            scheduledDate: month,
            manual: reason === "manual",
        };
        return combineLatest([
            this.backendService.post<string[]>(url, payloadNZ),
            this.backendService.post<string[]>(url, payloadAU),
        ]).pipe(map(([resultNZ, resultAU]) => [...resultNZ, ...resultAU]));
    }

    sendReminderEmail(month: string, reason: "manual" | "auto"): Observable<string[]> {
        const url = `/emails/signer`;
        const payloadNZ = {
            ...PAYLOAD_NZ,
            scheduledDate: month,
            manual: reason === "manual",
        };
        const payloadAU = {
            ...PAYLOAD_AU,
            scheduledDate: month,
            manual: reason === "manual",
        };
        return combineLatest([
            this.backendService.post<string[]>(url, payloadNZ),
            this.backendService.post<string[]>(url, payloadAU),
        ]).pipe(map(([resultNZ, resultAU]) => [...resultNZ, ...resultAU]));
    }

    sendClientEmail(month: string): Observable<string[]> {
        const url = `/emails/clients`;
        const payloadNZ = {
            ...PAYLOAD_NZ,
            scheduledDate: month,
            manual: false,
        };
        const payloadAU = {
            ...PAYLOAD_AU,
            scheduledDate: month,
            manual: false,
        };
        return combineLatest([
            this.backendService.post<string[]>(url, payloadNZ),
            this.backendService.post<string[]>(url, payloadAU),
        ]).pipe(map(([resultNZ, resultAU]) => [...resultNZ, ...resultAU]));
    }
}
