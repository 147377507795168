import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { LoggingService } from "./logging.service";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IFindexLocationService, IFindexLocationDetails } from "../../../../common/src/services/IFindexLocationService";
import lodash from "lodash";

@Injectable({
    providedIn: "root",
})
export class FindexLocationsService implements IFindexLocationService {
    private requestedLocations: { [key: string]: Promise<IFindexLocationDetails> } = {};
    endpoint: string;
    constructor(protected loggingService: LoggingService, protected httpClient: HttpClient) {
        this.endpoint = environment.locationsUrl;
    }
    async getInfo(countryName: string, locationAlias: string, segment = "base") {
        const aliasUri = `${this.endpoint}/Locations/${countryName}/${locationAlias}`;
        if (!this.requestedLocations[aliasUri]) {
            const aliasResponse = await this.requestAlias(countryName, locationAlias);
            if (typeof aliasResponse.url !== "string") {
                throw new Error("Could not understand alias response");
            }
            const uri = this.canonicalUrlToSegmentUrl(aliasResponse.url, segment);
            this.requestedLocations[aliasUri] = this.makeRequest(uri);
        }
        return await this.requestedLocations[aliasUri];
    }

    async getAdminEmailsByServiceLine(params: {
        country: string;
        office: string;
        serviceLine: string;
        type: string;
    }): Promise<string> {
        const locationDetails: any = await this.getInfo(params.country, params.office, params.type);

        const adminEmail = locationDetails["admin_email"];
        const adminEmailByServiceLine = locationDetails["admin_email_by_serviceline"];

        const adminEmailForServiceline = lodash.get(adminEmailByServiceLine, [params.serviceLine?.toLowerCase()]);
        const adminEmailToUse = adminEmailForServiceline ? adminEmailForServiceline : adminEmail;

        return adminEmailToUse;
    }

    private requestAlias(countryName: string, locationAlias: string) {
        const uri = `${this.endpoint}/Locations/${countryName}/${locationAlias}`;
        return this.getResponse(uri);
    }
    private canonicalUrlToSegmentUrl(canonicalUrl: string, segment: string) {
        const canonicalUrlObject = new URL(canonicalUrl);
        const endpointUrl = new URL(this.endpoint);
        endpointUrl.pathname = `${canonicalUrlObject.pathname}/${segment}`;
        return endpointUrl.href;
    }

    private getResponse(uri: string): Promise<HttpResponse<any>> {
        return this.httpClient.get(uri, { observe: "response" }).pipe(first()).toPromise();
    }
    private makeRequest(uri: string): Promise<IFindexLocationDetails> {
        return this.httpClient.get<IFindexLocationDetails>(uri).pipe(first()).toPromise();
    }
}
