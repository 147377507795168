import { IClientRow } from "./../../../../../common/models/IClientRow";
import { IPaginatedResult } from "./../../../../../common/models/IPaginatedResult";
import { ClientSearchFilter } from "./../../../../../common/enums/ClientSearchFilter";
import { BackendService } from "../backend.service";
import { LoggingService } from "src/app/services/logging.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RelationshipType } from "../../../../../common/models/IRelationship";
import { ISourceSystemReference } from "../../../../../common/models/ISourceSystemReference";

@Injectable({
    providedIn: "root",
})
export class ClientRepository {
    private endpoint = "clients";
    constructor(private loggingService: LoggingService, private backendService: BackendService) {}

    getClients(
        relationship: RelationshipType,
        value: string,
        year: number,
        offset?: number,
        count?: number
    ): Observable<IPaginatedResult<IClientRow[]>> {
        const params = [
            { key: "field", value: relationship },
            { key: "value", value },
            { key: "year", value: year.toString() },
        ];

        if (count) params.push({ key: "count", value: count.toString() });
        if (offset) params.push({ key: "offset", value: offset.toString() });

        return this.backendService.get<IPaginatedResult<IClientRow[]>>(this.endpoint, params);
    }

    searchClients(
        keyword: string,
        year: number,
        filters: string[],
        offset?: number,
        count?: number
    ): Observable<IPaginatedResult<IClientRow[]>> {
        const params = [
            { key: "keyword", value: keyword },
            { key: "year", value: year.toString() },
        ];

        if (filters) {
            filters.forEach((filter) => {
                params.push({ key: "filters", value: filter });
            });
        }

        if (count) params.push({ key: "count", value: count.toString() });
        if (offset) params.push({ key: "offset", value: offset.toString() });

        return this.backendService.get<IPaginatedResult<IClientRow[]>>("clients/search", params);
    }

    getAllMatterManagers(): Observable<Array<any>> {
        return this.backendService.get<any[]>("relationships", [
            { key: "relationshipType", value: RelationshipType.MatterManager },
        ]);
    }

    getAllMatterPartners(): Observable<Array<any>> {
        return this.backendService.get<any[]>("relationships", [
            { key: "relationshipType", value: RelationshipType.MatterPartner },
        ]);
    }

    // FIXME: define the type returned by this function
    getWorkItemsForEntities(clientIds: ISourceSystemReference[], year: number): Observable<any[]> {
        return this.backendService.post<any[]>("workItems/byEntities", clientIds, [
            { key: "year", value: year.toString() },
        ]);
    }
}
