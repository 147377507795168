import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({ name: "customCurrency" })
export class CustomCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: any, showDollarSign: boolean, showDecimalPlaces: boolean = false): string {
        if (value != null) {
            const currencyString = this.currencyPipe
                .transform(value, "", showDollarSign ? "$" : "", showDecimalPlaces ? "1.2-2" : "1.0-0")
                .replace("-", "");

            if (value < 0) {
                return `(${currencyString})`;
            }
            return currencyString;
        }
        return "";
    }
}
