export enum EngagementState {
    approved = "approved",
    open = "open", // deprecated
    draft = "draft",
    sent = "sent",
    scheduled = "scheduled",
    scheduledManual = "scheduled manual",
    sending = "sending",
    sendFailed = "send failed",
    signed = "signed",
    notRequired = "not required",
    notCreated = "not created",
    manual = "manual",
    tasked = "tasked", //for batch issues in TaskX without an engagement
}
export enum EngagementAction {
    approve = "approve",
    unapprove = "unapprove",
    sendToTaskX = "sendToTaskX",
    unsendToTaskX = "unsendToTaskX",
    schedule = "schedule",
    scheduleForManual = "scheduleForManual",
    manual = "manual",
    send = "send",
    withdrawCsa = "withdrawCsa",
    sign = "sign",
    update = "update",
    regenerateCsa = "regenerateCsa",
    createTask = "createTask",
}

// allowed transitions states
export const engagementCanTransitionTo = {
    [EngagementState.open]: [EngagementState.approved],
    [EngagementState.draft]: [EngagementState.approved],
    [EngagementState.tasked]: "",
    [EngagementState.approved]: [
        EngagementState.sending,
        EngagementState.sent,
        EngagementState.manual,
        EngagementState.scheduled,
        EngagementState.scheduledManual,
        EngagementState.tasked,
    ],
    [EngagementState.sent]: [EngagementState.signed],
    [EngagementState.scheduled]: [
        EngagementState.draft,
        EngagementState.sending,
        EngagementState.sent,
        EngagementState.manual,
    ],
    [EngagementState.scheduledManual]: [
        EngagementState.draft,
        EngagementState.sending,
        EngagementState.sent,
        EngagementState.manual,
    ],
    [EngagementState.sending]: [EngagementState.sent, EngagementState.sendFailed],
    [EngagementState.sendFailed]: [EngagementState.sending, EngagementState.sent, EngagementState.manual],
    [EngagementState.signed]: "",
    [EngagementState.notRequired]: [EngagementState.draft],
    [EngagementState.notCreated]: [EngagementState.draft],
    [EngagementState.manual]: [EngagementState.signed],
};

export const engagementCanTransitionFrom = {
    [EngagementState.open]: [EngagementState.approved, EngagementState.scheduled, EngagementState.scheduledManual],
    [EngagementState.draft]: [EngagementState.approved, EngagementState.scheduled, EngagementState.scheduledManual],
    [EngagementState.tasked]: [EngagementState.approved],
    [EngagementState.approved]: [EngagementState.draft],
    [EngagementState.sent]: [
        EngagementState.sending,
        EngagementState.approved,
        EngagementState.scheduled,
        EngagementState.scheduledManual,
    ],
    [EngagementState.scheduled]: [EngagementState.approved],
    [EngagementState.scheduledManual]: [EngagementState.approved],
    [EngagementState.sending]: [EngagementState.approved, EngagementState.scheduled, EngagementState.scheduledManual],
    [EngagementState.sendFailed]: [EngagementState.sending],
    [EngagementState.signed]: [EngagementState.sent],
    [EngagementState.notRequired]: "",
    [EngagementState.notCreated]: "",
    [EngagementState.manual]: [
        EngagementState.approved,
        EngagementState.scheduled,
        EngagementState.scheduledManual,
        EngagementState.sendFailed,
    ],
};

export const engagementCanPerformActionsFrom = {
    sendToTaskX: [
        EngagementState.approved,
        EngagementState.sent,
        EngagementState.scheduled,
        EngagementState.scheduledManual,
        EngagementState.sending,
        EngagementState.sendFailed,
        EngagementState.signed,
        EngagementState.manual,
        EngagementState.tasked,
    ],
    edit: [EngagementState.open, EngagementState.draft],
    downloadPdf: [EngagementState.sent, EngagementState.signed, EngagementState.manual],
    regenerateCsa: [
        EngagementState.approved,
        EngagementState.sent,
        EngagementState.scheduled,
        EngagementState.scheduledManual,
        EngagementState.sending,
        EngagementState.sendFailed,
        EngagementState.signed,
        EngagementState.manual,
    ],
};

export const serviceDeskEngagementCanPerformActionsFrom = {
    ...engagementCanPerformActionsFrom,
    edit: [
        EngagementState.open,
        EngagementState.draft,
        EngagementState.approved,
        EngagementState.sent,
        EngagementState.scheduled,
        EngagementState.scheduledManual,
        EngagementState.sending,
        EngagementState.sendFailed,
        EngagementState.signed,
        EngagementState.manual,
    ],
};
