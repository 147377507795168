import { Injectable } from "@angular/core";
import { IScopeSaveRequest, IScope } from "../../../../../common/models/IScope";
import { Observable } from "rxjs";
import { LoggingService } from "../logging.service";
import { BackendService } from "../backend.service";

@Injectable({
    providedIn: "root",
})
export class ScopeRepository {
    constructor(protected loggingService: LoggingService, protected backendService: BackendService) {}
    private endpoint = "scope";

    getScope(sourceSystemName: string, referenceId: string, startDate: string, endDate: string): Observable<IScope> {
        return this.backendService.get<IScope>(this.endpoint, [
            { key: "sourceSystemName", value: sourceSystemName },
            { key: "referenceId", value: referenceId },
            { key: "startDate", value: startDate },
            { key: "endDate", value: endDate },
        ]);
    }

    updateScope(scope: IScopeSaveRequest): Observable<IScope> {
        return this.backendService.update<IScopeSaveRequest, IScope>(this.endpoint, scope);
    }

    // TODO implement when we can support this
    // saveEngagement(scope: IScope, engagementId: string): Observable<IScope> {
    //     const endpoint = `engagement/${engagementId}`;
    //     return this.backendService.update<IScope, IScope>(endpoint, scope);
    // }

    transitionEngagement(scope: IScope, engagementId: string, action: string): Observable<IScope> {
        const endpoint = `engagement/${engagementId}/action/${action}`;
        return this.backendService.update<IScope, IScope>(endpoint, scope);
    }
}
