import { JsonValidator } from "./JsonValidator";
import { IScope } from "../../models/IScope";
import { ILoggingService } from "../services/ILoggingService";
import { IEntity } from "../../models/IEntity";
import { IEngagement } from "../../models/IEngagement";
import { IWorkItem } from "../../models/IWorkItem";
import some from "lodash/some";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import { WorkItemFilterFactory } from "../factories/WorkItemFilterFactory";
import { ISourceSystemReference } from "../../models/ISourceSystemReference";
import { EngagementType } from "../../enums/EngagementType";
import "reflect-metadata";
import { injectable } from "tsyringe";

@injectable()
export class ScopeValidator {
    workItemFilter: WorkItemFilterFactory;

    constructor(private loggingService: ILoggingService, private FUTURE_YEARS_TO_ADD: number = 3) {
        // TODO remove these
        this.workItemFilter = new WorkItemFilterFactory();
    }

    entityCanBeModified(entity: IEntity, workItems: IWorkItem[]): boolean {
        // check that the entity has no active workitems
        if (!workItems || workItems.length == 0) {
            return true;
        }
        const hasWorkItems = some(
            workItems,
            (workItem) => workItem.active === true && isEqual(workItem.entityId, entity.entityId)
        );
        return !hasWorkItems;
    }

    workItemCanBeModified(workItem: IWorkItem, engagements: Array<IEngagement>): boolean {
        // check that the workitem has not been allocated to any engagements
        let workItems = [{} as IWorkItem];
        engagements.forEach((engagement) => {
            workItems = workItems.concat(engagement.workItems);
        });
        const hasBeenAllocatedToAWorkItem = some(
            workItems,
            (prevWorkItem) => prevWorkItem.workItemId === workItem.workItemId
        );
        return !hasBeenAllocatedToAWorkItem;
    }

    /** TODO: move this to EngagementValidator */
    allEngagementsValid(engagements: Array<IEngagement>, workItems: IWorkItem[]): boolean {
        const validators = [
            this.mustHaveWorkItemsAndEngagements(),
            this.allWorkItemsAssigned(),
            this.allEngagementsHaveWorkItems(),
            this.allWorkItemsAssignedToOnlyOneEngagement(),
        ];
        return validators.every((validator) => {
            const result = validator(engagements, workItems);
            return result;
        });
    }

    allEntitiesValid(): boolean {
        return true;
    }

    allProductsValid(): boolean {
        return true;
    }

    scopeHasWorkItems(scope: IScope): boolean {
        return scope.workItems && scope.workItems.length > 0;
    }

    scopeHasEntities(scope: IScope): boolean {
        return scope.entities && scope.entities.length > 0;
    }

    debtorsAreInEntities(entities: IEntity[]): boolean {
        const entityIds = entities.map((entity) => entity.entityId);
        const debtorIds = entities.map((entity) => entity.debtorId);

        const debtorIdIsInEntityIds = (debtorId: ISourceSystemReference) =>
            entityIds.some(
                (entityId) =>
                    entityId.referenceId === debtorId.referenceId &&
                    entityId.sourceSystemName === debtorId.sourceSystemName
            );

        const someDebtorIdNotFound = debtorIds.some((debtorId) => !debtorIdIsInEntityIds(debtorId));
        return !someDebtorIdNotFound;
    }

    allNotRequiredServiceLinesHaveReasons(scope: IScope): boolean {
        if (scope.engagements.every((engagement) => engagement.type !== EngagementType.NotRequired)) {
            return true;
        }
        const notRequiredEngagements = scope.engagements.filter(
            (engagement) => engagement.type === EngagementType.NotRequired
        );
        return notRequiredEngagements.every(
            (engagement) => engagement.reason != null && engagement.reason.trim() !== ""
        );
    }

    //#region predicates
    private mustHaveWorkItemsAndEngagements(): (engagements: Array<IEngagement>, workItems: IWorkItem[]) => boolean {
        return (engagements, workItems) => {
            const valid = engagements.length > 0 && workItems.length > 0;
            return valid;
        };
    }

    private allWorkItemsAssigned(): (engagements: Array<IEngagement>, workItems: IWorkItem[]) => boolean {
        return (engagements, workItems) => {
            const unassignedWorkItems = this.filterUnassignedWorkItems(engagements, workItems);
            return unassignedWorkItems.length === 0;
        };
    }

    private allEngagementsHaveWorkItems(): (engagements: Array<IEngagement>, workItems: IWorkItem[]) => boolean {
        return (engagements, workItems) => {
            const valid = engagements.every((engagement) => engagement.workItems.length > 0);
            return valid;
        };
    }

    private allWorkItemsAssignedToOnlyOneEngagement(): (
        engagements: Array<IEngagement>,
        workItems: IWorkItem[]
    ) => boolean {
        return (engagements, workItems) => {
            const assignedWorkItems = engagements
                .map((engagement) => engagement.workItems)
                .reduce((acc, workItems) => [...acc, ...workItems]);
            const assignedWorkItemIds = assignedWorkItems.map((workItem) => workItem.workItemId);
            const uniqueWorkItemIds = uniqBy(assignedWorkItems, (assignedWorkItem) => assignedWorkItem.workItemId);
            const valid = assignedWorkItemIds.length === uniqueWorkItemIds.length;
            return valid;
        };
    }

    private filterUnassignedWorkItems(engagements: Array<IEngagement>, workItems: IWorkItem[]): IWorkItem[] {
        const assignedWorkItemFilter = this.workItemFilter.filterAssignedWorkItems(engagements);
        const unassignedWorkItems = workItems
            .filter((workItem) => workItem.active === true)
            .filter((workItem) => !assignedWorkItemFilter(workItem));
        return unassignedWorkItems;
    }
    //#endregion predicates
}
