import { AnalyticsService } from "../../../services/analytics.service";
import { ProgressService } from "../../../services/progress.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { combineLatest } from "rxjs";
import { isEqual } from "lodash";

export interface IProgressStage {
    title: string;
    active: boolean;
    valid: boolean;
}

@Component({
    selector: "app-progress",
    templateUrl: "./progress.component.html",
    styleUrls: ["./progress.component.scss"],
})
export class ProgressComponent implements OnInit {
    stages: Array<IProgressStage>;
    currentStage: IProgressStage;
    numberOfStages: number;
    currentIndex: number;

    @Output() navigateEvent = new EventEmitter<number>();

    constructor(private progressService: ProgressService, private analyticsService: AnalyticsService) {}

    navigateStage(newStage: IProgressStage) {
        if (newStage.title === this.currentStage.title) {
            return;
        }
        if (!newStage.valid) {
            return;
        }
        const isDirty = this.progressService.isDirty();

        if (isDirty && !confirm("You have unsaved changes, do you wish to proceed?")) {
            return;
        }

        this.progressService.setStage(newStage);
        this.progressService.clearDirty();

        this.analyticsService.navigationOccurred(this.currentStage.title, newStage.title);
        const index = this.stages.findIndex((stage) => isEqual(stage.title, newStage.title));
        this.navigateEvent.emit(index);
    }

    ngOnInit() {
        combineLatest(this.progressService.getStage(), this.progressService.getStages()).subscribe(
            ([activeStage, stages]) => {
                this.currentStage = activeStage;
                this.stages = stages.map((stage, index) => {
                    if (stage.title === this.currentStage.title) {
                        this.currentIndex = index + 1;
                        this.navigateEvent.emit(index);
                        return {
                            ...stage,
                            active: true,
                        };
                    }
                    return {
                        ...stage,
                        active: false,
                    };
                });
                this.numberOfStages = this.stages.length;
            }
        );
    }
}
