export enum EntityType {
    company = "company",
    maoriAuthority = "maori authority",
    governmentStatutoryBody = "government statutory body",
    interCompany = "inter-company",
    sundryServices = "sundry services",

    partnership = "partnership",
    limitedPartnership = "limited partnership",
    jointOwnership = "joint ownership",

    trust = "trust",
    estate = "estate",

    nonProfit = "non-profit",
    registeredCharitableTrust = "registered charitable trust",
    school = "school",

    soleTrader = "sole trader",
    individual = "individual",

    superfund = "superfund",

    // placeholder for groups
    entityGroup = "EntityGroup",
}

export const EntityBehaviourGroups = {
    company: [
        EntityType.company,
        EntityType.governmentStatutoryBody,
        EntityType.maoriAuthority,
        EntityType.interCompany,
        EntityType.sundryServices,
    ],
    companyUncategorized: [EntityType.nonProfit, EntityType.registeredCharitableTrust, EntityType.school],
    trust: [EntityType.trust, EntityType.estate],
    individual: [EntityType.soleTrader, EntityType.individual],
    partnership: [EntityType.partnership, EntityType.limitedPartnership, EntityType.jointOwnership],
};

export enum EntityRole {
    DEBTOR = "debtor",
    FAMILY_HEAD = "familyHead",
    ENTITY = "entity",
}
