// Adding new regions

export const SubRegions = [
    "ALB",
    "CBA",
    "CTN",
    "CWE",
    "EVI",
    "MDA",
    "MEL",
    "NQL",
    "NZC",
    "NZN",
    "NZS",
    "PTH",
    "SQL",
    "SYD",
    "TAS",
    "WVI",
];
