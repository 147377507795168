import { Component, ViewChild, ElementRef, HostListener, Output, EventEmitter, Input } from "@angular/core";

type SearchType = "matterManager" | "matterPartner" | "all";

interface ClientSearch {
    searchKeyword: string;
    filters: string[];
}

@Component({
    selector: "app-search-bar",
    templateUrl: "./search-bar.component.html",
    styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent {
    firstLoad = true;
    showFilter: boolean = false;
    searchKeyword: string = "";
    @Input() selectedFilters: string[];
    @Input() filterOptions: any[];
    @Input() filterResults: any[];
    @Output() onSearch = new EventEmitter<ClientSearch>();
    @Output() onSelectFilter = new EventEmitter<{ value: string; checked: boolean }>();

    constructor(private eref: ElementRef) {}

    get placeholder(): string {
        return this.selectedFilters.length === 0
            ? "Search for client"
            : `Search for ${this.selectedFilters.join(", ").toLowerCase()}`;
    }

    @HostListener("document:click")
    onOutsideClick(): void {
        if (!this.eref.nativeElement.contains(event.target)) {
            this.showFilter = false;
        }
    }

    toggleFilter(): void {
        this.showFilter = !this.showFilter;
    }

    search() {
        this.showFilter = false;
        const value = this.searchKeyword;
        let searchFilters = this.selectedFilters;

        const searchObject: ClientSearch = {
            searchKeyword: value,
            filters: searchFilters,
        };

        this.onSearch.emit(searchObject);
    }

    updateFilterOptions(value: string, checked: boolean) {
        this.onSelectFilter.emit({ value, checked });
    }
}
