import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { v4 as uuidV4 } from "uuid";
import { ICellData } from "../../../models/ICellData";

export interface IMultiMonthSelectOption {
    type: string;
    value: string;
    name: string;
    id: string;
    label: string;
    secondaryLabel?: string;
    tooltip: string;
    selected?: boolean;
    disabled?: boolean;
}

@Component({
    selector: "app-schedule-selector",
    templateUrl: "./schedule-selector.component.html",
    styleUrls: ["./schedule-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSelectorComponent implements OnInit, OnChanges {
    @Input() cellData: ICellData;
    @Output() valueSelected = new EventEmitter();
    showMonths = true;
    mappedOptions: Array<IMultiMonthSelectOption> = [];
    isDisabled = false;
    selectedString = "";
    tooltipShowDelay = new FormControl(1000);
    tooltipHideDelay = new FormControl(0);
    engaged = false;
    mandatory = false;
    expanded = false;
    extendOffset = 12;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.initialiseOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initialiseOptions();
    }

    initialiseOptions(): void {
        const val = this.cellData.value ? this.cellData.value : [];
        this.engaged = this.cellData.metadata.engaged;
        this.mandatory = this.cellData.metadata.mandatory;

        this.mappedOptions = [];

        if (this.cellData && this.cellData.options && this.cellData.options.length) {
            this.isDisabled = !(this.cellData.metadata && this.cellData.metadata.editable) || false;
            this.cellData.options.forEach((option: any) => {
                const selected = (val && val.indexOf(option.value) !== -1) || false;
                this.mappedOptions.push({
                    type: "checkbox",
                    value: option.value,
                    name: `name${option.name}`,
                    id: `id${uuidV4()}${option.value}`,
                    label: option.name,
                    secondaryLabel: option.label,
                    tooltip: option.name,
                    selected,
                    disabled: this.isDisabled,
                });
            });
        }
        this.showMonths = this.mappedOptions.length > 0;
    }

    updateSelectedOptions(option: IMultiMonthSelectOption, event: Event): void {
        if (!Array.isArray(this.cellData.value)) {
            this.cellData.value = [];
        }
        const target = event.target as HTMLInputElement;
        const value = option.value;
        this.cellData.value = this.cellData.value.filter((cellDataValue: any) => cellDataValue !== value);
        if (target.checked) {
            this.cellData.value = [...this.cellData.value, value];
        }
        this.valueSelected.next(this.cellData);
    }

    hasValueSelected(): IMultiMonthSelectOption {
        return this.mappedOptions.find((option: IMultiMonthSelectOption) => option.selected);
    }
}
