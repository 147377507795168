import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";

@Pipe({
    name: "textBold",
})
export class TextBoldPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, regex: string): string {
        if (regex === "") {
            return value;
        }
        return this.sanitize(this.replace(value, regex));
    }

    replace(str: string, regex: string): string {
        return str.replace(new RegExp(`(${regex})`, "gi"), "<b>$1</b>");
    }

    sanitize(str: string | SafeValue) {
        return this.sanitizer.sanitize(SecurityContext.HTML, str);
    }
}
