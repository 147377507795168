import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from "@angular/core";
import { AnalyticsService } from "src/app/services/analytics.service";
import { AnalyticsCategory } from "../../../../../common/constants/AnalyticsCategory";

interface IColumnSettingsOption {
    name: string;
    value: string;
    visible: boolean;
}
@Component({
    selector: "app-column-settings-dialog",
    templateUrl: "./column-settings-dialog.component.html",
    styleUrls: ["./column-settings-dialog.component.scss"],
})
export class ColumnSettingsDialogComponent implements OnInit {
    @Input() options: IColumnSettingsOption[];
    @Output() setVibility = new EventEmitter();
    showSettingsPanel = false;
    readonly analyticsCategories = AnalyticsCategory;

    constructor(private el: ElementRef, protected analyticsService: AnalyticsService) {}

    ngOnInit() {}

    // no need to log this, logged in parent
    setColumnVisibility(event: any) {
        const option = this.options.find((o) => o.value === event.target.value);
        if (option) {
            option.visible = event.target.checked;
            this.setVibility.next(option);
        }
    }

    getColumnVisibility(name: string) {
        const column = this.options.find((c: any) => c.name === name);

        if (!column || column.visible) {
            return true;
        }
        return false;
    }

    toggleColumnSettings() {
        this.logAnalyticsEvent(this.analyticsCategories.buttonClick, "toggleColumnSettings");
        this.showSettingsPanel = !this.showSettingsPanel;
    }

    logAnalyticsEvent(category: string, event: string, value?: number): void {
        this.analyticsService.eventOccurred(category, event, value);
    }

    @HostListener("document:click", ["$event"])
    clickOutside(event: any) {
        if (!this.el.nativeElement.contains(event.target)) {
            this.showSettingsPanel = false;
        }
    }
}
