import { arrayIsNullOrEmpty } from "./ArrayHelper";
import { EngagementStateOrder } from "../../constants/EngagementStates";
import sortBy from "lodash/sortBy";
import { EngagementState } from "../../enums/EngagementState";

export const getLowestEngagementStatus = (
    states: Array<EngagementState>,
    engagementOrder?: Array<string>
): EngagementState => {
    if (arrayIsNullOrEmpty(states)) {
        return EngagementState.notCreated;
    }

    const order = arrayIsNullOrEmpty(engagementOrder) ? EngagementStateOrder : engagementOrder;
    const sorted = sortBy(states, (state) => {
        return order.indexOf(state);
    });
    return sorted[0];
};
