import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsService } from "../../../services/analytics.service";
import { AnalyticsCategory } from "../../../../../../common/constants/AnalyticsCategory";

@Component({
    selector: "app-scope",
    templateUrl: "./client-redirect.component.html",
    styleUrls: ["./client-redirect.component.scss"],
})
export class ClientRedirectComponent implements OnInit, OnDestroy {
    ngUnsubscribe = new Subject();

    constructor(private router: Router, private route: ActivatedRoute, private analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.analyticsService.eventOccurred(AnalyticsCategory.pageLoad, "clients");

        document.title = "Job Scoping Tool";
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    navigate(to: string) {
        this.router.navigate([`../${to}`], { relativeTo: this.route });
    }
}
