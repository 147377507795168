import { appVersion } from "../../../common/constants/Release";

export const commonEnvironment = {
    loggingLevels: {
        info: "info",
        debug: "debug",
    },
    currentStatusUrl: "https://spaces.findex.com.au/display/DIG/TaskX+and+JST+Current+Status",
    feedbackUrl: "https://jira.findex.com.au/servicedesk/customer/portal/68",
    sentry: {
        dsn: "https://cdd727355eb34c9b93ac3b62e1ddb560@o516902.ingest.sentry.io/6742586",
    },
    notificationTimeout: 3000000,
    azureAD: {
        authority: "https://login.microsoftonline.com/b48a9eab-75aa-4a5b-9fd7-f39223245c1a",
    },
    release: appVersion,
    documents: "https://findex-web.imanagework-au.com/work/web/",
    reporting: "https://app.powerbi.com/groups/me/apps/c5d87e0c-3890-4c8b-a081-83102dda7d7f/",
    requestManualCSA: "https://jira.findex.com.au/servicedesk/customer/portal/68/create/1439",
};
