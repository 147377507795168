type ValidCurrencies = "AUD" | "NZD"; // Needs to line up with Client Portal's valid currencies
export enum Country {
    AU = "AU",
    NZ = "NZ",
}

export const countryDetails: { [country: string]: { currency: ValidCurrencies; gstRate: number } } = {
    [Country.AU]: {
        currency: "AUD",
        gstRate: 10,
    },
    [Country.NZ]: {
        currency: "NZD",
        gstRate: 15,
    },
};
