import { NotificationService } from "src/app/services/notification.service";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "src/app/modules/material.module";
import { NgModule } from "@angular/core";
import { NotificationComponent } from "./notification.component";
import { NotificationMenuComponent } from "./notification-menu/notification-menu.component";
import { NotificationDialogComponent } from "./notification.dialog/notification.dialog.component";
import { ClientsListingModule } from "../../clients-listing/clients-listing.module";

@NgModule({
    imports: [BrowserModule, HttpClientModule, BrowserAnimationsModule, MaterialModule, ClientsListingModule],
    exports: [NotificationComponent, NotificationDialogComponent, NotificationMenuComponent],
    declarations: [NotificationComponent, NotificationDialogComponent, NotificationMenuComponent],
    providers: [NotificationService],
})
export class NotificationModule {}
