import { JstError } from "./../../../../common/models/JstError";
import { SentryService } from "./sentry.service";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { ILoggingService } from "../../../../common/src/services/ILoggingService";
import { LoggingLevel } from "../../../../common/enums/LoggingLevel";

@Injectable({
    providedIn: "root",
})
export class LoggingService implements ILoggingService {
    //#region ctor
    constructor(private sentryService: SentryService) {}
    //#endregion ctor

    debug(error: Error | any, ...messages: Array<any>): void {
        if (!environment.production) {
            this.log("debug", error, ...messages);
        }
    }

    info(error: Error | any, ...messages: Array<any>): void {
        this.log("info", error, ...messages);
    }

    warn(error: Error | any, ...messages: Array<any>): void {
        this.log("warning", error, ...messages);
    }

    error(error: Error | any, ...messages: Array<any>): void {
        this.log("error", error, ...messages);
    }

    prettyPrint(error: Error | any): void {
        this.log("error", error);
    }

    private log(severity: string, error: Error | any, ...messages: Array<any>) {
        if (environment.sentry.enabled && severity !== "debug" && error.suppressLogging !== true) {
            const toLog = this.formatToValidSentry(error);
            this.sentryService.logToSentry(toLog, severity);
        } else {
            if (!this.meetsMinimumLoggingLevel(severity)) {
                return;
            }
            if (error) {
                console.log(error, ...messages);
            } else {
                console.log(...messages);
            }
        }
    }

    private formatToValidSentry(objectLogged: any): Error | string {
        if (objectLogged instanceof Error || objectLogged instanceof JstError) {
            return objectLogged;
        }
        if (typeof objectLogged === "string") {
            return objectLogged;
        }
        try {
            return JSON.stringify(objectLogged);
        } catch (err) {
            // not a json object need to find out why?
            return new Error("Object was in an invalid format");
        }
    }

    private meetsMinimumLoggingLevel(loggingLevel: string) {
        const minimumLoggingLevel = LoggingLevel[environment.loggingLevel as any];
        return LoggingLevel[loggingLevel as any] >= minimumLoggingLevel;
    }
}
