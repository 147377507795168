import { AnalyticsCategory } from "../../../../../../common/constants/AnalyticsCategory";
import { AnalyticsService } from "../../../services/analytics.service";
import { ISortOption } from "../../../models/ISortOption";
import { WindowService } from "../../../services/window.service";
import { Component, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MessageOfTheDayService } from "../../../services/message-of-the-day.service";
import { LoggingService } from "../../../services/logging.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ClientRepository } from "src/app/services/repositories/client.repository";
import { RelationshipType } from "../../../../../../common/models/IRelationship";
import { Subject, Observable } from "rxjs";
import { MatSelectChange } from "@angular/material/select";
import { SubRegions } from "../../../../../../common/constants/Regions";
import { CsvService } from "../../../services/csv.service";
import { get, toPairs, startCase, fromPairs } from "lodash";
import { ErrorHandlerService, IErrorHandler } from "../../../services/error-handler.service";
import { GraphService } from "../../../services/graph.service";
import { LocalStorageService } from "../../../services/local-storage.service";
import { takeUntil } from "rxjs/operators";

type SearchType = "matterManager" | "matterPartner" | "all";

@Component({
    template: "",
})
export abstract class ListingBaseComponent implements OnDestroy, IErrorHandler {
    readonly DEFAULT_PAGE = "listing";
    abstract componentName: string;
    rowSearchTerm: string;
    ngUnsubscribe = new Subject();

    searchTypeControl = new FormControl();
    searchTextControl = new FormControl();
    selectedYear: number;
    priorYear: number;
    currentSearch: string;
    subRegion: string;
    scopeSummaries: any[];
    filteredScopeSummaries: any[];
    makingRequests = false;
    readonly matterManager = RelationshipType.MatterManager;
    readonly matterPartner = RelationshipType.MatterPartner;
    readonly analyticsCategories = AnalyticsCategory;
    years: Array<number>;
    matterManagers: Array<any> = [];
    matterPartners: Array<any> = [];
    filteredOptions: Observable<any>;
    options: Array<any> = [];
    filterByCollection: Array<any> = [];
    sortByModel: ISortOption = {
        key: "revenueYTD",
        sorted: true,
    };
    showDollarSign = true;
    showDecimalPlaces = false;
    currentPage = 1;
    abstract columns: any[];

    readonly subRegionType = "Sub Region";

    readonly subRegions = SubRegions;
    currentUserEmail: string;
    currentUser: {
        name: string;
        email: string;
    };

    constructor(
        protected clientRepository: ClientRepository,
        protected loggingService: LoggingService,
        protected motdService: MessageOfTheDayService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected graphService: GraphService,
        protected windowService: WindowService,
        protected csvService: CsvService,
        protected errorHandlerService: ErrorHandlerService,
        protected analyticsService: AnalyticsService,
        protected localStorageService: LocalStorageService
    ) {}

    protected abstract resetTotals(): void;
    protected abstract setTotals(): void;
    abstract onRowClicked(event: any): Promise<void>;
    abstract onSearch(): void;

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    handleError(error: Error, functionName: string, displayMessage: string) {
        const jstError = this.errorHandlerService.formatError(error, {
            displayMessage,
            functionName,
            className: this.componentName,
        });
        this.loggingService.error(jstError);
        this.motdService.createErrorMessage(jstError, "OK");
    }

    logAnalyticsEvent(category: string, event: string, value?: number): void {
        this.analyticsService.eventOccurred(category, event, value);
    }

    // hook up analytics to all methods in this region
    //#region handlers
    setColumnVisibility(event: any) {
        this.logAnalyticsEvent(AnalyticsCategory.filter, "setColumnVisibility");
        const column = this.columns.find((col: any) => col.name === event.value);
        if (column) {
            column.visible = event.visible;
        } else {
            this.columns.push({ name: event.target.value, visible: event.visible });
        }
    }

    onSearchChange(_event: MatSelectChange) {
        if (this.searchTypeControl.value === RelationshipType.MatterManager) {
            this.options = this.matterManagers;
        }
        if (this.searchTypeControl.value === RelationshipType.MatterPartner) {
            this.options = this.matterPartners;
        }

        this.currentSearch = this.searchTypeControl.value;
    }

    onSubregionChange(): void {
        this.onSearch();
        this.logAnalyticsEvent(this.analyticsCategories.search, "subRegion");
        this.setStoredCommonValue("subRegion", this.subRegion);
    }

    onYearChange(): void {
        this.onSearch();
        this.logAnalyticsEvent(this.analyticsCategories.search, "year");
        this.setStoredCommonValue("year", this.selectedYear);
    }

    sortRows(key: string) {
        this.logAnalyticsEvent(AnalyticsCategory.rowSort, "sortRows");
        if (this.sortByModel.key === key) {
            this.sortByModel.sorted = !this.sortByModel.sorted;
        } else {
            this.sortByModel.key = key;
            this.sortByModel.sorted = true;
        }
        this.filteredScopeSummaries = this.scopeSummaries.sort((firstSummary, secondSummary) => {
            if (this.sortByModel.sorted) {
                if (firstSummary[this.sortByModel.key] > secondSummary[this.sortByModel.key]) {
                    return -1;
                }
                if (firstSummary[this.sortByModel.key] < secondSummary[this.sortByModel.key]) {
                    return 1;
                }
            } else if (!this.sortByModel.sorted) {
                if (firstSummary[this.sortByModel.key] < secondSummary[this.sortByModel.key]) {
                    return -1;
                }
                if (firstSummary[this.sortByModel.key] > [this.sortByModel.key]) {
                    return 1;
                }
            }

            return 0;
        });
    }

    filterBy(key: string, value: any) {
        this.logAnalyticsEvent(AnalyticsCategory.filter, "filterBy");
        const filterKeyExists = this.filterByCollection.find((filt: any) => filt.key === key);
        let filteredResults = [];

        this.filteredScopeSummaries = this.scopeSummaries;

        if (value.length < 3) {
            // reset filterByCollection key value to ''
            if (filterKeyExists) {
                this.filterByCollection.filter((filt: any) => filt.key === key)[0].value = "";
            }
        } else if (filterKeyExists) {
            // update filterByCollection key value
            this.filterByCollection.filter((filt: any) => filt.key === key)[0].value = value.toLowerCase();
        } else {
            // create filterByCollection key and assign value
            this.filterByCollection.push({ key, value: value.toLowerCase() });
        }

        filteredResults = this.filteredScopeSummaries.filter((summary: any) => {
            return this.filterByCollection.every((element: any, index: any, array: any[]) => {
                const objKey = Object.keys(summary).filter((key: string) => key === element.key);

                return summary[objKey[0]].toLowerCase().includes(element.value.toLowerCase());
            });
        });

        this.filteredScopeSummaries = filteredResults;

        this.resetTotals();
        this.setTotals();

        this.currentPage = 1;
    }

    exportList() {
        this.logAnalyticsEvent(AnalyticsCategory.buttonClick, "exportList");
        const listMapper = (list: object[]) => {
            return list.map((entry) => {
                const mappedPairs = toPairs(entry).map(([key, value]) => [startCase(key), value]);

                return fromPairs(mappedPairs);
            });
        };
        if (this.filteredScopeSummaries.length > 0) {
            const clientList = listMapper(this.filteredScopeSummaries);
            const searchType = get(this.searchTypeControl, ["value"], "");
            const searchText = get(this.searchTextControl, ["value", "name"], "");
            this.csvService.downloadCsv(clientList, `Export ${searchType}-${searchText}.csv`);
        }
    }
    //#endregion handlers

    //#region getters
    getColumnVisibility(name: string) {
        const column = this.columns.find((col: any) => col.name === name);
        if (!column || column.visible) {
            return true;
        }
        return false;
    }

    showSubRegionSearch(): boolean {
        return this.currentSearch === this.subRegionType;
    }

    displayName(val: any) {
        return val ? val.name : val;
    }

    getStoredCommonValue(key: string): any {
        const storedConfig = this.localStorageService.get(this.DEFAULT_PAGE);
        const config = storedConfig ? JSON.parse(storedConfig) : {};
        return get(config, [key]);
    }

    getStoredComponentValue(key: string): any {
        const storedConfig = this.localStorageService.get(this.componentName);
        const config = storedConfig ? JSON.parse(storedConfig) : {};
        return get(config, [key]);
    }

    setStoredCommonValue(key: string, value: any): void {
        const storedConfig = this.localStorageService.get(this.DEFAULT_PAGE);
        const config = storedConfig ? JSON.parse(storedConfig) : {};
        config[key] = value;
        this.localStorageService.save(this.DEFAULT_PAGE, config);
    }

    setStoredComponentValue(key: string, value: any): void {
        const storedConfig = this.localStorageService.get(this.componentName);
        const config = storedConfig ? JSON.parse(storedConfig) : {};
        config[key] = value;
        this.localStorageService.save(this.componentName, config);
    }
    //#endregion getters

    protected getYearOptions(year: number): void {
        this.years = [year - 2, year - 1, year, year + 1, year + 2];
        const savedYear = this.getStoredCommonValue("year");
        const selectedYear = savedYear && this.years.includes(savedYear) ? savedYear : year;
        this.selectedYear = selectedYear;
        this.priorYear = this.selectedYear - 1;
    }

    protected _filter(value: any): string[] {
        const filterValue = value.toLowerCase();
        return this.options.filter((option) => {
            return option.name.toLowerCase().includes(filterValue);
        });
    }

    protected updatePriorYear() {
        this.priorYear = this.selectedYear - 1;
    }

    protected getSearchValue(type: string): string {
        if (type === this.subRegionType) {
            return this.subRegion;
        }
        if (type === RelationshipType.MatterPartner) {
            const selectedMatterPartner = this.matterPartners.find((matterManager) => {
                const email = matterManager.email;
                return (
                    typeof email === "string" &&
                    email.localeCompare(this.searchTextControl.value.email, "en", { sensitivity: "base" }) === 0
                );
            });
            return selectedMatterPartner ? selectedMatterPartner.email : undefined;
        }
        if (type === RelationshipType.MatterManager) {
            const selectedMatterManager = this.matterManagers.find((matterPartner) => {
                const email = matterPartner.email;
                return (
                    typeof email === "string" &&
                    email.localeCompare(this.searchTextControl.value.email, "en", { sensitivity: "base" }) === 0
                );
            });
            return selectedMatterManager ? selectedMatterManager.email : undefined;
        }
        return this.searchTextControl.value;
    }

    protected subscribeToFormControl(): void {
        this.searchTypeControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            this.setStoredCommonValue("searchTypeControl", value);
        });
        this.searchTextControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            this.setStoredCommonValue("searchTextControl", value);
        });
    }
}
