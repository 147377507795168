import { Pipe, PipeTransform } from "@angular/core";
import { SERVICE_LINE_SHORT_NAME_TO_LONG_NAME } from "../../../../common/enums/ServiceLine";

@Pipe({
    name: "serviceLineFullDescription",
})
export class ServiceLineFullDescriptionPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (typeof value !== "string") {
            return value;
        }

        const serviceLineLongName = (SERVICE_LINE_SHORT_NAME_TO_LONG_NAME as any)[value.toUpperCase()];
        return serviceLineLongName ? serviceLineLongName : value;
    }
}
