import { Injectable } from "@angular/core";
import { IStorageService } from "src/models/IStorageService";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService implements IStorageService {
    storage: Storage;
    constructor() {
        this.storage = window.localStorage;
    }

    save(key: string, value: any): void {
        this.storage.setItem(key, this.formatValue(value));
    }

    get(key: string): any {
        return this.storage.getItem(key);
    }

    clearItem(key: string): void {
        this.storage.removeItem(key);
    }

    clearAll(): void {
        this.storage.clear();
    }

    formatValue(value: any) {
        if (typeof value === "object") {
            return JSON.stringify(value);
        }
        return value;
    }
}
