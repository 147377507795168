import { Pipe, PipeTransform } from "@angular/core";
import { ServiceLineAbbreviatedNames } from "../../../../../../common/constants/ServiceLine";
import { ServiceLineService } from "../../../services/serviceline.service";
import { IWorkItem } from "../../../../../../common/models/IWorkItem";

@Pipe({
    name: "workItemAbbreviatedServiceLineName",
    pure: true,
})
export class WorkItemAbbreviatedServiceLineNamePipe implements PipeTransform {
    constructor(private serviceLineService: ServiceLineService) {}
    transform(productServiceLine: string): string {
        const serviceLine = this.serviceLineService.getCanonicalServiceLine(productServiceLine);
        if (serviceLine == null) {
            return "";
        }
        return `(${ServiceLineAbbreviatedNames[serviceLine]})`;
    }
}

@Pipe({
    name: "workItemHasConnector",
    pure: true,
})
export class WorkItemHasConnectorPipe implements PipeTransform {
    transform(workItem: IWorkItem, type: string, subtype: string): boolean {
        if (!Array.isArray(workItem.connectors)) {
            return false;
        }
        return workItem.connectors.some((connector) => connector.type === type && connector.subtype == subtype);
    }
}
