import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { IsAdministratorGuard } from "./administrator.guard";
import { LegacyClientsListingComponent } from "./components/clients-listing/clients-listing/legacy-clients-listing.component";
import { EmailSendingComponent } from "./components/email-sending/email-sending.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { RoadblockComponent } from "./components/roadblock/roadblock.component";
import { SiteMaintenanceComponent } from "./components/site-maintenance/site-maintenance.component";

const routes: Routes = [
    {
        path: "yearless",
        loadChildren: () => import("./components/yearlessScope/scope.module").then((m) => m.YearlessScopeModule),
        canActivate: [MsalGuard],
    },
    {
        path: "engagement",
        loadChildren: () => import("./components/engagement/engagement.module").then((m) => m.EngagementModule),
        canActivate: [MsalGuard],
    },
    {
        path: "clients",
        loadChildren: () =>
            import("./components/clients-listing/clients-listing.module").then((m) => m.ClientsListingModule),
        canActivate: [MsalGuard],
    },
    {
        path: "legacy-client-listing",
        component: LegacyClientsListingComponent,
        canActivate: [MsalGuard],
    },
    {
        path: "email-send",
        component: EmailSendingComponent,
        canActivate: [MsalGuard, IsAdministratorGuard],
    },
    { path: "loading", component: LoadingComponent },
    { path: "roadblock", component: RoadblockComponent },
    { path: "", redirectTo: "clients", pathMatch: "full" },
    { path: "**", redirectTo: "clients" },
    { path: "site-maintenance", component: SiteMaintenanceComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
