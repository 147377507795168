import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IEntity } from "../../../../../../../common/models/IEntity";

@Component({
    selector: "app-client-display",
    templateUrl: "./client.display.component.html",
    styleUrls: ["./client.display.component.scss"],
})
export class ClientDisplayComponent {
    @Input() entity: IEntity;
    @Input() entityDebtorName: string;
    @Input() canBeModified: boolean;
    @Output() delete = new EventEmitter();

    deleteEntity(entityId: string) {
        this.delete.emit(entityId);
    }
    parseData(data: string): string {
        return data ? data : "Not Set";
    }
}
