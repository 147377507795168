import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ICellData } from "./../../../../../models/ICellData";

export interface IDropdownOptions {
    name: string;
    value: unknown;
}
@Component({
    selector: "app-drop-down",
    templateUrl: "drop-down.component.html",
    styleUrls: ["drop-down.component.scss"],
})
export class DropDownComponent implements OnInit, OnChanges {
    @Input() key: unknown; // field unique identifier
    @Input() label: string;
    @Input() disabledTooltip: string;
    @Input() cellData: ICellData;

    @Output() change = new EventEmitter();

    options: Array<IDropdownOptions>;
    value: unknown;
    disabled: boolean;

    ngOnInit(): void {
        this.loadCellData();
    }

    valueOnChange(event: string): void {
        this.change.emit(event);
    }

    // handle the select all change event
    ngOnChanges(): void {
        this.loadCellData();
    }

    private loadCellData(): void {
        this.options = this.cellData.options;
        this.value = this.cellData.value;
        this.disabled = !this.cellData.metadata.editable;
    }
}
