import {
    Component,
    ViewChild,
    TemplateRef,
    Input,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    HostBinding,
} from "@angular/core";
import { Subject } from "rxjs";

@Component({
    selector: "app-option",
    template: "<ng-template #template><ng-content></ng-content></ng-template>",
})
export class OptionComponent implements OnChanges, OnDestroy {
    @ViewChild("template", { static: true }) template: TemplateRef<any>;
    @Input() selected: boolean;
    @Input() value: any;
    @Input("disabled")
    @HostBinding("attr.disabled")
    disabled: boolean;

    selectChange = new Subject();

    ngOnChanges(changes: SimpleChanges) {
        const { selected } = changes;
        if (selected) {
            this.selectChange.next(selected.currentValue);
        }
    }

    ngOnDestroy() {
        this.selectChange.complete();
    }
}
