export enum NotificationAlertLevel {
    "ROADBLOCK" = 0,
    "MODAL_DIALOG" = 1,
    "DIALOG" = 2,
    "TOASTER" = 3,
    "MENU" = 4,
}
export interface INotification {
    alertLevel: NotificationAlertLevel;
    message: string;
}
