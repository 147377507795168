import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NotificationComponent } from "../notification.component";
import { AnalyticsService } from "../../../../services/analytics.service";
import { AnalyticsCategory } from "../../../../../../../common/constants/AnalyticsCategory";

@Component({
    selector: "app-notification-menu",
    templateUrl: "./notification-menu.component.html",
    styleUrls: ["./notification-menu.component.scss"],
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
    @Input() notification: NotificationComponent;
    messages: string[];
    ngUnsubscribe = new Subject();
    constructor(private analyticsService: AnalyticsService) {}

    async ngOnInit() {
        this.messages = ["There are no notifications."];
        this.notification
            .getMenuNotifications()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (notifications) => this.processNotifications(notifications),
                (error) => this.handleError(error)
            );
    }
    handleError(error: any): void {
        throw error;
    }
    processNotifications(notifications: Array<any>) {
        if (Array.isArray(notifications)) {
            this.messages = notifications
                .filter((notification) => notification.alertLevel > 3)
                .map((notification) => notification.message);
        }
    }

    raiseAnalyticsEvent(eventType: string) {
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, eventType);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
