import { Component, Input } from "@angular/core";
import { IEntity } from "../../../../../../../common/models/IEntity";

@Component({
    selector: "app-client-recipient-display",
    templateUrl: "./client-recipient.display.component.html",
    styleUrls: ["./client-recipient.display.component.scss"],
})
export class RecipientDisplayComponent {
    @Input() entity: IEntity;

    parseData(data: string): string {
        return data ? data : "Not Set";
    }
}
