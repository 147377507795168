import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs/Observable";
import { filter } from "rxjs/operators";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ScopeStateService } from "src/app/services/scope-state.service";
import { environment } from "src/environments/environment";
import { IStorageService } from "src/models/IStorageService";
import { CommonFeatureFlags } from "../../../../../common/constants/CommonFeatureFlags";
import { IPeriod } from "../../../../../common/models/IPeriod";
import { DateTimeHelper } from "../../../../../common/src/helpers/DateTimeHelper";

export interface IDateRange {
    startDate: string;
    endDate: string;
}

const SERVICE_FILTER_START_DATE_CONST = "serviceFilterStartDate";
const SERVICE_FILTER_END_DATE_CONST = "serviceFilterEndDate";
@Injectable({
    providedIn: "root",
})
export class ServiceFilterService {
    private currentDateFilter: IPeriod = this.setupDefaults();

    private isVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isVisible$: Observable<boolean> = this.isVisible.asObservable();

    dateFilter$: BehaviorSubject<IDateRange> = new BehaviorSubject(this.currentDateFilter);
    dateFilter: Observable<IDateRange> = this.dateFilter$.asObservable();

    yearlessScopesFeature: boolean = environment.featureFlags[CommonFeatureFlags.YEARLESS_SCOPES];

    PATHS_TO_SHOW_FILTER = {
        SERVICE_SELECTION: "service/selection",
        SERVICE_CONFIGURATION: "service/configuration",
        ENGAGEMENT_ALLOCATION: "engagement/allocation",
    };

    constructor(
        private router: Router,
        private scopeState: ScopeStateService,
        @Inject(LocalStorageService) protected storageService: IStorageService
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            if (event instanceof NavigationEnd && this.yearlessScopesFeature && this.showFilter(event.url)) {
                this.show();
            } else {
                this.hide();
            }
        });
    }

    show() {
        this.isVisible.next(true);
    }

    hide() {
        this.isVisible.next(false);
    }

    private setupDefaults(): IPeriod {
        const startDate = this.storageService.get(SERVICE_FILTER_START_DATE_CONST);
        const endDate = this.storageService.get(SERVICE_FILTER_END_DATE_CONST);
        const defaults = DateTimeHelper.getSurrounding12MonthsDateRange();
        return {
            startDate: startDate ? startDate : defaults.startDate,
            endDate: endDate ? endDate : defaults.endDate,
        };
    }

    updateStartDate(newValue: string, publishEvent = true): void {
        this.currentDateFilter.startDate = newValue;
        this.storageService.save(SERVICE_FILTER_START_DATE_CONST, this.currentDateFilter.startDate);
        if (publishEvent) {
            this.dateFilter$.next(this.currentDateFilter);
        }
    }

    updateEndDate(newValue: string, publishEvent = true): void {
        this.currentDateFilter.endDate = newValue;
        this.storageService.save(SERVICE_FILTER_END_DATE_CONST, this.currentDateFilter.endDate);
        if (publishEvent) {
            this.dateFilter$.next(this.currentDateFilter);
        }
    }

    private showFilter(url: string): boolean {
        return Object.values(this.PATHS_TO_SHOW_FILTER).some((path) => url.includes(path));
    }

    isDirty(): boolean {
        return this.scopeState.isDirty();
    }
}
