export const apsCountry = (sourceSystemReference: string): string => {
    switch (sourceSystemReference) {
        case "APS_E":
            return "NZ";
        case "APS_N":
        case "APS_S":
        default:
            return "AU";
    }
};
