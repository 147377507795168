import { PriceInflationConfig } from "../../constants/ProductPriceInflation"; // this should be injected in constructor
import { IProduct } from "../../models/IProduct";

export interface IInflationService {
    getInflation(product: IProduct, country: string): number;
}

export class InflationService implements IInflationService {
    constructor(private priceInflationConfig: PriceInflationConfig) {}

    getInflation(product: IProduct, country: string): number {
        const customInflationModifierByCountry = this.priceInflationConfig[country];

        if (!customInflationModifierByCountry) {
            throw new Error(`Invalid Country : ${country}`);
        }

        if (customInflationModifierByCountry[product.serviceLine.toUpperCase()]) {
            return customInflationModifierByCountry[product.serviceLine.toUpperCase()];
        }
        return customInflationModifierByCountry.DEFAULT;
    }
}
