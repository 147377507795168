import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";

export interface IRuntime {
    writeStatus: WriteStatus;
}

export enum WriteStatus {
    create = "create",
    update = "update",
    delete = "delete",
    none = "none",
}

export const toDataObject = <TRuntimeDataObject extends IRuntime>(
    dataObject: TRuntimeDataObject
): Omit<TRuntimeDataObject, "writeStatus"> => {
    const copy: TRuntimeDataObject = cloneDeep(dataObject);
    // Lodash types are not suitable for generics, so needs these casts
    return omit(copy as any, "writeStatus") as TRuntimeDataObject;
};

export const mapToRuntime = <TRuntime extends IRuntime>(data: any): TRuntime => {
    if ((<IRuntime>data).writeStatus !== undefined) {
        return data as TRuntime;
    }
    return {
        ...data,
        writeStatus: WriteStatus.update, // FIX ME at the moment we have no way of differentiating so we just update all sorry!!!
    };
};
