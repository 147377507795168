import { Injectable } from "@angular/core";
import { IProgressStage } from "../components/yearlessScope/progress/progress.component";
import { Subject, ReplaySubject, Observable } from "rxjs";
import { ScopeStateService } from "./scope-state.service";

@Injectable({
    providedIn: "root",
})
export class ProgressService {
    stages$: ReplaySubject<Array<IProgressStage>> = new ReplaySubject(1);
    activeStage$: ReplaySubject<IProgressStage> = new ReplaySubject(1); // using this service will only allow one stepper at a time
    // dirty: boolean = false;
    constructor(private scopeState: ScopeStateService) {}

    setStage(newStage: IProgressStage): void {
        this.activeStage$.next(newStage);
    }

    getStage(): Observable<IProgressStage> {
        return this.activeStage$;
    }

    setStages(newStages: Array<IProgressStage>): void {
        this.stages$.next(newStages);
    }

    getStages(): Observable<Array<IProgressStage>> {
        return this.stages$;
    }

    clearDirty(): void {
        this.scopeState.clearDirty();
    }

    setDirty(): void {
        this.scopeState.setDirty();
    }

    isDirty(): boolean {
        return this.scopeState.isDirty();
    }
}
