import uniq from "lodash/uniq";
import { IScope } from "./../../models/IScope";
import { IEntity } from "../../models/IEntity";
import { Offices } from "../../../common/constants/Offices";
import get from "lodash/get";

export abstract class ScopeServiceBase {
    getDefaultPrimaryEntity(scope: IScope): IEntity {
        if (scope.primaryEntity) {
            return scope.primaryEntity;
        }

        if (!scope.prefills || scope.prefills.length === 0) {
            throw new Error("No prefill found on this scope; cannot determine primary entity");
        }
        const familyGroupId = scope.prefills[0].request.prefillId;
        return scope.entities.find((entity) => entity.entityId.referenceId === familyGroupId);
    }

    getEntityOffices(scope: IScope): string[] {
        const entityOffices = scope.entities.reduce((acc, entity) => [...acc, entity.office], []);
        const locations = uniq(entityOffices).filter((location) => typeof location === "string" && location.length > 0);

        // HACK: CRM is sending us some scopes that don't have an office. Let the user choose the office instead.
        if (locations.length > 0) {
            return locations;
        }

        return Object.keys(Offices).includes(scope.country) ? get(Offices, [scope.country]) : [];
    }
}
