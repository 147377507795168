// FIXME: merge back into the single-multi-selector.component
import { Component } from "@angular/core";
import { ISingleMultiSelectOption, SingleMultiSelectorComponent } from "./single-multi-selector.component";

/**
    Usage:

    <app-single-multi-selector
        [options]="[
            {
                type: 'checkbox',
                value: '2019',
                name: 'checkbox',
                id: 'm2019',
                label: '2019',
                selected: true
            }
        ]"
        [layout]="'inline'"
    ></app-single-multi-selector>
 */

@Component({
    selector: "app-single-multi-selector-header",
    templateUrl: "./single-multi-selector.component.html",
    styleUrls: ["./single-multi-selector.component.scss"],
})
export class SingleMultiSelectorHeaderComponent extends SingleMultiSelectorComponent {
    updateSelectedOptions(option: ISingleMultiSelectOption, event: any) {
        this.leftOptions = this.updateCheckedStatus(this.leftOptions, option, event);
        this.exposedOptions = this.updateCheckedStatus(this.exposedOptions, option, event);
        this.rightOptions = this.updateCheckedStatus(this.rightOptions, option, event);

        this.emitUpdatedHeaderValue(event);
        this.selectedString = this.calculateSelectedString();
    }

    private emitUpdatedHeaderValue(event: any): void {
        this.valueSelected.next({
            value: Number(event.target.value),
            checked: event.target.checked,
        });
    }
}
