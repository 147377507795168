import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClientRedirectComponent } from "./client-redirect/client-redirect.component";
import { ClientSearchComponent } from "./client-search/client-search.component";

const routes: Routes = [
    {
        path: "",
        component: ClientRedirectComponent,
        children: [
            {
                path: "",
                component: ClientSearchComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClientsListingRoutingModule {}
