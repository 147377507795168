import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { distinctUntilChanged } from "rxjs/operators";
import { QuillEditorComponent } from "ngx-quill";

@Component({
    selector: "app-wysiwyg-editor",
    templateUrl: "./wysiwyg-editor.component.html",
    styleUrls: ["./wysiwyg-editor.component.scss"],
})
export class WysiwygEditorComponent implements OnInit {
    contentFormControl: FormControl;
    modules: object = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["clean"],
        ],
    };

    @Input() content: string = "";
    @Input() placeholder: string | undefined = "Insert text here...";
    @Output() onContentChanged: EventEmitter<string>;

    @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
    constructor() {
        this.contentFormControl = new FormControl();

        this.onContentChanged = new EventEmitter<string>();
    }

    ngOnInit() {
        this.contentFormControl.setValue(this.content);

        this.contentFormControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            this.onContentChanged.emit(value);
        });
    }

    setFocus(editor: any) {
        editor.focus();
    }
}
