import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "classname",
})
export class ClassnamePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return value.toLowerCase().split(" ").join("-");
    }
}
