import { CommonJSTModule } from "../../modules/common.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ClientsListingRoutingModule } from "./clients-listing-routing.module";
import { ClientRedirectComponent } from "./client-redirect/client-redirect.component";
import { MaterialModule } from "src/app/modules/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClientSearchModule } from "./client-search/client-search.module";
import { RouterModule } from "@angular/router";
import { LegacyClientsListingComponent } from "./clients-listing/legacy-clients-listing.component";

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        ClientsListingRoutingModule,
        ClientSearchModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonJSTModule,
    ],
    declarations: [ClientRedirectComponent, LegacyClientsListingComponent],
})
export class ClientsListingModule {}
