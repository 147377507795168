import { getLowestEngagementStatus } from "./../../../../common/src/helpers/EngagementStateHelper";
import { EngagementStateOrder } from "./../../../../common/constants/EngagementStates";
import { arrayIsNullOrEmpty } from "./../../../../common/src/helpers/ArrayHelper";
import { Injectable } from "@angular/core";
import { BackendService } from "./backend.service";
import { throwError, Observable, of } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { IScopeNotificationMessage } from "src/../../common/models/IScopeSummary";
import lodash from "lodash";
import { IClientRow } from "../../../../common/models/IClientRow";
import { EngagementState } from "../../../../common/enums/EngagementState";

@Injectable({
    providedIn: "root",
})
export class ScopeListingService {
    constructor(protected backendService: BackendService) {}

    getScopes(): Observable<IScopeNotificationMessage[]> {
        const url = "scopes";
        return this.retrieveScopesFromUrl(url);
    }
    getScopesByMatterPartner(matterPartner: string): Observable<IScopeNotificationMessage[]> {
        const url = `scopes?matterPartner=${matterPartner}`;
        return this.retrieveScopesFromUrl(url);
    }
    getScopesByMatterManager(matterManager: string): Observable<IScopeNotificationMessage[]> {
        const url = `scopes?matterManager=${matterManager}`;
        return this.retrieveScopesFromUrl(url);
    }
    private retrieveScopesFromUrl(url: string): Observable<IScopeNotificationMessage[]> {
        return this.backendService.get<IScopeNotificationMessage[]>(url).pipe(
            switchMap((scopes) =>
                Array.isArray(scopes)
                    ? of(scopes as IScopeNotificationMessage[])
                    : throwError(new Error("Could not understand the scope listing"))
            ),
            map((scopes) =>
                scopes.map((scope) => ({
                    ...scope,
                    matterManager: lodash.get(scope, ["matterManagers", 0], ""),
                    matterPartner: lodash.get(scope, ["matterPartners", 0], ""),
                }))
            )
        );
    }

    formatLists(clientRows: Array<IClientRow>): any[] {
        // group by family group id TEMPORY
        const groupedRows: [string, IClientRow[]][] = lodash
            .chain(clientRows)
            .groupBy((clientRow) => clientRow.familyGroupId)
            .toPairs()
            .value();
        const getStatus = (rows: Array<IClientRow>, scopeId: string) => {
            if (!scopeId) {
                return "Not Scoped";
            }

            const engagementStates = rows
                .filter((row) => row.status != null && row.scopeId === scopeId)
                .map((row) => row.status);

            return getLowestEngagementStatus(engagementStates as EngagementState[]);
        };
        return groupedRows.map((group) => {
            return <IClientRow>{
                familyGroupId: group[0],
                familyGroupName: group[1][0].familyGroupName,
                revenueLY: group[1][0].revenueLY,
                invoicedLY: group[1][0].invoicedLY,
                writeOffsLY: group[1][0].writeOffsLY,
                revenueYTD: group[1][0].revenueYTD,
                invoicedYTD: group[1][0].invoicedYTD,
                writeOffsYTD: group[1][0].writeOffsYTD,
                scopeId: group[1][0].scopeId,
                year: group[1][0].year,
                sourceSystem: group[1][0].sourceSystem,
                status: getStatus(group[1], group[1][0].scopeId),
                scoped: this.getScopedTotal(group[1]),
                subRegion: group[1][0].subRegion,
                matterManager: group[1][0].matterManager,
                matterPartner: group[1][0].matterPartner,
                relationshipStrength: this.getRelationshipStrength(group[1][0].relationshipStrength),
            };
        });
    }

    private getRelationshipStrength(relationshipStrength: any): string {
        if (
            !relationshipStrength ||
            !(typeof relationshipStrength === "string") ||
            relationshipStrength.toLowerCase() === "null"
        ) {
            return "Please select";
        }

        return relationshipStrength;
    }

    getScopedTotal(rows: Array<IClientRow>): number {
        return rows.map((row) => (row.scoped ? row.scoped : 0)).reduce((acc, number) => number, 0);
    }
}
