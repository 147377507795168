import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AnalyticsCategory } from "../../../../../../common/constants/AnalyticsCategory";
import { AnalyticsService } from "../../../services/analytics.service";
import { ServiceFilterService } from "../../scope/service-filter.service";

@Component({
    selector: "app-service-filter",
    templateUrl: "./service-filter.component.html",
    styleUrls: ["./service-filter.component.scss"],
})
export class ServiceFilterComponent implements OnInit {
    dateFilter$: Observable<{ startDate: string; endDate: string }>;

    constructor(private serviceFilter: ServiceFilterService, private analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        this.dateFilter$ = this.serviceFilter.dateFilter.pipe(
            map((newValue) => {
                return {
                    startDate: newValue.startDate,
                    endDate: newValue.endDate,
                };
            })
        );
    }

    onStartDateChanged(newValue: string) {
        this.analyticsService.eventOccurred(AnalyticsCategory.filter, "scoping_configureengagements_filterstart");
        if (this.serviceFilter.isDirty() && !confirm("You have unsaved changes, do you wish to proceed?")) {
            return;
        }
        this.serviceFilter.updateStartDate(newValue);
    }

    onEndDateChanged(newValue: string) {
        this.analyticsService.eventOccurred(AnalyticsCategory.filter, "scoping_configureengagements_filterend");
        if (this.serviceFilter.isDirty() && !confirm("You have unsaved changes, do you wish to proceed?")) {
            return;
        }
        this.serviceFilter.updateEndDate(newValue);
    }
}
