import { Component, EventEmitter, Input } from "@angular/core";

@Component({
    selector: "app-count-button",
    templateUrl: "./count-button.component.html",
    styleUrls: ["./count-button.component.scss"],
})
export class CountButtonComponent {
    @Input()
    disabled: boolean = false;
    @Input()
    click: EventEmitter<void> = new EventEmitter<void>();
    @Input()
    title: string = "";
    @Input()
    count: number = 0;

    onClick() {
        this.click.emit();
    }
}
