import { Injectable } from "@angular/core";
import { ServiceLineServiceBase } from "./../../../../common/src/services/ServiceLineServiceBase";
import { LoggingService } from "./logging.service";

@Injectable({
    providedIn: "root",
})
export class ServiceLineService extends ServiceLineServiceBase {
    constructor(loggingService: LoggingService) {
        super(loggingService);
    }
}
