import { ServiceLine } from "./../../enums/ServiceLine";
import { ILoggingService } from "./ILoggingService";
import { IWorkItem, IMatchedWorkItem } from "../../models/IWorkItem";
import { arrayIsNullOrEmpty } from "../helpers/ArrayHelper";
import { IProduct } from "../../models/IProduct";
import {
    ServiceLineCanonicalMapping,
    IServiceLineMapping,
    ServiceLineAbbreviatedNames,
} from "../../constants/ServiceLine";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { IServiceLine } from "../../models/IServiceLine";
import { IEngagement } from "../../models/IEngagement";
import { sourceSystemReferencesAreEqual } from "../helpers/SourceSystemReferenceHelper";

export class ServiceLineServiceBase {
    constructor(protected loggingService: ILoggingService) {}

    getDistinctServiceLinesFromWorkItems(
        workItems: IWorkItem[],
        products: IProduct[],
        serviceLineMapper?: IServiceLineMapping
    ): Array<ServiceLine> {
        if (arrayIsNullOrEmpty(workItems)) {
            return [];
        }
        const activeWorkItems = workItems.filter((workItem) => workItem.active === true);
        const serviceLines = activeWorkItems.map((workItem) => {
            const workItemProduct = products.find((product) => product.productId === workItem.productId);
            return this.getCanonicalServiceLine(workItemProduct.serviceLine, serviceLineMapper);
        });
        return uniq(serviceLines);
    }

    getServiceLineFromProduct(product: IProduct): ServiceLine {
        return this.getCanonicalServiceLine(product.serviceLine);
    }

    getDistinctServiceLines(
        workItems: IWorkItem[],
        products: IProduct[],
        serviceLineMapper?: IServiceLineMapping
    ): Array<IServiceLine> {
        if (arrayIsNullOrEmpty(workItems)) {
            return [];
        }
        const activeWorkItems = workItems.filter((workItem) => workItem.active === true);
        const serviceLines = activeWorkItems
            .filter((workItem) => products.find((product) => product.productId === workItem.productId))
            .map((workItem) => {
                const workItemProduct = products.find((product) => product.productId === workItem.productId);
                const code = this.getCanonicalServiceLine(workItemProduct.serviceLine, serviceLineMapper);
                const serviceLineName = ServiceLineAbbreviatedNames[code] ? ServiceLineAbbreviatedNames[code] : code;
                return {
                    name: serviceLineName,
                    code: this.getCanonicalServiceLine(workItemProduct.serviceLine, serviceLineMapper),
                } as IServiceLine;
            });
        return uniqBy(serviceLines, "code");
    }

    getEngagementServiceLines(engagement: IEngagement, products: IProduct[]): Array<ServiceLine> {
        const mappedWorkItems: IMatchedWorkItem[] = engagement.workItems.map((workItem) => {
            const entity = engagement.entities.find((engagementEntity) =>
                sourceSystemReferencesAreEqual(workItem.entityId, engagementEntity.entityId)
            );
            const product = products.find((productDetails) => productDetails.productId === workItem.productId);
            return {
                workItem,
                entity,
                product,
            };
        });
        const serviceLines = mappedWorkItems.map((mappedWorkItem) =>
            this.getServiceLineFromProduct(mappedWorkItem.product)
        );
        return uniq(serviceLines);
    }

    getCanonicalServiceLine(serviceLineString: string, testMapping?: IServiceLineMapping): ServiceLine {
        const mapping = testMapping != null ? testMapping : ServiceLineCanonicalMapping;
        return mapping[serviceLineString];
    }
}
