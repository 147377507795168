import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { ViewportRuler } from "@angular/cdk/scrolling";
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { OptionComponent } from "./option.component";

@Component({
    selector: "app-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements AfterViewInit, AfterViewChecked, OnDestroy, ControlValueAccessor {
    constructor(protected viewportRuler: ViewportRuler, protected changeDetectorRef: ChangeDetectorRef) {}

    @ViewChild("trigger", { static: false }) trigger: CdkOverlayOrigin;

    @ContentChildren(OptionComponent) options: QueryList<OptionComponent>;

    @Input() id?: string;
    @Input() name?: string;
    @Input() placeholder = "Select an option";
    @Input() disabled: boolean | "";
    @Output() change = new EventEmitter<any>();

    selected: OptionComponent;
    expand: boolean;
    unbindOptions = new Subject();
    initialFormValue: any; // required because reactive forms sets the value before the children are ready
    dropdownTriggerWidth: number;

    protected readonly onDestroy$ = new Subject<void>();

    onChange = (newValue?: any) => {};
    onTouched = () => {};

    uiSelect(option: OptionComponent): void {
        if (option.disabled || option.disabled != null || this.disabled || this.disabled === "") {
            return;
        }

        this.selectOption(option);
        this.change.emit(option.value);
        this.expand = !this.expand;
        this.onTouched();
    }

    private watchTriggerWidth(): void {
        this.viewportRuler
            .change()
            .pipe(takeUntil(this.onDestroy$), debounceTime(300))
            .subscribe(() => {
                this.calculateContentWidth();
            });
    }

    private calculateContentWidth(): void {
        this.dropdownTriggerWidth = this.trigger.elementRef.nativeElement.getBoundingClientRect().width;
        this.changeDetectorRef.detectChanges();
        this.watchTriggerWidth();
    }

    ngAfterViewInit(): void {
        this.options.forEach((option) => this.bindOptionChanges(option));
    }

    ngAfterViewChecked(): void {
        this.calculateContentWidth();
    }

    ngOnDestroy(): void {
        this.unbindOptions.complete();

        if (this.onDestroy$) {
            this.onDestroy$.next();
            this.onDestroy$.complete();
        }
    }

    private bindOptionChanges(option: OptionComponent): void {
        if (option.selected) {
            setTimeout(() => this.selectOption(option));
        }
        if (this.initialFormValue && option.value === this.initialFormValue) {
            setTimeout(() => this.selectOption(option));
        }

        option.selectChange.pipe(takeUntil(this.unbindOptions)).subscribe(() => this.selectOption(option));
    }

    private selectOption(option: OptionComponent): void {
        this.options.forEach((optionChild) => (optionChild.selected = false));

        option.selected = true;
        this.selected = option;
        this.onChange(option.value);
    }

    writeValue(value: any): void {
        if (this.options) {
            this.options.forEach((optionChild) => (optionChild.selected = false));
            const option = this.options.find((optionChild) => optionChild.value === value);
            if (option) {
                option.selected = true;
                this.selected = option;
            }
        } else {
            this.initialFormValue = value;
        }
    }
    registerOnChange(fn: (obj?: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
