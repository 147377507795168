import { Injectable } from "@angular/core";
import { WindowService } from "./window.service";
import papaparse from "papaparse";

@Injectable({
    providedIn: "root",
})
export class CsvService {
    constructor(protected windowService: WindowService) {}

    downloadCsv(content: any[], filename: string) {
        const parsedContent = papaparse.unparse(content) as string;

        this.downloadBlob(parsedContent, filename);
    }

    private downloadBlob(parsedContent: string, filename: string) {
        const blob = new Blob([parsedContent], { type: "text/csv" });
        const url = this.windowService.window().URL.createObjectURL(blob);
        const elem = this.windowService.window().document.createElement("a");
        elem.href = url;
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}
