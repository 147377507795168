import { Component, Input, OnInit } from "@angular/core";
import { GraphService } from "src/app/services/graph.service";
import { LoggingService } from "src/app/services/logging.service";
import { AnalyticsCategory } from "../../../../../../common/constants/AnalyticsCategory";
import { Administrators, ServiceDeskRepresentatives } from "../../../../../../common/constants/ServiceDesk";
import { environment } from "../../../../environments/environment";
import { AnalyticsService } from "../../../services/analytics.service";

@Component({
    selector: "HelpMenu",
    templateUrl: "helpMenu.component.html",
    styleUrls: ["helpMenu.component.scss"],
})
export class HelpMenu implements OnInit {
    isAdministrator: boolean;
    @Input() isLoggedIn: boolean;

    constructor(
        private analyticsService: AnalyticsService,
        private graphService: GraphService,
        private loggingService: LoggingService //
    ) {}

    ngOnInit(): void {
        if (this.isLoggedIn) {
            this.graphService.getMe().subscribe((myself) => {
                const email = myself["mail"];
                this.isAdministrator =
                    ServiceDeskRepresentatives.includes(email.toLowerCase()) ||
                    Administrators.includes(email.toLowerCase());
                (err): void => this.loggingService.error(err, "HelpMenuOnInit", err.message);
            });
        }
    }

    raiseAnalyticsEvent(eventType: string): void {
        this.analyticsService.eventOccurred(AnalyticsCategory.buttonClick, eventType);
    }

    appVersion: string = environment.release;
}
