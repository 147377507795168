import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class WindowService {
    constructor() {}

    window(): any {
        return window;
    }
}
