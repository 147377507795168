import { Injectable } from "@angular/core";
import lodash from "lodash";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class AnalyticsService {
    navigationOccurred(source: string, navigationType: string) {
        const sanitizedType = this.sanitizeEvent(navigationType);
        const sanitizedSource = this.sanitizeEvent(source);
        this.sendEvent("navigation", sanitizedSource, sanitizedType);
    }

    searchEventOccurred() {
        this.sendEvent("search", "search");
    }

    eventOccurred(category: string, eventType: string, value?: number) {
        const sanitizedCategory = this.sanitizeEvent(category);
        const sanitizedAction = this.sanitizeEvent(eventType);
        this.sendEvent(sanitizedCategory, "user-action", sanitizedAction, value);
    }

    updateLocation(location: string) {
        const sanitizedLocation = this.sanitizeLocation(location);
        const pathComponents = sanitizedLocation.split("/");
        if (location.startsWith("/")) {
            if (pathComponents.length > 1) {
                this.setPage(pathComponents[1]);
            } else {
                this.setPage(sanitizedLocation);
            }
        } else {
            this.setPage(pathComponents[0]);
        }
    }

    updatePageView() {
        this.tracker.send("pageview");
    }

    private sanitizeLocation(unsafeLocation: string) {
        if (!unsafeLocation) {
            return "";
        }
        try {
            const url = new URL(unsafeLocation, window.location.href);
            // Strip out the protocol, hostname, query params and hash
            const { pathname } = url;
            return pathname;
        } catch (error) {
            const withoutHash = unsafeLocation.split("#")[0];
            return withoutHash.split("?")[0];
        }
    }

    private sanitizeEvent(tabName: string) {
        return lodash.kebabCase(tabName.toLowerCase());
    }

    private sendEvent(category: string, action: string, label?: string, value?: any) {
        this.tracker.send("event", category, action, label, value);
    }

    private setPage(location: string) {
        this.tracker.set("page", location);
    }

    private get tracker() {
        const theWindow = <any>window;
        if ("ga" in window && "getAll" in theWindow.ga) {
            const tracker = theWindow.ga.getAll()[0];
            if (tracker) {
                return tracker;
            }
        }
        return {
            send: () => {
                //do nothing
            },
            set: () => {
                //do nothing
            },
        };
    }

    initialize() {
        (<any>window).ga("create", environment.googleAnalytics.id, "auto");
        (<any>window).ga("event", "pageview");
    }
}
