import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthenticationInterceptorService, AuthenticationService } from "./services/authentication.service";
import { AuthenticationModule } from "./modules/authentication.module";
import { SentryService } from "./services/sentry.service";
import { MaterialModule } from "./modules/material.module";
import { NotificationModule } from "./components/common/notification/notification.module";
import { HelpMenu } from "./components/common/helpMenu/helpMenu.component";
import { FindexLocationsService } from "./services/findexLocations.service";
import { GraphService } from "./services/graph.service";
import { CommonJSTModule } from "./modules/common.module";
import { ScopeHttpRequestInterceptor } from "./services/http-interceptor.service";
import { TourService } from "./services/tour.service";
import { SiteMaintenanceComponent } from "./components/site-maintenance/site-maintenance.component";
import { MaintenanceInterceptorService } from "./services/maintenance-interceptor.service";
import { ConfirmDialogModule } from "./components/common/confirm-dialog/confirm-dialog.module";
import { QuillModule } from "ngx-quill";
import { ClientModule } from "./components/yearlessScope/client/client.module";
import { EngagementHelper } from "../../../common/src/helpers/EngagementHelper";
import { ClientsListingModule } from "./components/clients-listing/clients-listing.module";
import { PRODUCT_PRICE_INFLATION } from "../../../common/constants/ProductPriceInflation";
import { HotjarService } from "./services/hotjar.service";
import { InflationService } from "../../../common/src/services/InflationService";
import { PRODUCT_PRICE_INFLATION_CONFIG } from "src/constants/Products";
import { RoadblockComponent } from "./components/roadblock/roadblock.component";
import { EmailSendingComponent } from "./components/email-sending/email-sending.component";
import { IsAdministratorGuard } from "./administrator.guard";

@NgModule({
    declarations: [AppComponent, RoadblockComponent, SiteMaintenanceComponent, HelpMenu, EmailSendingComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AuthenticationModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        NotificationModule,
        CommonJSTModule,
        ClientModule,
        ConfirmDialogModule,
        ClientsListingModule,
        QuillModule.forRoot(),
    ],
    providers: [
        AuthenticationInterceptorService,
        AuthenticationService,
        IsAdministratorGuard,
        FindexLocationsService,
        GraphService,
        TourService,
        EngagementHelper,
        // FIXME: would be nice to use en-NZ for NZ users from Graph, but much ado about nothing at the moment
        { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        SentryService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ScopeHttpRequestInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptorService, multi: true },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        HotjarService,
        { provide: PRODUCT_PRICE_INFLATION_CONFIG, useValue: PRODUCT_PRICE_INFLATION },
        { provide: InflationService, useClass: InflationService, deps: [PRODUCT_PRICE_INFLATION_CONFIG] },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
