import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { ICellData } from "src/models/ICellData";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Moment } from "moment";

/**
    Usage:

    <app-date-selector
        *ngIf="field.type === 'date'"
        [cellData]="cellDatas[workItem.workItemId][field.key]"
        [value]="cellDatas[workItem.workItemId][field.key].value"
        (valueSelected)="fieldDataOnChange(workItem, field, $event.value)"
    >
 */

@Component({
    selector: "app-date-selector",
    templateUrl: "./date-selector.component.html",
    styleUrls: ["./date-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSelectorComponent {
    @Input() placeholder: string = "Choose a date";
    @Input() cellData: ICellData;
    @Input() value: string;
    @Input() dateFormat: string = "YYYY-MM-DD";
    @Input() enabled: boolean = true;
    @Input() minDate: string;
    @Input() maxDate: string;
    @Output() valueSelected = new EventEmitter();

    update(event: MatDatepickerInputEvent<Moment>) {
        this.value = event.value.format(this.dateFormat);
        this.valueSelected.next(this.value);
    }
}
