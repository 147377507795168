import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Moment } from "moment";
import { MAT_DATE_FORMATS } from "@angular/material/core";

export const MONTH_SELECTOR_DATE_FORMATS = {
    display: {
        dateInput: "MMMM YYYY",
        monthYearLabel: "MMM YYYY",
    },
};

/**
    Usage:

    <app-month-selector
        *ngIf="field.type === 'date'"
        [enabled]="cellDatas[workItem.workItemId][field.key].metadata?.editable"
        [value]="cellDatas[workItem.workItemId][field.key].value"
        (valueSelected)="fieldDataOnChange(workItem, field, $event.value)"
    >
 */

@Component({
    selector: "app-month-selector",
    templateUrl: "./month-selector.component.html",
    styleUrls: ["./month-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_SELECTOR_DATE_FORMATS }],
})
export class MonthSelectorComponent {
    @Input() value: string;
    @Input() dateFormat: string = "YYYY-MM";
    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() enabled: boolean;
    @Input() placeholder: string = "Choose a month";
    @Input() endOfMonth: boolean = false;
    @Output() valueSelected = new EventEmitter();

    update(event: MatDatepickerInputEvent<Moment>) {
        const date = this.endOfMonth ? event.value.endOf("month") : event.value.startOf("month");
        this.value = date.format(this.dateFormat);
        this.valueSelected.next(this.value);
    }

    selectedMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const date = this.endOfMonth ? normalizedMonth.endOf("month") : normalizedMonth.startOf("month");
        this.value = date.format(this.dateFormat);
        this.valueSelected.next(this.value);
        datepicker.close();
    }
}
