import { Injectable } from "@angular/core";
import { ScopeValidator } from "../../../../common/src/validators/ScopeValidator";
import { LoggingService } from "../services/logging.service";

@Injectable({
    providedIn: "root",
})
export class InjectableScopeValidator extends ScopeValidator {
    constructor(loggingService: LoggingService) {
        super(loggingService);
    }
}
