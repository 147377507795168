import { Injectable } from "@angular/core";
import { BackendService } from "./backend.service";
import { INotification, NotificationAlertLevel } from "../../../../common/models/INotification";
import { map } from "rxjs/operators";
import { Observable, of, from } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(private backendService: BackendService) {}

    getNotifications(): Observable<INotification[]> {
        return this.backendService.get("notifications").pipe(map((array) => this.validateNotificationArray(array)));
    }

    /**
     * Validates that the notification is of the correct type
     * TODO: possibly replace this with an AJV-based alternative
     * TODO: use unknown when we can use Typescript 3
     */
    private validateNotificationArray(array: any): INotification[] {
        if (
            Array.isArray(array) &&
            array.every((element) => typeof element.alertLevel === "number" && typeof element.message === "string")
        ) {
            return array as INotification[];
        }
        throw new TypeError("Could not parse notifications");
    }
}
