import { IErrorHandler } from "./error-handler.service";
import { JstError } from "../../../../common/models/JstError";
import { Injectable } from "@angular/core";
import { LoggingService } from "./logging.service";
import { BackendService } from "./backend.service";
import { IEntity } from "../../../../common/models/IEntity";
import { Observable } from "rxjs";
import { IRelationship } from "../../../../common/models/IRelationship";
import { ISourceSystemReference } from "../../../../common/models/ISourceSystemReference";
import { sourceSystemReferencesAreEqual } from "../../../../common/src/helpers/SourceSystemReferenceHelper";
import { formatError } from "../../../../common/src/helpers/JstErrorHelper";
import { IPrefillResponse } from "../../../../common/models/IPrefill";

@Injectable({
    providedIn: "root",
})
export class CustomerService implements IErrorHandler {
    constructor(private loggingService: LoggingService, private backendService: BackendService) {}

    getCustomers(
        { referenceId: prefillId, sourceSystemName: location }: ISourceSystemReference,
        apsType: string
    ): Observable<IPrefillResponse> {
        const data = [
            { key: "id", value: prefillId },
            { key: "location", value: location },
            { key: "type", value: apsType },
        ];
        return this.backendService.get("customers", data);
    }

    refreshEngagementEntities(
        engagementEntities: IEntity[],
        scopeEntities: IEntity[],
        neededEntityIds: Array<ISourceSystemReference>
    ): IEntity[] {
        try {
            return neededEntityIds.map((entityId) => {
                const engagementEntity = engagementEntities.find((entity) =>
                    sourceSystemReferencesAreEqual(entity.entityId, entityId)
                );
                const scopeEntity = scopeEntities.find((entity) =>
                    sourceSystemReferencesAreEqual(entity.entityId, entityId)
                );
                if (!engagementEntity && !scopeEntity) {
                    const params = {
                        displayMessage:
                            "An error occurred when updating data from the scope. One or more entities were not found",
                        functionName: "refreshEngagementEntities",
                        className: "CustomerService",
                    };
                    throw formatError(new Error(`Failed to find entity ${entityId}`), params);
                }
                if (!engagementEntity) {
                    return scopeEntity;
                }
                if (!scopeEntity) {
                    return engagementEntity;
                }
                // merge without breaking changes
                return {
                    ...scopeEntity,
                    debtorId: engagementEntity.debtorId,
                    country: engagementEntity.country,
                    active: engagementEntity.active,
                    type: engagementEntity.type,
                };
            });
        } catch (err) {
            // for unhandled errors
            this.handleError(err, "refreshEngagementEntities", "An error occurred when attempting to update entities");
            return undefined;
        }
    }

    refreshEngagementRelationships(
        engagementRelationships: Array<IRelationship>,
        scopeRelationships: Array<IRelationship>
    ): Array<IRelationship> {
        try {
            return engagementRelationships.map((relationship) => {
                const scopeRelationship = scopeRelationships.find(
                    (updatedRelationship) =>
                        updatedRelationship.email.toLowerCase() === relationship.email.toLowerCase()
                );
                if (scopeRelationship) {
                    return {
                        ...scopeRelationship,
                        type: relationship.type,
                    };
                }
                return relationship;
            });
        } catch (err) {
            // for unhandled errors
            this.handleError(
                err,
                "refreshEngagementRelationships",
                "An error occurred when attempting to update relationships"
            );
            return undefined;
        }
    }

    handleError(error: Error | JstError, functionName: string, displayMessage: string): void {
        const params = {
            displayMessage,
            functionName,
            className: "CustomerService",
        };
        throw formatError(error, params);
    }
}
