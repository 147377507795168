import { JstHttpError } from "./../../../../common/models/JstError";
import { Injectable } from "@angular/core";
import { JstError, IErrorParameters } from "../../../../common/models/JstError";

@Injectable({
    providedIn: "root",
})
export class ErrorHandlerService {
    formatError(error: Error, params: IErrorParameters): JstError {
        if (this.isJstError(error)) {
            return error;
        }
        if (this.isJstHttpError(error)) {
            const updatedParams: IErrorParameters = {
                ...params,
                errorCode: error.errorCode as string,
                url: error.url,
            };
            const httpToJstResult = new JstError(error.message, updatedParams);
            httpToJstResult.stack = error.stack;
            return httpToJstResult;
        }
        const normalToJstResult = new JstError(error.message, params);
        normalToJstResult.stack = error.stack;
        return normalToJstResult;
    }

    isJstError(error: Error | JstHttpError | JstError): error is JstError {
        return (<JstError>error).params !== undefined;
    }
    isJstHttpError(error: Error | JstHttpError | JstError): error is JstHttpError {
        return (<JstHttpError>error).url !== undefined || (<JstHttpError>error).errorCode !== undefined;
    }
}

// TODO move this somewhere else

export interface IErrorHandler {
    handleError(...stuff: any[]): void;
}
