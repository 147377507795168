import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import lodash from "lodash";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { v4 as Uuid } from "uuid";

/**
    Usage:

    <app-single-multi-selector
        [options]="[
            {
                type: 'checkbox',
                value: '2019',
                name: 'checkbox',
                id: 'm2019',
                label: '2019',
                selected: true
            }
        ]"
        [layout]="'inline'"
    ></app-single-multi-selector>
 */

export interface ISingleMultiSelectOption {
    type: string;
    value: string;
    name: string;
    id: string;
    label: string;
    selected?: boolean;
    disabled?: boolean;
}
@Component({
    selector: "app-single-multi-selector",
    templateUrl: "./single-multi-selector.component.html",
    styleUrls: ["./single-multi-selector.component.scss"],
})
export class SingleMultiSelectorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() cellData: any;
    @Input() value: string[];
    @Input() layout: string;
    @Input() editable: boolean;
    @Input() documentClickEvent: Subject<any>;
    @Input() escapeButtonEvent: Subject<any>;
    @Output() valueSelected = new EventEmitter();

    exposedOptions: Array<ISingleMultiSelectOption> = [];
    leftOptions: Array<ISingleMultiSelectOption> = [];
    rightOptions: Array<ISingleMultiSelectOption> = [];
    isExpanded = false;
    isDisabled = false;
    selectedString = "";

    ngUnsubscribe = new Subject();
    parentEventSubscription: Subscription;
    escapeEventSubscription: Subscription;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.initialiseOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            Object.prototype.hasOwnProperty.call(changes, "value") &&
            !changes.firstChange &&
            !lodash.isEqual(changes.value.previousValue, changes.value.currentValue)
        ) {
            this.initialiseOptions();
        }
    }

    initialiseOptions(): void {
        this.leftOptions = [];
        this.exposedOptions = [];
        this.rightOptions = [];
        if (this.cellData && this.cellData.options && this.cellData.options.length) {
            this.isDisabled = (this.cellData.metadata && this.cellData.metadata.editable) || false;
            const multiEditable =
                this.cellData && this.cellData.metadata && this.cellData.metadata.multiEditable
                    ? this.cellData.metadata.multiEditable
                    : undefined;

            const allOptions = this.cellData.options.map((option: any) => {
                return {
                    type: "checkbox",
                    value: option,
                    name: `name${option}`,
                    id: `id${Uuid()}${option}`,
                    label: option,
                    selected: this.value && this.value.indexOf(option) !== -1,
                    disabled: multiEditable ? !multiEditable[option] : false,
                };
            });

            if (this.value && this.value.length) {
                this.value.forEach((val: any) => {
                    const option = allOptions.find((option: any) => option.value === val);
                    if (option) {
                        option.selected = true;
                    }
                });
            }

            // use defaultYearOffset - if it doesn't exist revert to the historical default of last year
            const defaultYearOffset =
                this.cellData.metadata && typeof this.cellData.metadata.defaultYearOffset === "number"
                    ? this.cellData.metadata.defaultYearOffset
                    : -1;
            this.leftOptions = allOptions.slice(0, -defaultYearOffset);
            this.exposedOptions = [allOptions[-defaultYearOffset]];
            this.rightOptions = allOptions.slice(-defaultYearOffset + 1);

            if (this.value && this.value.length) {
                this.value.forEach((val: any) => {
                    const option = this.rightOptions.find((option: any) => option.value === val);
                    if (option) {
                        option.selected = true;
                    }
                });
            }

            this.selectedString = this.calculateSelectedString();
        }
    }

    open(event: any): void {
        this.isExpanded = true;
        if (this.parentEventSubscription) {
            this.parentEventSubscription.unsubscribe();
        }
        this.parentEventSubscription = this.documentClickEvent
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((parentEvent) => {
                if (parentEvent && !this.el.nativeElement.contains(parentEvent.target)) {
                    this.close(parentEvent);
                }
            });
        if (this.escapeEventSubscription) {
            this.escapeEventSubscription.unsubscribe();
        }
        this.escapeEventSubscription = this.escapeButtonEvent
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((parentEvent) => {
                if (parentEvent) {
                    this.close(parentEvent);
                }
            });
    }

    close(event: any): void {
        this.isExpanded = false;
        if (this.parentEventSubscription || this.escapeEventSubscription) {
            this.escapeEventSubscription.unsubscribe();
        }
    }

    // FIXME: we shouldn't be passing events around like this
    // https://angular.io/guide/user-input#passing-event-is-a-dubious-practice
    updateSelectedOptions(option: ISingleMultiSelectOption, event: any): void {
        this.leftOptions = this.updateCheckedStatus(this.leftOptions, option, event);
        this.exposedOptions = this.updateCheckedStatus(this.exposedOptions, option, event);
        this.rightOptions = this.updateCheckedStatus(this.rightOptions, option, event);

        this.emitUpdatedValue(event);
        this.selectedString = this.calculateSelectedString();
    }

    // this is overridden by single-multi-selector-header.component
    private emitUpdatedValue(_event: any): void {
        const selectedOptions = [...this.leftOptions, ...this.exposedOptions, ...this.rightOptions].filter(
            (option) => option.selected === true
        );
        const selectedValues = selectedOptions.map((option: ISingleMultiSelectOption) => option.value);
        this.valueSelected.next(selectedValues);
    }

    protected updateCheckedStatus(
        options: ISingleMultiSelectOption[],
        option: { id: string },
        event: { target: { checked: boolean } }
    ): ISingleMultiSelectOption[] {
        return options.map((currentOption) =>
            currentOption.id === option.id
                ? {
                      ...currentOption,
                      selected: event.target.checked,
                  }
                : currentOption
        );
    }

    calculateSelectedString(): string {
        const allOptions = [...this.leftOptions, ...this.exposedOptions, ...this.rightOptions];

        if (allOptions.length) {
            if (allOptions.filter((option) => option.selected === true).length === 1) {
                return `(${allOptions.filter((option) => option.selected === true)[0].label})`;
            } else if (allOptions.filter((option) => option.selected === true).length > 1) {
                return `(${allOptions.filter((option) => option.selected === true).length})`;
            } else {
                return "";
            }
        }
        return "";
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
