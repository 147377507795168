import { Country } from "../constants/Country";
import { ServiceLine } from "../enums/ServiceLine";

export type PriceInflationConfig = {
    [country: string]: {
        [key: string]: number;
        DEFAULT: number;
    };
};

export const PRODUCT_PRICE_INFLATION: PriceInflationConfig = {
    [Country.AU]: {
        BA: 1.095,
        SF: 1.095,
        [ServiceLine.bas]: 1.095,
        [ServiceLine.smsf]: 1.095,
        [ServiceLine.ia]: 1.095,
        [ServiceLine.au]: 1.095,
        [ServiceLine.ca]: 1.1,
        [ServiceLine.ta]: 1.1,
        [ServiceLine.pc]: 1.1,
        DEFAULT: 1.075,
    },
    [Country.NZ]: {
        BA: 1.095,
        SF: 1.095,
        [ServiceLine.bas]: 1.095,
        [ServiceLine.smsf]: 1.095,
        [ServiceLine.ia]: 1.095,
        [ServiceLine.au]: 1.095,
        [ServiceLine.ca]: 1.1,
        [ServiceLine.ta]: 1.1,
        [ServiceLine.pc]: 1.1,
        DEFAULT: 1.075,
    },
};
