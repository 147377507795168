import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Administrators, ServiceDeskRepresentatives } from "../../../common/constants/ServiceDesk";
import { GraphService } from "./services/graph.service";
import { LoggingService } from "./services/logging.service";

@Injectable()
export class IsAdministratorGuard implements CanActivate, CanActivateChild {
    constructor(private loggingService: LoggingService, private graphService: GraphService) {}

    private serviceDeskObservable(): Observable<boolean> {
        return this.graphService.getMe().pipe(
            map((myself) => {
                const email = myself["mail"];
                const isAdministrator =
                    ServiceDeskRepresentatives.includes(email.toLowerCase()) ||
                    Administrators.includes(email.toLowerCase());
                return isAdministrator;
            }),
            catchError((err) => {
                this.loggingService.error(err, "IsAdministratorGuardOnInit", err.message);
                return of(false);
            })
        );
    }

    canActivate(): Observable<boolean> {
        return this.serviceDeskObservable();
    }

    canActivateChild(): Observable<boolean> {
        return this.serviceDeskObservable();
    }
}
