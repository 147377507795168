import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { environment } from "src/environments/environment";
import { ErrorsNotToLog } from "src/constants/Sentry";
import { JstError } from "../../../../common/models/JstError";
import lodash from "lodash";

// https://docs.sentry.io/data-management/rollups/?platform=browser

if (environment.sentry.enabled) {
    Sentry.init({
        dsn: environment.sentry.dsn,
        release: environment.release,
        environment: environment.environment,
        // adapted from https://docs.sentry.io/platforms/javascript/configuration/filtering/
        ignoreErrors: [
            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "EBCallBackMessageReceived",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
            /**
             * Specific to JST Errors we should ignore
             */
            // Angular generic error - https://github.com/getsentry/sentry-javascript/issues/2292
            "Non-Error exception captured with keys: error, headers, message, name, ok",
            // AADSTS50076: MFA Interaction required error - ignored by sentry
            /^AADSTS50076: Due to a configuration change made by your administrator, or because you moved to a new location, you must use multi-factor authentication to access.+?$/,
            // AADSTS50058: A silent sign-in request was sent but no user is signed in - ignored by sentry
            "AADSTS50058: A silent sign-in request was sent but no user is signed in.",
            "AADSTS50058: A silent sign-in request was sent but none of the currently signed in user(s) match the requested login hint.",
            "user_login_error|User login is required",
            // AADSTS50078: Presented multi-factor authentication has expired due to policies configured by your administrator, you must refresh your multi-factor authentication to access ...
            /^AADSTS50078: Presented multi-factor authentication has expired due to policies configured by your administrator, you must refresh your multi-factor authentication to access.+?$/,
        ],
        blacklistUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
    });
} else {
    /* this is the rare case where we actually want a console.log in frontend */
    /* eslint-disable-next-line no-console */
    // console.log('SentryService not enabled in this environment');
}

@Injectable({
    providedIn: "root",
})
export class SentryService implements ErrorHandler {
    logToSentry(error: any, severity: string) {
        if (environment.sentry.enabled) {
            if (error instanceof JstError && this.shouldLogError(error)) {
                Sentry.configureScope((scope) => {
                    scope.setTag("class_name", error.params.className);
                    scope.setTag("function_name", error.params.functionName);
                    scope.setFingerprint([
                        environment.release,
                        environment.environment,
                        error.params.className,
                        error.params.functionName,
                    ]);
                });
                Sentry.captureException(error);
            } else if (this.shouldLogError(error)) {
                if (error instanceof Error) {
                    Sentry.captureException(error);
                } else {
                    Sentry.captureMessage(
                        error.originalError || error,
                        Sentry.Severity.fromString(severity.toLowerCase())
                    );
                }
            }
        }
    }

    shouldLogError(error: any): boolean {
        if (!error) {
            // nothing to log
            return false;
        }
        const message = lodash.get(error, ["message"], "");
        return !ErrorsNotToLog.some((notImportantString) => message.includes(notImportantString));
    }

    handleError(error: any) {
        if (environment.sentry.enabled) {
            Sentry.captureException(error);
        }
    }

    setUser(user: string): void {
        Sentry.configureScope((scope) => {
            scope.setUser({ email: user });
        });
    }
}
