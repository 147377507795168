import { IEntity } from "../../models/IEntity";
import sortBy from "lodash/sortBy";
import { EntityBehaviourGroups, EntityRole } from "../../constants/Entity";
import { sourceSystemReferencesAreEqual } from "../helpers/SourceSystemReferenceHelper";

import "reflect-metadata";

import { injectable } from "tsyringe";

@injectable()
export class EntityServiceBase {
    orderByEntityType(
        objectsWithEntities: {
            entity: IEntity;
            [key: string]: any;
        }[]
    ): { entity: IEntity; [key: string]: any }[] {
        const order = this.orderOfEntities();

        const grouped = order.map((entityType) => {
            const objectsWithEntitiesForType = objectsWithEntities.filter((objectWithEntity) =>
                this.compareTypes(entityType, objectWithEntity.entity.type)
            );
            return sortBy(objectsWithEntitiesForType, ["entity.name", "asc"]);
        });

        const others = objectsWithEntities.filter((objectWithEntity) => {
            return !order.some((entityType) => this.compareTypes(entityType, objectWithEntity.entity.type));
        });
        const othersSorted = sortBy(others, ["entity.name", "asc"]);

        const sorted = grouped.reduce((acc, group) => {
            return [...acc, ...group];
        });

        return [...sorted, ...othersSorted];
    }

    orderOfEntities() {
        const groupOrder = [
            EntityBehaviourGroups.company,
            EntityBehaviourGroups.trust,
            EntityBehaviourGroups.partnership,
            EntityBehaviourGroups.individual,
        ];
        return groupOrder.reduce((acc, nextGroup) => [...acc, ...nextGroup]);
    }

    compareTypes = (left: string, right: string): boolean => {
        return left.localeCompare(right, ["en"], { sensitivity: "base", usage: "search" }) === 0;
    };

    orderEntitiesByType(entities: IEntity[]): Array<IEntity & { [key: string]: any }> {
        const order = this.orderOfEntities();

        const grouped = order.map((entityType) => {
            const workItemsWithEntitiesForType = entities.filter((entity) =>
                this.compareTypes(entityType, entity.type)
            );
            return workItemsWithEntitiesForType;
        });

        const others = entities.filter((entity) => {
            return !order.some((entityType) => this.compareTypes(entityType, entity.type));
        });

        const sorted = grouped.reduce((acc, group) => {
            return [...acc, ...group];
        });

        const othersSorted = sortBy(others, ["entity.name", "asc"]);

        return [...sorted, ...othersSorted];
    }

    assignRolesToEntities(entities: IEntity[], primaryEntity: IEntity) {
        return entities.map((entity) => {
            if (sourceSystemReferencesAreEqual(entity.entityId, primaryEntity.entityId)) {
                return {
                    ...entity,
                    role: EntityRole.FAMILY_HEAD,
                };
            }
            if (entities.some((entityRaw) => sourceSystemReferencesAreEqual(entity.entityId, entityRaw.debtorId))) {
                return {
                    ...entity,
                    role: EntityRole.DEBTOR,
                };
            }
            return {
                ...entity,
                role: EntityRole.ENTITY,
            };
        });
    }
}
