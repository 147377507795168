import { ServiceLine } from "./../enums/ServiceLine";
/**lets us group servicelines into the same engagement */
// A Jira Project Reference needs to be sent via findex-locations to know which
// Jira Project is relevant given the location.
// jiraProject = function(jiraProjectReference, location)
/**
 * WARNING: Use this mapping with caution.
 * We initially assumed that multiple service lines could map to one service line
 * but we also assumed that one service line would map to one Jira project.
 *
 * Try to use the ServiceLineService instead wherever possible.
 */
export const ServiceLineCanonicalMapping: IServiceLineMapping = {
    "BA Comp": ServiceLine.bas,
    "BA Adv": ServiceLine.bas,
    BA: ServiceLine.bas,
    [ServiceLine.bas]: ServiceLine.bas,
    SF: ServiceLine.smsf,
    [ServiceLine.smsf]: ServiceLine.smsf,
    CA: ServiceLine.ca,
    [ServiceLine.ta]: ServiceLine.ta,
    [ServiceLine.ia]: ServiceLine.ia,
    [ServiceLine.au]: ServiceLine.au,
    [ServiceLine.pc]: ServiceLine.pc,
    CORP: ServiceLine.corp,
    [ServiceLine.gi]: ServiceLine.gi,
    [ServiceLine.lf]: ServiceLine.lf,
    [ServiceLine.ri]: ServiceLine.ri,
    [ServiceLine.wm]: ServiceLine.wm,
    [ServiceLine.coe]: ServiceLine.coe,
};

// HACK: quick fix to retrieve abbreviated names. Should be combined with above.
export const ServiceLineAbbreviatedNames: { [key: string]: string } = {
    [ServiceLine.bas]: "BA",
    [ServiceLine.smsf]: "SF",
    [ServiceLine.ca]: "CA",
    [ServiceLine.ia]: ServiceLine.ia,
    [ServiceLine.au]: ServiceLine.au,
    [ServiceLine.pc]: "PC",
    [ServiceLine.ta]: "TA",
    [ServiceLine.corp]: "CORP",
    [ServiceLine.gi]: "GI",
    [ServiceLine.lf]: ServiceLine.lf,
    [ServiceLine.ri]: ServiceLine.ri,
    [ServiceLine.wm]: ServiceLine.wm,
    [ServiceLine.coe]: ServiceLine.coe,
};

export interface IServiceLineMapping {
    [key: string]: ServiceLine;
}
