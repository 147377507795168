import { Directive, HostListener, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
    selector: "[appKeyPressDebounce]",
})
export class KeyPressDebounceDirective implements OnInit, OnDestroy {
    @Output() debounceKeyPress = new EventEmitter();
    private keyPresses = new Subject();
    private subscription: Subscription;

    ngOnInit() {
        this.subscription = this.keyPresses.pipe(debounceTime(500)).subscribe((e) => {
            this.debounceKeyPress.emit(e);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener("keyup", ["$event"])
    keyPressEvent(event: any) {
        event.preventDefault();
        event.stopPropagation();

        this.keyPresses.next(event);
    }
}
