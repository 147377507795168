import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from "@angular/core";
import { IWorkItemViewModel } from "../../../../../../../common/models/IWorkItemViewModel";
import { groupBy } from "lodash";
import { ISourceSystemReference } from "../../../../../../../common/models/ISourceSystemReference";
import { sortify } from "sorted-json";

interface IScopeReference {
    scopeName: string;
    scopeId: string;
    // FIXME: this is a scope property that was returned via the workitems. it's ugly but it belongs here.
    scopeCanSwitchPrimaryEntity: boolean;
}
interface IEntityReference {
    entityName: string;
    entityId: ISourceSystemReference;
}

@Component({
    selector: "app-client-workitem-warning",
    templateUrl: "./client-workitem-warning.component.html",
    styleUrls: ["./client-workitem-warning.component.scss"],
})
export class ClientWorkItemWarningComponent implements OnInit, OnChanges {
    @Input() workItems: IWorkItemViewModel[];
    @Output() redirect = new EventEmitter();
    @Output() redirectAndChangePrimaryEntity = new EventEmitter();

    workItemsByScopeAndEntity: GroupingRecord<IScopeReference, GroupingRecord<IEntityReference, IWorkItemViewModel>>[];

    ngOnInit() {
        this.workItemsByScopeAndEntity = this.getWorkItemsByScopeAndEntity(this.workItems);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (Object.keys(changes).includes("workItems")) {
            const workItems = changes["workItems"].currentValue;
            this.workItemsByScopeAndEntity = this.getWorkItemsByScopeAndEntity(workItems);
        }
    }
    getWorkItemsByScopeAndEntity(
        workItems: IWorkItemViewModel[]
    ): GroupingRecord<IScopeReference, GroupingRecord<IEntityReference, IWorkItemViewModel>>[] {
        const scopeToWorkItemGrouping = groupAndIndex(workItems, workItemToScopeReference);

        const scopeAndEntityGrouping = scopeToWorkItemGrouping.map(({ key: scopeReference, values: workItems }) => {
            const entityToWorkItemGrouping = groupAndIndex(workItems, workItemToEntityReference);
            const scopeAndEntityGroupingItem = {
                key: scopeReference,
                values: entityToWorkItemGrouping,
            };
            return scopeAndEntityGroupingItem;
        });
        return scopeAndEntityGrouping;
    }

    redirectOnly(scopeId: string) {
        this.redirect.emit(scopeId);
    }

    redirectAndMigrateScope(scopeId: string) {
        this.redirectAndChangePrimaryEntity.emit(scopeId);
    }
}

function workItemToScopeReference(workItem: IWorkItemViewModel): IScopeReference {
    return {
        scopeId: workItem.scopeId,
        scopeName: workItem.scopeName,
        scopeCanSwitchPrimaryEntity: Boolean(workItem.canSwitchPrimaryEntity),
    };
}

function workItemToEntityReference(workItem: IWorkItemViewModel): IEntityReference {
    return {
        entityName: workItem.entityName,
        entityId: {
            referenceId: workItem.referenceId,
            sourceSystemName: workItem.sourceSystemName,
        },
    };
}
type GroupingRecord<TKey, TValue> = {
    key: TKey;
    values: TValue[];
};
function groupAndIndex<TItem, TIndex>(
    items: TItem[],
    indexFunction: (TInput: TItem) => TIndex
): GroupingRecord<TIndex, TItem>[] {
    const groupedItems = groupBy(items, (item) => sortify(indexFunction(item), { stringify: true }));
    const groupedEntries = Object.entries(groupedItems).map(([indexJson, items]: [string, TItem[]]) => {
        const index = JSON.parse(indexJson) as TIndex;
        return { key: index, values: items };
    });
    return groupedEntries;
}
