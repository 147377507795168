import { EntityValidator } from "../../../../common/src/validators/EntityValidator";
import { Injectable } from "@angular/core";
import { LoggingService } from "../services/logging.service";

@Injectable({
    providedIn: "root",
})
export class InjectableEntityValidator extends EntityValidator {
    constructor(loggingService: LoggingService) {
        super(loggingService);
    }
}
