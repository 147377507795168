import { Injectable } from "@angular/core";
import { MsalService, MsalInterceptor, MsalBroadcastService } from "@azure/msal-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { IUserInfo } from "src/models/IUserInfo";
import { filter, first, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    constructor(private msalService: MsalService, private msalBroadcastService: MsalBroadcastService) {}
    getUser(): Observable<IUserInfo> {
        return this.msalBroadcastService.inProgress$.pipe(
            filter((msg: InteractionStatus) => msg === InteractionStatus.None),
            first(),
            map((event) => {
                // would have liked to use getActiveAccount but that often seems to return null
                // in our case there's only one account and that one is the correct one
                const allAccounts = this.msalService.instance.getAllAccounts();
                const accountInfo = allAccounts?.[0];
                if (accountInfo == null) {
                    return null;
                }
                const userInfo: IUserInfo = {
                    username: accountInfo.username,
                    name: accountInfo.name,
                };
                return userInfo;
            })
        );
    }
    login() {
        this.msalService.loginRedirect();
    }
    logout() {
        this.msalService.logoutRedirect();
    }
}

export const AuthenticationInterceptorService = {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
};
