export const dateTimeFormats = {
    monthFormat: {
        data: "YYYY-MM",
        display: "MMMM YYYY",
    },
    dateFormat: {
        data: "YYYY-MM-DD",
        display: "DD/MM/YYYY",
    },
};
