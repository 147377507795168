import { Component, Input } from "@angular/core";

// TODO: import this as a proper angular module
import Driver from "driver.js";
import { DriverOptions, Step } from "driver.js";
import { TourService } from "src/app/services/tour.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
    selector: "app-tour",
    templateUrl: "./tour.component.html",
    styleUrls: ["./tour.component.scss"],
})
export class TourComponent {
    driver: Driver;

    constructor(private tourService: TourService, private localStorageService: LocalStorageService) {
        this.driver = new Driver({ opacity: 0, animate: false });
    }
    loadSteps(steps: Step[]) {
        this.driver.defineSteps(steps);
    }

    start() {
        this.driver.start();
    }
    initializeTour(tourIdentifier: string) {
        this.tourService.getTourSteps(tourIdentifier).subscribe((steps) => {
            if (steps != null && steps.length > 0) {
                this.loadSteps(steps);
                this.start();
            }
        });
    }
}
