import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-action-button",
    templateUrl: "./action-button.component.html",
    styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent {
    @Input() name: string;
    @Input() label: string;
    @Input() hidden: boolean;
    @Input() disabled: boolean;
    @Input() errorMessages: string;
    @Input() primary: boolean;
    @Input() makingRequests: boolean;
    @Input() showSupportColor: boolean;
    @Output() action: EventEmitter<any> = new EventEmitter();

    onClickEvent() {
        this.action.emit();
    }
}
