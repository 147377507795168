import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class ScopeStateService {
    private dirty: boolean = false;
    constructor() {}

    clearDirty(): void {
        this.dirty = false;
    }

    setDirty(): void {
        this.dirty = true;
    }

    isDirty(): boolean {
        return this.dirty;
    }
}
