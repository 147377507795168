import { IEntity } from "../../../../common/models/IEntity";
import { Injectable } from "@angular/core";
import { EntityServiceBase } from "../../../../common/src/services/EntityServiceBase";

@Injectable({
    providedIn: "root",
})
export class EntityService extends EntityServiceBase {
    constructor() {
        super();
    }

    isActiveEntity(entity: IEntity): boolean {
        return entity.name.substring(0, 2).toLowerCase() !== "zz";
    }
}
