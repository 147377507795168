import { JstError, IErrorParameters, JstHttpError } from "../../models/JstError";

export const formatError = (error: Error, params: IErrorParameters): JstError => {
    if (isJstError(error)) {
        return error;
    }
    if (isJstHttpError(error)) {
        const updatedParams: IErrorParameters = {
            ...params,
            errorCode: error.errorCode.toString(),
            url: error.url,
        };
        const httpToJstResult = new JstError(error.message, updatedParams);
        httpToJstResult.stack = error.stack;
        return httpToJstResult;
    }
    const normalToJstResult = new JstError(error.message, params);
    normalToJstResult.stack = error.stack;
    return normalToJstResult;
};

export const getErrorDisplayMessage = (error: Error | JstHttpError | JstError): string => {
    if (isJstError(error)) {
        return error.params.displayMessage;
    }
    return error.message;
};

export const isJstError = (error: Error | JstHttpError | JstError): error is JstError => {
    return (<JstError>error).params !== undefined;
};
export const isJstHttpError = (error: Error | JstHttpError | JstError): error is JstHttpError => {
    return (<JstHttpError>error).url !== undefined && (<JstHttpError>error).errorCode !== undefined;
};
