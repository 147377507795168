import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-banner",
    templateUrl: "./banner.component.html",
    styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
    @Input() body: string;
    @Input() type: BannerType;
    cssType = "";

    ngOnInit() {
        this.cssType = `bg-${this.type}`;
    }
}

/* type must be of angular material icon, consult docs for different types */
export enum BannerType {
    info = "info",
    warning = "warning",
    error = "error",
}
