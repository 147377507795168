export enum EngagementType {
    ClientMeeting = "clientReview",
    ConvertedSimpleItr = "convertedSimpleItr",
    ConvertedMultipleSimpleItr = "convertedMultipleSimpleItr",
    Default = "default",
    GeneralInsurance = "generalInsurance",
    Lending = "lending",
    Admin = "admin",
    NotRequired = "notRequired",
}

export const CSAEngagementType = [
    EngagementType.Default,
    EngagementType.ConvertedMultipleSimpleItr,
    EngagementType.ConvertedSimpleItr,
];

export const NonCSAEngagementType = [
    EngagementType.ClientMeeting,
    EngagementType.GeneralInsurance,
    EngagementType.Lending,
    EngagementType.Admin,
];

export type EngagementFilter = {
    displayName: string;
    engagementTypes: EngagementType[];
};

export const EngagementTypeSortingOrder: EngagementType[] = [
    EngagementType.Default,
    EngagementType.GeneralInsurance,
    EngagementType.Lending,
    EngagementType.Admin,
    EngagementType.ClientMeeting,
];
