export const ServiceDeskRepresentatives = [
    "fusheng.wong@findex.com.au",
    "wayne.yeap@findex.com.au",
    "adam.flanagan@findex.com.au",
    "rajat.srivastava@findex.com.au",
    "danielle.waddell@findex.com.au",
    "ivy.lee@findex.com.au",
    "chris.roussos@findex.com.au",
    "sachit.malhotra@findex.com.au",
    "yasantha.kalupahana@findex.com.au",
    "shekher.mudgal@findex.com.au",
    "jonathan.poulton@findex.com.au",
    "spencer.porteous@findex.com.au",
    "markham.meredith@findex.com.au",
    "priya.vivekanandan@findex.com.au",
];

export const Administrators = [
    "theodore.rosenthal@findex.com.au",
    "george.soder@crowe.com.au",
    "george.soder@findex.com.au",
];
