import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "tableHeaderTitle",
})
export class TableHeaderTitlePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value != null) {
            return value.replace("/", " / ");
        }
    }
}
