export enum ServiceLine {
    bas = "BAS",
    smsf = "SMSF",
    ca = "CA",
    ta = "TA",
    ia = "IA", //internal audit
    au = "AU", //external audit
    pc = "PC",
    corp = "CORP",
    gi = "GI",
    lf = "LF",
    ri = "RI",
    wm = "WM",
    coe = "COE",
}

export const SERVICE_LINE_SHORT_NAME_TO_LONG_NAME: Record<string, string> = {
    BA: "Accounting & Business Advisory", // Acc & Tax
    SF: "SMSF Administration & Advisory", // Acc & Tax
    [ServiceLine.bas]: "Accounting & Business Advisory", // Acc & Tax
    [ServiceLine.smsf]: "SMSF Administration & Advisory", // Acc & Tax
    [ServiceLine.ca]: "Corporate Finance", // A&C
    [ServiceLine.ta]: "Tax Advisory", // A&C
    [ServiceLine.ia]: "Internal Audit",
    [ServiceLine.au]: "External Audit",
    [ServiceLine.pc]: "Consulting", // A&C
    [ServiceLine.gi]: "General Insurance",
    [ServiceLine.corp]: "Corporate",
    [ServiceLine.lf]: "Lending",
    [ServiceLine.ri]: "Risk Insurance",
    [ServiceLine.wm]: "Wealth",
    [ServiceLine.coe]: "Testing",
};
export enum ServiceLineState {
    notRequired = "not required",
}

export const serviceLineIsAdviceAndConsulting = (serviceLines: ServiceLine[]): boolean => {
    return (
        serviceLines.includes(ServiceLine.ca) ||
        serviceLines.includes(ServiceLine.ta) ||
        serviceLines.includes(ServiceLine.pc)
    );
};

export const serviceLineIsAccountingAndTax = (serviceLines: ServiceLine[]): boolean => {
    return serviceLines.includes(ServiceLine.bas) || serviceLines.includes(ServiceLine.smsf);
};
