import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject, Subject } from "rxjs";
import { LoggingService } from "src/app/services/logging.service";

import { IScopeSaveRequest, IScope } from "../../../../common/models/IScope";
import { ScopeServiceBase } from "../../../../common/src/services/ScopeServiceBase";
import { ScopeRepository } from "./repositories/scope.repository";
import { first, map, switchMap, tap } from "rxjs/operators";
import { ISourceSystemReference } from "../../../../common/models/ISourceSystemReference";
import { IEngagement } from "../../../../common/models/IEngagement";
import { WriteStatus } from "../../../../common/models/IRuntime";
import { ServiceFilterService } from "../components/scope/service-filter.service";
import { MessageOfTheDayService } from "./message-of-the-day.service";

const findEarliestDateString = (array: string[]) => {
    return array.reduce((acc, value) => (acc < value ? value : acc));
};

const findLatestDateString = (array: string[]) => {
    return array.reduce((acc, value) => (acc > value ? value : acc));
};

@Injectable({
    providedIn: "root",
})
export class ScopeService extends ScopeServiceBase {
    private scope$ = new ReplaySubject<IScope>(1);
    error$ = new Subject<Error>();
    private sourceSystemName: string;
    private referenceId: string;
    private startDate: string;
    private endDate: string;

    constructor(
        private scopeRepository: ScopeRepository,
        private loggingService: LoggingService,
        private serviceFilter: ServiceFilterService,
        private motdService: MessageOfTheDayService
    ) {
        super();
    }

    /**
     * Specifies and fetches the current scope from the repository.
     * Intended to be called from scope.component
     */
    fetchScopeFromRepository(
        sourceSystemName: string,
        referenceId: string,
        startDate: string,
        endDate: string
    ): Observable<IScope> {
        return this.scopeRepository.getScope(sourceSystemName, referenceId, startDate, endDate).pipe(
            tap(
                (scope) => {
                    // store these, we will need them for later
                    this.sourceSystemName = scope.primaryEntity.entityId.sourceSystemName;
                    this.referenceId = scope.primaryEntity.entityId.referenceId;
                    this.startDate = startDate;
                    this.endDate = endDate;
                    this.scope$.next(scope);
                },
                (error) => this.handleError(error)
            )
        );
    }

    /**
     * Get the currently loaded scope.
     * Intended to be called from the scope's child components.
     */
    getScope(): Observable<IScope> {
        return this.scope$;
    }

    // this should always be a Save Request with the flags
    saveScope(scope: IScopeSaveRequest): Observable<IScope>;
    // @deprecated We should be using IScopeSaveRequest instead
    saveScope(scope: IScope): Observable<IScope>;
    saveScope(scope: IScopeSaveRequest | IScope): Observable<IScope> {
        return this.scopeRepository.updateScope(scope as IScopeSaveRequest).pipe(
            switchMap((savedScope) => {
                const engagementsThatWouldBeHidden = savedScope.engagements.filter((engagement) => {
                    return engagement.endDate < this.startDate || engagement.startDate > this.endDate;
                });

                if (engagementsThatWouldBeHidden.length > 0) {
                    const startDates = engagementsThatWouldBeHidden.map((engagement) => engagement.startDate);
                    const endDates = engagementsThatWouldBeHidden.map((engagement) => engagement.endDate);

                    const earliestStartDate = findEarliestDateString(startDates);
                    const latestEndDate = findLatestDateString(endDates);

                    if (earliestStartDate < this.startDate || latestEndDate > this.endDate) {
                        const newStartDate = earliestStartDate < this.startDate ? earliestStartDate : this.startDate;
                        const newEndDate = latestEndDate > this.endDate ? latestEndDate : this.endDate;

                        this.serviceFilter.updateStartDate(newStartDate, false);
                        this.serviceFilter.updateEndDate(newEndDate, true);
                        this.motdService.createMessage(
                            "Please note that we have updated your date filters to include the saved engagement(s)"
                        );
                        return of(savedScope);
                    }
                }

                return this.fetchScopeFromRepository(
                    this.sourceSystemName,
                    this.referenceId,
                    this.startDate,
                    this.endDate
                );
            })
        );
    }

    // TODO move this to a better place
    saveEngagement(engagement: IEngagement, action?: string): Observable<IScope> {
        const relatedWorkitemIds = engagement?.workItems?.map((workitem) => workitem.workItemId);
        return this.scope$.pipe(
            first(),
            switchMap((scope) => {
                const updatedScope = {
                    ...scope,
                    prefills: scope.prefills.map((prefill) => {
                        // TODO handle engagement prefills
                        return {
                            ...prefill,
                            writeStatus: WriteStatus.none,
                        };
                    }),
                    workItems: scope.workItems.map((workitem) => {
                        // related workitem = update
                        if (relatedWorkitemIds.includes(workitem.workItemId)) {
                            const engagementWorkItem = engagement.workItems.find(
                                (engagementWorkItem) => engagementWorkItem.workItemId == workitem.workItemId
                            );
                            // should never be undefined since we already know it exists
                            return {
                                ...engagementWorkItem,
                                writeStatus: WriteStatus.update,
                            };
                        }
                        // not related = dont save
                        return {
                            ...workitem,
                            writeStatus: WriteStatus.none,
                        };
                    }),
                    engagements: scope.engagements.map((oldEngagement) => {
                        if (engagement.engagementId === oldEngagement.engagementId) {
                            // engagement to update
                            return {
                                ...engagement,
                                writeStatus: WriteStatus.update,
                            };
                        }
                        // not related = dont save
                        return {
                            ...oldEngagement,
                            writeStatus: WriteStatus.none,
                        };
                    }),
                };
                if (action && action !== "update") {
                    return this.scopeRepository.transitionEngagement(updatedScope, engagement.engagementId, action);
                }
                return this.scopeRepository.updateScope(updatedScope);
            }),
            map((savedScope) => {
                this.scope$.next(savedScope);
                return savedScope;
            })
        );
    }

    getError$(): Observable<Error> {
        return this.error$;
    }

    private handleError(error: Error) {
        this.loggingService.error(error);
        this.error$.next(error);
    }

    /**
     * Creates a new GUID for a given engagement.
     * Also updates all workitems to point to the new engagement.
     *
     * @param engagementId Engagement ID to update
     * @returns the new engagement ID
     */
    updateCurrentEngagementId(engagementId: string): Observable<string> {
        // TODO yearless scopes
        return of(undefined);
    }

    getSourceSystemReference(): ISourceSystemReference {
        const result = {
            sourceSystemName: this.sourceSystemName,
            referenceId: this.referenceId,
        };
        return result;
    }
}
